import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const HijackUnlawfulInterference = () => {

    const [landingRWY, setLandingRWY] = useState('');
    const [aircraftType, setAircraftType] = useState('');
    const [ETA, setETA] = useState('');
    const [pob, setPob] = useState('');
    const [operator, setOperator] = useState('');
    const [lastPoint, setLastPoint] = useState('');
    const [otherInformation, setOtherInformation] = useState('');


    const mysubmit = () => {
        let landingRWYClean = DOMPurify.sanitize(landingRWY, { USE_PROFILES: { html: false } });
        let aircraftTypeClean = DOMPurify.sanitize(aircraftType, { USE_PROFILES: { html: false } });
        let ETAClean = DOMPurify.sanitize(ETA, { USE_PROFILES: { html: false } });
        let pobClean = DOMPurify.sanitize(pob, { USE_PROFILES: { html: false } });
        let operatorClean = DOMPurify.sanitize(operator, { USE_PROFILES: { html: false } });
        let lastPointClean = DOMPurify.sanitize(lastPoint, { USE_PROFILES: { html: false } });
        let otherInformationClean = DOMPurify.sanitize(otherInformation, { USE_PROFILES: { html: false } });

        console.log(landingRWYClean);
        console.log(aircraftTypeClean);
        console.log(ETAClean);
        console.log(pobClean);
        console.log(operatorClean);
        console.log(lastPointClean);
        console.log(otherInformationClean);
    }

    return (

        <div style={{fontFamily:"Poppins , sans-serif"}}>
     
        <div className="App">
                        <h1 style={{ color: "#505050" }} className="fw-bold my-5">Hijack Unlawful Interference</h1>
                    </div>
        <form onSubmit={(e)=>e.preventDefault()}>
                    <div className="row ">
                        <div className="col-md-6">
                               <label className="fw-bold clas5" style={{marginLeft:"15%",width:"50%"}} htmlFor="inp2" >Landing RWY or Location on Airport</label> <br />
                            <div className="App">
                            <InputsHi  onChange={(e) => setLandingRWY(e.target.value)} type="text" id="inp2" placeholder="Landing RWY or Location on Airport"/>
                            </div>              
                            </div>
                        <div className="col-md-6">
                        <label className="fw-bold clas5" style={{width:"50%", marginLeft:"1.2%"}} htmlFor="inp3" >Aircraft Type</label> <br />
        
                            <InputsHi type="text" id="inp3"  onChange={(e) => setAircraftType(e.target.value)} placeholder="Aircraft Type (Auto Complete)" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <label className="fw-bold clas5" style={{marginLeft:"15%",width:"50%"}} htmlFor="inp4" >ETA</label> <br />
                        <div className="App">
                        <InputsHi type="time" id="inp3"  onChange={(e) => setETA(e.target.value)} placeholder="ETA" />
        </div>
                        </div>

                        <div className="col-md-6">
                        <label className="fw-bold clas5" style={{width:"50%", marginLeft:"1.2%"}} htmlFor="inp4" >POB (if known-Crew/Passengers/Hijackers)</label> <br />
                        
                        <InputsHi type="text" id="inp3"  onChange={(e) => setPob(e.target.value)} placeholder="POB (if known-Crew/Passengers/Hijackers)" />
           </div>
           </div>
          
          <div className="row">
           <div className="col-md-6">
                        <label className="fw-bold clas5" style={{marginLeft:"15%",width:"50%"}} htmlFor="inp4" >Operator</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp3"  onChange={(e) => setOperator(e.target.value)} placeholder="Operator" />
           </div>
           </div>
           <div className="col-md-6">
                        <label className="fw-bold"  htmlFor="inp4" style={{marginLeft:"8px", marginLeft:"1.2%"}} >Last Point of Refuel & Estimated Range</label> <br />
                       
                        <InputsHi type="text" id="inp3"  onChange={(e) => setLastPoint(e.target.value)} placeholder="Last Point of Refuel & Estimated Range" />
                    </div>
                     
                        </div>
           
           <div className="row">
                        <div className="col-md-6">
                        <label className="fw-bold clas5" style={{marginLeft:"15%",width:"50%"}} htmlFor="inp4" >Other Information</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp3"  onChange={(e) => setOtherInformation(e.target.value)} placeholder="Other Information" />
           </div>
           </div>
           
           </div>
        
              <div style={{marginLeft:"40px",marginTop:"80px", fontFamily:'Poppins',fontWeight:'400'}}>
        
                <h5 style={{fontFamily:'Poppins',fontWeight:'600',fontSize:"16px"}}>Pass the information above to:</h5>

                <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb1"name="tb1" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb1"> GMC/ADC/CIC</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb2"name="tb2"/>
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb2">ATCA</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb3" name="tb3" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb3"> DPN DL</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb4" name="tb4" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb4"> Support Manager (use On-Call list)</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb5" name="tb5" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb5">D/MGR (1 or 2)</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb6" name="tb6" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb6">OMAE ACC</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb7" name="tb7" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb7">U/S CNS EQIP</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb8" name="tb8" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb8">Police Airwing</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb9" name="tb9" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb9"> CFO Mobile</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb10" name="tb10" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb10"> Split ADC/GMC</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb11" name="tb11" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb11">Complete Phase Checklist</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb12" name="tb12" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb12"> Refer to IFER Manual</label><br/>

                    </li>
         
                
                </ul>


                <h5 style={{fontFamily:'Poppins',fontWeight:'600',fontSize:"16px"}}>Notify all parties when OMSJ ATC Services no longer required Time ______ .</h5>
                        

                <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb13"name="tb13" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb13"> GMC/ADC/CIC</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb14"name="tb14"/>
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb14">ATCA</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb15" name="tb15" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb15"> DPN DL</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb16" name="tb16" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb16"> Support Manager (use On-Call list)</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb17" name="tb17" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb17">D/MGR (1 or 2)</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb18" name="tb18" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb18">Area Sup</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb19" name="tb19" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb19">U/S CNS EQIP</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb20" name="tb20" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb20">Police Airwing</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb21" name="tb21" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb21"> CFO Mobile</label><br/>

                    </li>
                  <br />
                <li>
                        <label for="tb">File ROSI</label><br/></li>
                </ul>



                  
         </div>
         {/* Check Boxxes End */}
         <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
        
         </form>
         </div>
    )
}

export default HijackUnlawfulInterference;
