import React, { useState } from 'react';
import SnackBar from "../LOGs/SnackBar";
import InputsHi from '../LOGs/input';
import DOMPurify from 'dompurify';

const Rostering = () => {
 

    const [staffInitial, setStaffInitial] = useState('');
    const [shiftAffected, setShiftAffected] = useState('');
    const [notificationTime, setNotificationTime] = useState('');
    const [firstDayOfAbsence, setFirstDayOfAbsence] = useState('');
    const [reason, setReason] = useState('');
    const [callOut, setCallOut] = useState('');
    const [remarks, setRemarks] = useState('');

    const [initial, setInitial] = useState('');
    const [unavailable, setUnavailable] = useState('');

    // var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let staffInitialClean = DOMPurify.sanitize(staffInitial, { USE_PROFILES: { html: false } });     
        let shiftAffectedClean = DOMPurify.sanitize(shiftAffected, { USE_PROFILES: { html: false } });
        let notificationTimeClean = DOMPurify.sanitize(notificationTime, { USE_PROFILES: { html: false } });
        let firstDayOfAbsenceClean = DOMPurify.sanitize(firstDayOfAbsence, { USE_PROFILES: { html: false } });
        let reasonClean = DOMPurify.sanitize(reason, { USE_PROFILES: { html: false } });
        let callOutClean = DOMPurify.sanitize(callOut, { USE_PROFILES: { html: false } });
        let remarksClean = DOMPurify.sanitize(remarks, { USE_PROFILES: { html: false } });
        
        let initialClean = DOMPurify.sanitize(initial, { USE_PROFILES: { html: false } });
        let unavailableClean = DOMPurify.sanitize(unavailable, { USE_PROFILES: { html: false } });

        console.log(staffInitialClean);
        console.log(shiftAffectedClean);
        console.log(notificationTimeClean);
        console.log(firstDayOfAbsenceClean);
        console.log(reasonClean);
        console.log(callOutClean);
        console.log(remarksClean);

        console.log(initialClean);
        console.log(unavailableClean);
    }

return (
    <>
    <div className="font back7">
    
 
        <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Rostering</h1>
        </div>
<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp1" className="fw-bold" style={{marginLeft:"15%"}} >Staff Initial*</label> <br />
            <div className="App">
            <InputsHi type="text" onChange={(e)=>setStaffInitial(e.target.value)}  id="inp1" placeholder="Enter Name" />
            </div> 
            </div>
            <div className="col-md-6">
            
            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}} >Shift Affected</label>
            <div className="App">
            <select id="inp3"  onChange={(e)=>setShiftAffected(e.target.value)}  className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >--Select--</option>
            <option value="Night">Night</option>
            <option value="Demo Shift">Demo Shift</option>
            <option value="Test Shift">Test Shift</option>
            </select>
            </div>
            </div>
        </div>
        <div className="row">
        <div className="col-md-6">
            

<label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Notification Time</label> <br />
<div className="App">
            <InputsHi type="time"  onChange={(e)=>setNotificationTime(e.target.value)}  id="inp2" />
</div>
            </div>
       
            <div className="col-md-6">
           
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >First Day of Absence</label> <br />
            <div className="App">
            <InputsHi type="text"  onChange={(e)=>setFirstDayOfAbsence(e.target.value)}  id="inp2" />
            </div>
            </div>
             <br />
            </div>
            <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Reason</label>
            <div className="App">
            <select id="inp3"  onChange={(e)=>setReason(e.target.value)}  className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >--Select--</option>
            <option value="Demo Reason123">Demo Reason123</option>
            <option value="Test Reason">Test Reason</option>
            </select>
            </div>
            </div>
            
      
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Call Out Required*</label>
            <div className="App">
            <select id="inp3"  onChange={(e)=>setCallOut(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected > -- Select -- </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            
            </select>
            </div>
                </div>
            
        </div>
            <div className="row">
            <div className="col-md-6">
            <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp4" >Remarks</label> <br />
            <div className="App">
                    <textarea  onChange={(e)=>setRemarks(e.target.value)} id="inp4" placeholder="Enter Remarks..." className="px-2 mx-2 inpFoc radius" style={{ height: "80%", marginBottom:"3%" }} type="text" />

            </div>
            </div>
            
      
            <div className="col-md-6">
          
                </div>
            
        </div>


        <div className="mx-5 px-4">
            <h3 style={{ color: "#505050" }} className="fw-bold my-5">Unavailable / Unresponsive</h3>

        </div>

        <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Initial</label>
            <div className="App" >
            <select id="inp3" onChange={(e)=>setInitial(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >--Select--</option>
            <option value="OMJ" >OMJ</option>
           
            </select>
            </div>
            </div>
            
      
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Unavailable / Unresponsive</label>
            <div className="App">
            <select id="inp3" onChange={(e)=>setUnavailable(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected > -- Select -- </option>
            <option value="Unavailable">Unavailable</option>
            <option value="Unresponsive">Unresponsive</option>
            
            </select>
                </div>
                </div>
            
        </div>











       
        <div style={{paddingBottom:"1%", marginTop:"1.5%"}}>
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
        </div>
</form>
    </div>
    </>
)
}
export default Rostering;