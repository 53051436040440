import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';
// 
const EquipmentName = () => {
    const [equipment, setEquipment] = useState('');
    const [itSupport, setItSupport] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState('');
   


 

    const mysubmit = () => {
        let equipmentClean = DOMPurify.sanitize(equipment, { USE_PROFILES: { html: false } });
        let itSupportClean = DOMPurify.sanitize(itSupport, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });
        let activeClean = DOMPurify.sanitize(active, { USE_PROFILES: { html: false } });
        
       
        console.log(equipmentClean);
        console.log(itSupportClean);
        console.log(descriptionClean);
        console.log(activeClean);

    }

    return (

        <div className="container" style={{fontFamily:"Poppins , sans-serif"}}>
            {/*  */}
            
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Equipment Name</h1>
            </div>
       		       			
<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Equipment Name</label> <br />
                   <div className="App">
                <InputsHi type="text" onChange={(e)=>setEquipment(e.target.value)} id="inp2" placeholder="Enter Equipment Name..." />
            </div>
            </div>
			
			<div className="col-md-6">
<label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >IT Support</label> <br />

			<ul style={{
                    listStyleType: "none", width: "350px",
                    display: "inline-block" , marginLeft:"20%"
                }}>
                   
                   
                    <li className="my-1">
                    <input  onChange={ itSupport ? (e) => {(e.target.checked)?setItSupport("IT Support"):setItSupport("No IT Support")} : setItSupport("No IT Support")  } type="checkbox" id="tb1"name="tb1" />
        <label style={{ width: "250px", display: "inline-block" }} className="text-dark mx-2" for="tb1">IT Support</label><br/></li>
                   
                   
                   
                </ul>
			</div>
			
        </div>
        
            <div className="row" >
            
			
           
            <div className="col-md-6"  >

            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Description</label> <br />
			<div className="App">
                           <textarea id="inp4" onChange={(e)=>setDescription(e.target.value)} className="px-2 mx-2 inpFoc radius" style={{ height: "80%", marginBottom:"3%" }} type="text" placeholder="Enter Description ..." />
        </div>
            </div>
			<div className="col-md-6">
<label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Active</label> <br />

			<ul style={{
                    listStyleType: "none", width: "350px",
                    display: "inline-block" , marginLeft:"20%"
                }}>
                   
                   
                    <li className="my-1">
                    <input type="checkbox"  onChange={ active ? (e) => {(e.target.checked)?setActive("Active"):setActive("Not Active")} : setActive("Not Active")  } id="tb5"name="tb5" />
        <label style={{ width: "250px", display: "inline-block" }} className="text-dark mx-2" for="tb5">Active</label><br/></li>
                   
                   
                   
                </ul>
			</div>
            </div>




            <br/>
            <br/>
        
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
<button className="btn px-4 py-2 mx-1" style={{backgroundColor:"blue", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>View Equipment Names</button>
</form>
		

        </div>
    )
}

export default EquipmentName;
