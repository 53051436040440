import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';
// 
const FaultReport = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [initial, setInitial] = useState('');
    const [position, setPosition] = useState('');
    const [equipment, setEquipment] = useState('');
    const [console, setConsole] = useState('');
    const [FRNStatus, setFRNStatus] = useState('');


    const mysubmit = () => {
        let fromDateClean = DOMPurify.sanitize(fromDate, { USE_PROFILES: { html: false } });
        let toDateClean = DOMPurify.sanitize(toDate, { USE_PROFILES: { html: false } });
        let initialClean = DOMPurify.sanitize(initial, { USE_PROFILES: { html: false } });
        let positionClean = DOMPurify.sanitize(position, { USE_PROFILES: { html: false } });
        let equipmentClean = DOMPurify.sanitize(equipment, { USE_PROFILES: { html: false } });
        let consoleClean = DOMPurify.sanitize(console, { USE_PROFILES: { html: false } });
        let FRNStatusClean = DOMPurify.sanitize(FRNStatus, { USE_PROFILES: { html: false } });

        window.console.log(fromDateClean);
        window.console.log(toDateClean);
        window.console.log(initialClean);
        window.console.log(positionClean);
        window.console.log(equipmentClean);
        window.console.log(consoleClean);
        window.console.log(FRNStatusClean);
    }

    return (
        <div style={{fontFamily:"Poppins , sans-serif"}}>
            {/*  */}
         
               <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Fault Report</h1>
            </div>

            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >From Date</label> <br />
                       <div className="App">
                    <InputsHi type="date"  onChange={(e)=>setFromDate(e.target.value)} id="inp2" />
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp3" >To Date</label> <br />
<div className="App">
                    <InputsHi type="date"  onChange={(e)=>setToDate(e.target.value)} id="inp3" />
                </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp3" >Initial</label> <br />
<div className="App">
                    <InputsHi type="text"  onChange={(e)=>setInitial(e.target.value)} id="inp3" placeholder="Enter Initial.." />
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp4" >Position</label> <br />
 <div className="App">
                    <InputsHi type="text"  onChange={(e)=>setPosition(e.target.value)} id="inp4" placeholder="Enter Position" />
                </div>
                </div>
                </div>
                
        
            
        <div className="row">
            <div className="col-md-6">
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp5" >Equipment</label> <br />
                <div className="App">
                <InputsHi type="text" id="inp4"  onChange={(e)=>setEquipment(e.target.value)} placeholder="Enter Equipment.." />
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp4" >Console</label> <br />
<div className="App">
                    <InputsHi type="text" id="inp4"  onChange={(e)=>setConsole(e.target.value)} placeholder="Enter Console.." />
                </div>
                </div>
                </div>
        <div className="row">
            <div className="col-md-6">
                
              
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp4" >FRN Status</label> <br />
<div className="App">
                    

<select id="inp3"  onChange={(e)=>setFRNStatus(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >Select FRN Status</option>
    <option value="Open">Open</option>
    <option value="Close">Close</option>
  </select> 



                </div>
                </div>
                </div>
             
              
     

                <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>

        </div>
    )
}

export default FaultReport;
