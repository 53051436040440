import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';
import Elog from './elog';

const ElogMainview = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [subject, setSubject] = useState('');
    const [active, setActive] = useState('');
  
    const mysubmit = () => {
        let fromDateClean = DOMPurify.sanitize(fromDate, { USE_PROFILES: { html: false } });
        let toDateClean = DOMPurify.sanitize(toDate, { USE_PROFILES: { html: false } });
        let subjectClean = DOMPurify.sanitize(subject, { USE_PROFILES: { html: false } });
        let activeClean = DOMPurify.sanitize(active, { USE_PROFILES: { html: false } });
        
       
        console.log(fromDateClean);
        console.log(toDateClean);
        console.log(subjectClean);
        console.log(activeClean);

    }
    return (
        <div className="container" style={{fontFamily:"Poppins , sans-serif"}}>

        <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">E-Log Main View</h1>
        </div>
        <form onSubmit={(e)=>e.preventDefault()}> 
        <div className="row">
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >From Date</label> <br />
                   <div className="App">
                <InputsHi onChange={(e)=>setFromDate(e.target.value)} type="date" id="inp2" />
            </div>
            </div>
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >To Date</label> <br />
                   <div className="App">
                <InputsHi onChange={(e)=>setToDate(e.target.value)} type="date" id="inp2" />
            </div>
            </div>
			
			
			
        </div>
        
            <div className="row" >
            
			
           
            <div className="col-md-6"  >

            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Subject</label> <br />
			<div className="App">
            <InputsHi onChange={(e)=>setSubject(e.target.value)} type="text" placeholder="Enter Subject..." id="inp2" />
        </div>
            </div>

			<div className="col-md-6">
<label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Status</label> <br />

<div className="App">
                <select  onChange={(e)=>setActive(e.target.value)} id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >-- Select Status --</option>
    <option value="Open">Open</option>
    <option value="Pending">Pending</option>
    <option value="Close">Close</option>
    <option value="Return Back">Return Back</option>
   
  </select>  



            </div>
			</div>
            </div>




            <br/>
            <br/>
        
{/* <SnackBar /> */}
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Submit</button>
{/* <button className="btn px-4 py-2 mx-1" style={{backgroundColor:"blue", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>View Aircraft Types</button> */}
</form>




<div className='my-5 py-5'>
    <div style={{display:"flex"}}>
    <p style={{paddingTop:"15px" , fontSize:"15px" , fontWeight:"600"}}>Order By:</p>
    <button className='btn btn-danger shadowed mx-3'>Ascending</button>
    </div>
<hr />
<Elog />
</div>









    </div>
    )
}

export default ElogMainview;
