import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const LaserIncident = () => {
    const [callSign, setCallSign] = useState('');
    const [aircraftType, setAircraftType] = useState('');
    const [altitude, setAltitude] = useState('');
    const [RWYUsed, setRWYUsed] = useState('');
    const [distance, setDistance] = useState('');
    const [registration, setRegistration] = useState('');
    const [phaseOfFlight, setPhaseOfFlight] = useState('');
    const [coordinates, setCoordinates] = useState('');
    
    const [landmark, setLandmark] = useState('');
    const [duration, setDuration] = useState('');
    const [color, setColor] = useState('');
    const [laserCoordinates, setLaserCoordinates] = useState('');
    const [quantity, setQuantity] = useState('');
    const [laserWas, setLaserWas] = useState([]);
    const [otherInformation, setOtherInformation] = useState('');


  function handleChange(e) {
    let isChecked = e.target.value;
    laserWas.push(isChecked)
  }

    const mysubmit = () => {
        let callSignClean = DOMPurify.sanitize(callSign, { USE_PROFILES: { html: false } });
        let aircraftTypeClean = DOMPurify.sanitize(aircraftType, { USE_PROFILES: { html: false } });
        let altitudeClean = DOMPurify.sanitize(altitude, { USE_PROFILES: { html: false } });
        let RWYUsedClean = DOMPurify.sanitize(RWYUsed, { USE_PROFILES: { html: false } });
        let distanceClean = DOMPurify.sanitize(distance, { USE_PROFILES: { html: false } });
        let registrationClean = DOMPurify.sanitize(registration, { USE_PROFILES: { html: false } });
        let phaseOfFlightClean = DOMPurify.sanitize(phaseOfFlight, { USE_PROFILES: { html: false } });
        let coordinatesClean = DOMPurify.sanitize(coordinates, { USE_PROFILES: { html: false } });
        
        let landmarkClean = DOMPurify.sanitize(landmark, { USE_PROFILES: { html: false } });
        let durationClean = DOMPurify.sanitize(duration, { USE_PROFILES: { html: false } });
        let colorClean = DOMPurify.sanitize(color, { USE_PROFILES: { html: false } });
        let laserCoordinatesClean = DOMPurify.sanitize(laserCoordinates, { USE_PROFILES: { html: false } });
        let quantityClean = DOMPurify.sanitize(quantity, { USE_PROFILES: { html: false } });
        let laserWasClean = DOMPurify.sanitize(laserWas, { USE_PROFILES: { html: false } });
        let otherInformationClean = DOMPurify.sanitize(otherInformation, { USE_PROFILES: { html: false } });



        console.log(callSignClean);
        console.log(aircraftTypeClean);
        console.log(altitudeClean);
        console.log(RWYUsedClean);
        console.log(distanceClean);
        console.log(registrationClean);
        console.log(phaseOfFlightClean);
        console.log(coordinatesClean);
        
        console.log(landmarkClean);
        console.log(durationClean);
        console.log(colorClean);
        console.log(laserCoordinatesClean);
        console.log(quantityClean);
        console.log(laserWasClean);
        console.log(otherInformationClean);
    }

    return (
        <div style={{ fontFamily: "Poppins , sans-serif" }}>
            {/*  */}
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Laser Incident</h1>
            </div>
            <form onSubmit={(e)=>e.preventDefault()}>
                <center>
                    <h3 className="py-3 my-5" style={{ backgroundColor: '#CBCBCB', width: "70%" }}>Aircraft Details</h3></center>
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp2" >Callsign:</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp2"  onChange={(e) => setCallSign(e.target.value)} placeholder="Callsign (Auto Complete)" />
                    </div>
                    </div>
                    <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp3" >Aircraft Type</label> <br />
<div className="App">
                        <InputsHi type="text" id="inp3"  onChange={(e) => setAircraftType(e.target.value)} placeholder="Aircraft Type (Auto Complete)" />
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp4" >Altitude</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp4"  onChange={(e) => setAltitude(e.target.value)} placeholder="Altitude" />
                    </div>
                    </div>
                    <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp5" >RWY used</label> <br />
<div className="App">
                        <InputsHi type="text" id="inp5"  onChange={(e) => setRWYUsed(e.target.value)} placeholder="RWY used" />
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold"  htmlFor="inp6" style={{marginLeft:"15%"}} >Distance from OMSJ</label> <br />
                      <div className="App">
                        <InputsHi type="text" id="inp6"  onChange={(e) => setDistance(e.target.value)} placeholder="Distance from OMSJ" />
                    </div>
                    </div>
                    <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp7" >Registration</label> <br />
<div className="App">
                        <InputsHi type="text" id="inp7"  onChange={(e) => setRegistration(e.target.value)} placeholder="Registration" />
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}>Phase of flight</label>
                        <div className="App">
                        <select id="inp3"  onChange={(e) => setPhaseOfFlight(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius" style={{ marginBottom: "4%" }}>
                            <option selected >Approach </option>
                            <option value="Short Final">Short Final</option>
                            <option value="Downwind">Downwind</option>
                            <option value="Base Leg">Base Leg</option>
                            <option value="Climb Out">Climb Out</option>
                        </select>
                    </div>
                    </div>

                    <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp7" >Coordinates</label> <br />
                        <div className="App">
                        <InputsHi type="text"  onChange={(e) => setCoordinates(e.target.value)} id="inp7" placeholder="Coordinates" />
                    </div>
                    </div>

                </div>


                <center>
                    <h3 className="py-3 my-5" style={{ backgroundColor: '#CBCBCB', width: "70%" }}>Details Laser</h3></center>
                <br />
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp2" style={{marginLeft:"15%"}} >Distance from OMSJ/ Landmark</label> <br />
                       <div className="App">
                        <InputsHi type="text" onChange={(e) => setLandmark(e.target.value)} id="inp2" placeholder="Distance from OMSJ/ Landmark" />
                    </div>
                    </div>
                    <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp3" >Duration</label> <br />
<div className="App">
                        <InputsHi type="text" id="inp3" onChange={(e) => setDuration(e.target.value)} placeholder="Duration" />
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp4">Color</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp4" onChange={(e) => setColor(e.target.value)} placeholder="Color" />
                    </div>
                    </div>
                    <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp5" >Coordinates</label> <br />
<div className="App">
                        <InputsHi type="text" id="inp5" onChange={(e) => setLaserCoordinates(e.target.value)} placeholder="Coordinates" />
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold"  htmlFor="inp6" style={{marginLeft:"15%"}} >Quantity</label> <br />
                       <div className="App">
                        <InputsHi type="text" id="inp6" onChange={(e) => setQuantity(e.target.value)} placeholder="Quantity" />

                    </div>
                    </div>
                    
                    <div className="col-md-6">
                        <div className="row ">
                        <label className="fw-bold"  htmlFor="inp6" style={{marginLeft:"15%", marginBottom:"2%"}} >Laser Was:</label> 
                        <br />
                     
                            <div className="col-md-2"></div>
                            <div className="col-md-4">

                            <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb13" name="tb13" value="Flashing" onChange={e => handleChange(e)} />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb13"> Flashing</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb14"name="tb14" value="Steady" onChange={e => handleChange(e)}/>
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb14">Steady</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb15" name="tb15" value="Fixed" onChange={e => handleChange(e)} />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb15"> Fixed </label><br/>
                    </li>
      
                </ul>



                            </div>
                            <div className="col-md-6">
                              

                                    <div className="col-md-4">



                                    <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb3"name="tb3" value="Random" onChange={e => handleChange(e)} />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb3"> Random</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb4"name="tb4" value="Targeted" onChange={e => handleChange(e)} />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb4">Targeted</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb5" name="tb5" value="Flashing" onChange={e => handleChange(e)} />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb5"> Flashing </label><br/>
                    </li>
      
                </ul>

                                    </div>
                              
                            </div>

                        </div>
                    </div>

                </div>
                <div className="row">

                    <div className="col-md-6">
                     
                            <label className="fw-bold" htmlFor="inp7" style={{ marginLeft: "15%" }} >Other information</label> <br />
                           <div className="App">
                            <InputsHi type="text" onChange={(e)=>setOtherInformation(e.target.value)} id="inp7" placeholder="Other information" />
                        </div>
                        </div>
           

                </div>
           

                <SnackBar />
       <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
            </form>
        </div>
    )
}

export default LaserIncident;
