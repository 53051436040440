import React, { useState, useEffect } from 'react';
import "./sidebars.css";
import $ from "jquery"
import logo from "../../images/logo2.png"
import { FaUserCircle } from "react-icons/fa"
import { FaWifi } from "react-icons/fa"
import Divider from '@mui/material/Divider';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { BsWifiOff } from 'react-icons/bs';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Badge from '@mui/material/Badge';
import Dashboard from '../dashboard';
import { Link, useHistory } from 'react-router-dom';
// import Scroll from './scroll';
import home from "../../images/home.png"
import elog from "../../images/elog.png"
import accessLog from "../../images/access_log.png"
import aircraftDiversion from "../../images/aircraft_Diversion.png"
import document from "../../images/document.png"
import domainpara from "../../images/domainpara.png"
import emergency from "../../images/emergency.png"
import faultReporting from "../../images/fault_reporting.png"
import inspection from "../../images/inspection.png"
import lvo from "../../images/lvo.png"
import reports from "../../images/reports.png"
import runwayChange from "../../images/runway_Change.png"
import training from "../../images/training.png"
import userManagement from "../../images/usermanagment.png"
import specialEntry from "../../images/special_entry.png"
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import Elog from '../elog/elog';
import Aircraft from '../aircraftDiversion/aircraft';
import Routine from '../generalEntry/routine';
import ReportableDelays from '../generalEntry/reportabledelays';
import Pilotsreports from '../generalEntry/pilotsreports';
import CNSFaultReporting from '../FaultReporting/cnsFaultReporting';
import FMFaultReporting from '../FaultReporting/fmFaultReporting';
import ElogFault from '../FaultReporting/elogFault';
import RunwayChange from '../Runway/runwayChange';
import NonDutyRunwayOperations from '../Runway/nonDutyRunwayOperations';
import TotalMovementReport from '../Reports/totalMovementReport';
import Movement from '../totalMovement/movement';
import RWYInspection from '../Inspection/rwyInspection';
import OtherInspection from '../Inspection/otherInsp';
import StaffEntry from '../staff/staffEntry';
import Rostering from '../staff/rostering';
import Special from '../specialEntry/special';
import AircraftAcci from '../Emergency/aircraftAcci';
import BombWarning from '../Emergency/bombWarning';
import HijackUnlawfulInterference from '../Emergency/hijackUnlawfulInterference';
import FullEmergency from '../Emergency/fullEmergency';
import GroundIncident from '../Emergency/groundIncident';
import LaserIncident from '../Emergency/laserIncident';
import LocalStandby from '../Emergency/localStandby';
import GeneralMedicalEmergency from '../Emergency/generalMedicalEmergency';
import SarPhaseDeclaration from '../Emergency/sarPhaseDeclaration';
import LVO from '../lvo/lvo';
import DailyChecklist from '../dailyChecklist/dailyChecklist';
import METCondition from '../conditions/metCondition';
import OperationalCondition from '../conditions/operationalCondition';
import Si from '../document/si';
import Ti from '../document/ti';
import Memo from '../document/memo';
import OpsManual from '../document/opsManual';
import DemoInstruction from '../document/demoInstruction';
import PositionLogReport from '../Reports/positionLogReport';
import PositionLogSummaryRep from '../Reports/positionLogSummaryRep';
import DailyReport from '../Reports/dailyReport';
import SupervisorReport from '../Reports/supervisorReport';
import ManagementReport from '../Reports/managementReport';
import FaultReport from '../Reports/faultReport';
import GeneralReport from '../Reports/generalReport';
import InstructionReport from '../Reports/instructionReport';
import IncidentReport from '../Reports/incidentReport';
import AttendanceReport from '../Reports/attendanceReport';
import CreateFeedback from '../Feedback/createFeedback';
import ManageFeedback from '../Feedback/manageFeedback';
import TrainingSession from '../Training/trainingSession';
import TrainingReport from '../Training/trainingReport';
import TrainingHours from '../Training/trainingHours';
import AddUser from '../UserManagment/addUser';
import Users from '../UserManagment/users';
import UserRoles from '../UserManagment/userRoles';
import Status from '../ConfigurationSetup/status';
import SubjectForm from '../ConfigurationSetup/subjectForm';
import PurposeForm from '../ConfigurationSetup/purposeForm';
import SubjectPriorityView from '../ConfigurationSetup/subjectPriorityView';
import PositionName from '../ConfigurationSetup/positionName';
import EquipmentName from '../ConfigurationSetup/equipmentName';
import ConsoleNumber from '../ConfigurationSetup/consoleNumber';
import Vehicles from '../ConfigurationSetup/vehicles';
import ReleasePurpose from '../ConfigurationSetup/releasePurpose';
import AircraftType from '../ConfigurationSetup/aircraftType';
import Airport from '../ConfigurationSetup/airport';
import Shift from '../ConfigurationSetup/shift';
import StaffReason from '../ConfigurationSetup/staffReason';
import ManoeuvringArea from '../ConfigurationSetup/manoeuvringArea';
import Nationality from '../ConfigurationSetup/nationality';
import Destination from '../ConfigurationSetup/destination';
import Designation from '../ConfigurationSetup/designation';
import Department from '../ConfigurationSetup/department';
import Company from '../ConfigurationSetup/company';
import InstructionType from '../ConfigurationSetup/instructionType';
import InspectionType from '../ConfigurationSetup/inspectionType';
import TypeOfIncident from '../ConfigurationSetup/typeOfIncident';
import DangerousGoods from '../ConfigurationSetup/dangerousGoods';
import NotificationList from '../ConfigurationSetup/notificationList';
import Runway from '../ConfigurationSetup/runway';
import Authority from '../ConfigurationSetup/authority';
import AccessLogs from '../accessLogs/accessLogs';
import BasicTabs from '../UserManagment/webapp';
import ElogMainview from '../elog/elogMainview';
import { api, logoutApi } from '../api/api';
import AddInstruction from '../document/addInstruction';
import UserProfile from '../UserManagment/userProfile/userProfile';
import PositionRelief from '../LOGs/positionRelief';


const Sidebars = () => {

    const [userData, setUserData] = useState();
    const [token, setToken] = useState();

    let history = useHistory();


    useEffect(() => {
        const saved = localStorage.getItem("userDetails");
        const initialValue = (saved) ? JSON.parse(saved) : "";
        setUserData((initialValue) ? initialValue.user : "");
        setToken((initialValue) ? initialValue.token : "");

        console.log(userData);
        console.log(token);
    }, token)


    const Logout = () => {
        fetch(
            api + logoutApi
            , {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`

                },

            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.message === "Logged Out") {
                    localStorage.clear();
                    history.push("/")

                }

            })
            .catch((error) => {
                console.error(error);
            });

    }





    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function Click1() {
        $(".page-wrapper").removeClass("toggled");
    };
    function Click2() {
        $(".page-wrapper").addClass("toggled");
    };

    const [mail, setMail] = React.useState(null);
    const Mailopen = Boolean(mail);
    const MailClick = (event) => {
        setMail(event.currentTarget);
    };
    const MailClose = () => {
        setMail(null);
    };

    const [notification, setNotification] = React.useState(null);
    const opening = Boolean(notification);
    const Clicking = (event) => {
        setNotification(event.currentTarget);
    };
    const Closing = () => {
        setNotification(null);
    };

    const [setSeconds, setnext] = useState(<FaWifi className="text-white" />)
    useEffect(() => {
        if (navigator.onLine === !true) {
            setTimeout(() => {
                setnext(<BsWifiOff className="text-white" />)
            }, 100);
        } else {
            setTimeout(() => {
                setnext(<FaWifi className="text-white" />)
            }, 100);
        }
    });



    const [demo, setDemo] = useState(<Dashboard />)
    // console.log(demo)
    return (
        <div>
            <div className="page-wrapper chiller-theme toggled">
                {/* <Scroll /> */}
                <button id="show-sidebar" onClick={() => Click2()} style={{ border: "1px solid rgb(209, 24, 24)", boxShadow: "0px 1px 10px rgba(0 0 0 0.2)", zIndex: "1000" }} className="btn btn-sm bg-white">
                    <i className="fas fa-bars" style={{ color: "rgb(221, 51, 51)", zIndex: "1000" }}></i>
                </button>
                <nav id="sidebar" className="sidebar-wrapper" style={{ zIndex: "1000" }}>
                    <div className="sidebar-content" style={{ zIndex: "1000" }}>
                        <div className="sidebar-brand" style={{ backgroundColor: "#DD3333", boxShadow: "0px 10px 20px rgba(0,0,0,0.2)" }}>
                            <img width="170px" className="py-2" style={{ zIndex: "1000" }} src={logo} />
                            <div id="close-sidebar " onClick={() => Click1()}>
                                <i className="fas fa-times text-white" style={{ marginLeft: "50px", zIndex: "1000" }}></i>
                            </div>
                        </div>



                        <aside>



                            {/* <!-- Menu --> */}
                            <ul class="main ul">

                                {/* <!-- Start Dashboard --> */}

                                {/* <Link to="/dashboard"> */}
                                <li onClick={() => setDemo(<Dashboard />)} >
                                    <input type="radio"
                                        id="home"
                                        name="menu" checked />
                                    <label for="home">
                                        <img src={home} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        {/* <i id="1" class="fa fa-th-large" aria-hidden="true"></i> */}
                                        Home
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                </li>
                                {/* </Link> */}
                                {/* <!-- End Dashboard --> */}

                                {/* <!-- Start Mailbox --> */}
                                {/* <Link to="/dashboard/elog"> */}
                                <li onClick={() => setDemo(<ElogMainview />)} >
                                    <input type="radio"
                                        id="e-log"
                                        name="menu" />
                                    <label for="e-log">
                                        {/* <i class="fa fa-envelope" aria-hidden="true"></i> */}
                                        <img src={elog} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        E-Log
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                </li>
                                {/* </Link> */}
                                {/* <!-- End Mail box --> */}


                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="layout"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="layout">
                                        <img src={specialEntry} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        {/* <i class="fa fa-laptop" aria-hidden="true"></i> */}
                                        General Entry
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul">
                                        <li onClick={() => setDemo(<Routine />)}>
                                            <input type="radio"
                                                id="layoutfirstelement"
                                                name="menu" />
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                Routine Entry
                                            </label>
                                        </li>

                                        <li onClick={() => setDemo(<ReportableDelays />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }}>
                                                Reportable Delays
                                            </label>
                                        </li>


                                        <li onClick={() => setDemo(<Pilotsreports />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }}>
                                                Pilot Reports
                                            </label>
                                        </li>
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>

                                {/* <!-- End Layout Dropdown --> */}




                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="fault"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="fault">
                                        <img src={faultReporting} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Fault Reporting
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul">
                                        <li onClick={() => setDemo(<CNSFaultReporting />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                CNS Fault Reporting
                                            </label>

                                        </li>


                                        <li onClick={() => setDemo(<FMFaultReporting />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }}>
                                                FM Fault Reporting
                                            </label>
                                        </li>



                                        <li onClick={() => setDemo(<ElogFault />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }}>
                                                Elog Fault Reporting
                                            </label>
                                        </li>
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>

                                </li>
                                {/* <!-- End Layout Dropdown --> */}



                                {/* <!-- Start sinppets --> */}
                                {/* <Link to="/dashboard/aircraft"> */}
                                <li onClick={() => setDemo(<Aircraft />)}>
                                    {/* <input type="radio"
                                            id="aircraft"
                                            name="menu" /> */}
                                    <label for="aircraft">
                                        <AirplanemodeActiveIcon style={{ color: "red", borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} />
                                        {/* <img src={aircraftDiversion} height="30px" width="13%" style={{borderRadius:"50px" , marginRight:"3%" , marginLeft:"2%"}} alt="home" /> */}
                                        Aircraft Diversion
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                </li>
                                {/* </Link> */}
                                {/* <!-- End Sinppets --> */}






                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="runway"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="runway">
                                        <AirplanemodeActiveIcon style={{ color: "#ee312d", borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} />
                                        Runway Change
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul">
                                        {/* <Link to="/dashboard/runwayChange/runwayForm"> */}
                                        <li onClick={() => setDemo(<RunwayChange />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                Runway Change
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/runwayChange/runwayOperations"> */}
                                        <li onClick={() => setDemo(<NonDutyRunwayOperations />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Non-Duty Runway Operations
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}










                                {/* <!-- Start User Profile --> */}
                                {/* <Link to="/dashboard/totalMovement"> */}
                                <li onClick={() => setDemo(<Movement />)}>
                                    {/* <input type="radio"
                                            id="totalmovement"
                                            name="menu" /> */}
                                    <label for="totalmovement">
                                        <img src={specialEntry} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Total Movement
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                </li>
                                {/* </Link> */}
                                {/* <!-- End user Profile --> */}




                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="inspEntry"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="inspEntry">
                                        <img src={inspection} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Inspection Entry
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul">
                                        {/* <Link to="/dashboard/inspectionEntry/rwyInspection"> */}
                                        <li onClick={() => setDemo(<RWYInspection />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                RWY Inspection
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/inspectionEntry/otherInspection"> */}

                                        <li onClick={() => setDemo(<OtherInspection />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Other Inspection
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}





                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="staff"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="staff">
                                        <img src={specialEntry} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Staff
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul">
                                        {/* <Link to="/dashboard/staff/staffEntry"> */}
                                        <li onClick={() => setDemo(<StaffEntry />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                Staff Entry
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/staff/rostering"> */}
                                        <li onClick={() => setDemo(<Rostering />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Rostering
                                            </label>
                                        </li>

                                        {/* </Link> */}
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}








                                {/* <!-- Start Sticky Notes --> */}
                                {/* <Link to="/dashboard/specialentry"> */}
                                <li onClick={() => setDemo(<Special />)}>
                                    <input type="radio" id="specialEntry" name="menu" />
                                    <label for="specialEntry">
                                        <img src={specialEntry} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Unusual Entry
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                </li>
                                {/* </Link> */}
                                {/* <!-- End Sticky Notes --> */}






                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="emergencyCheck"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="emergencyCheck">
                                        <img src={emergency} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Emergency Checklist
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul drops">
                                        {/* <Link to="/dashboard/emergencyChecklist/aircraftAccident"> */}
                                        <li onClick={() => setDemo(<AircraftAcci />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                Aircraft Accident
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/emergencyChecklist/bombWarning"> */}

                                        <li onClick={() => setDemo(<BombWarning />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Bomb Warning
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/emergencyChecklist/hijackUnlawfulInterference" > */}
                                        <li onClick={() => setDemo(<HijackUnlawfulInterference />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Hijack / Unlawful Interference
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/emergencyChecklist/fullEmergency"> */}
                                        <li onClick={() => setDemo(<FullEmergency />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Full Emergency
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/emergencyChecklist/groundIncident"> */}
                                        <li onClick={() => setDemo(<GroundIncident />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Ground Incident
                                            </label>
                                        </li>
                                        {/* </Link> */}


                                        {/* <Link to="/dashboard/emergencyChecklist/laserIncident"> */}

                                        <li onClick={() => setDemo(<LaserIncident />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Laser Incident
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/emergencyChecklist/localStandby"> */}


                                        <li onClick={() => setDemo(<LocalStandby />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Local Standby
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/emergencyChecklist/medicalEmergency"> */}

                                        <li onClick={() => setDemo(<GeneralMedicalEmergency />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Medical Emergency
                                            </label>
                                        </li>
                                        {/* </Link> */}


                                        {/* <Link to="/dashboard/emergencyChecklist/sarPhaseDeclaration"> */}

                                        <li onClick={() => setDemo(<SarPhaseDeclaration />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                SAR Phase Decleration
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}


                                {/* <!-- Start User Profile --> */}
                                {/* <Link to="/dashboard/lvo"> */}
                                <li onClick={() => setDemo(<LVO />)}>
                                    {/* <input type="radio"
                                            id="lvo"
                                            name="menu" /> */}
                                    <label for="lvo">
                                        <img src={lvo} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        LVO
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                </li>
                                {/* </Link> */}
                                {/* <!-- End user Profile --> */}

                                {/* <!-- Start User Profile --> */}
                                {/* <Link to="/dashboard/dailyChecklist"> */}
                                <li onClick={() => setDemo(<DailyChecklist />)}>
                                    {/* <input type="radio"
                                            id="dailyCheck"
                                            name="menu" /> */}
                                    <label for="dailyCheck">
                                        <img src={specialEntry} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Daily Checklist
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                </li>
                                {/* </Link> */}
                                {/* <!-- End user Profile --> */}



                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="conditions"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="conditions">
                                        <img src={specialEntry} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Conditions
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul">
                                        {/* <Link to="/dashboard/conditions/metCondition"> */}

                                        <li onClick={() => setDemo(<METCondition />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                MET Conditions
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/conditions/operationalCondition"> */}

                                        <li onClick={() => setDemo(<OperationalCondition />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Operational Conditions
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}




                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="doc"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="doc">
                                        <img src={document} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Document / Instruction
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul drops">
                                        {/* <Link to="/dashboard/userManagement/addInstruction"> */}
                                            <li onClick={() => setDemo(<AddInstruction />)}>
                                                {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                                <i class="mx-4" aria-hidden="true"></i>
                                                <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                    Add Instruction
                                                </label>
                                            </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/instruction/si"> */}
                                        <li onClick={() => setDemo(<Si />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                SI
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/instruction/ti"> */}
                                        <li onClick={() => setDemo(<Ti />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                TI
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/instruction/memo"> */}
                                        <li onClick={() => setDemo(<Memo />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Memo
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/instruction/opsManual"> */}
                                        <li onClick={() => setDemo(<OpsManual />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Ops Manual
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/instruction/demoInstruction"> */}
                                        <li onClick={() => setDemo(<DemoInstruction />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Demo Instruction
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}




                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="reports"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="reports">
                                        <img src={reports} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Reports
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul drops">
                                        {/* <Link to="/dashboard/reports/positionLogReport"> */}
                                        <li onClick={() => setDemo(<PositionLogReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                Position Log Report
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/reports/positionLogSummaryReport"> */}

                                        <li onClick={() => setDemo(<PositionLogSummaryRep />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Position Log Summary Report
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/reports/dailyReport"> */}
                                        <li onClick={() => setDemo(<DailyReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Daily Report
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/reports/supervisorReport"> */}
                                        <li onClick={() => setDemo(<SupervisorReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Supervisor Report
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/reports/managementReport"> */}
                                        <li onClick={() => setDemo(<ManagementReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Management Report
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/reports/faultReport"> */}
                                        <li onClick={() => setDemo(<FaultReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Fault Report
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/Reports/generalReport"> */}
                                        <li onClick={() => setDemo(<GeneralReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                General Report
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/reports/airCraftReport"> */}
                                        <li onClick={() => setDemo(<aircraftDiversion />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Aircraft Diversion Report
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/reports/totalMovementReport" > */}
                                        <li onClick={() => setDemo(<TotalMovementReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Total Movement Report
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/reports/instructionReport"> */}
                                        <li onClick={() => setDemo(<InstructionReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Instruction Report
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/reports/incidentReport"> */}
                                        <li onClick={() => setDemo(<IncidentReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Incident Report
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/reports/attendanceReport"> */}
                                        <li onClick={() => setDemo(<AttendanceReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Attendence Report
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />



                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}






                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="feedback"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="feedback">
                                        <img src={training} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Feedback
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />


                                    <ul class="drop ul">
                                        {/* <Link to="/dashboard/feedback/createFeedback"> */}
                                        <li onClick={() => setDemo(<CreateFeedback />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                Create Feedback
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/feedback/manageFeedback"> */}
                                        <li onClick={() => setDemo(<ManageFeedback />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Manage Feedback
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}



                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="trainingSession"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="trainingSession">
                                        <img src={training} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Training Session
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul">
                                        {/* <Link to="/dashboard/trainingSession/trainingSessionForm"> */}
                                        <li onClick={() => setDemo(<TrainingSession />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                Training Session
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/trainingSession/trainingReport"> */}
                                        <li onClick={() => setDemo(<TrainingReport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Training Report
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/trainingSession/trainingHours" > */}
                                        <li onClick={() => setDemo(<TrainingHours />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Training Hours Report
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}




                                {/* <!-- Start Layout Dropdown --> */}

                                <li>
                                    <input type="checkbox"
                                        id="userManage"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="userManage">
                                        <img src={userManagement} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        User Management
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul">
                                        {/* <Link to="/dashboard/UserManagment/addUser"> */}
                                        <li onClick={() => setDemo(<AddUser />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                Add User
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/UserManagment/users"> */}
                                        <li onClick={() => setDemo(<Users />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Users
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/UserManagment/userRoles"> */}
                                        <li onClick={() => setDemo(<UserRoles />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                User Role
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/userManagement/webAppPermissions"> */}
                                        <li onClick={() => setDemo(<BasicTabs />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Web App Permissions
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}



                                {/* <!-- Start Layout Dropdown --> */}
                                <li>
                                    <input type="checkbox"
                                        id="domainPara"
                                        className="inps"
                                        name="dropdowns" />
                                    <label for="domainPara">
                                        <img src={domainpara} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Configuration Setup
                                        <i id="downarrow" class="fa fa-angle-down" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                        <i id="uparrow" class="fa fa-angle-up" style={{ marginTop: "5px" }} aria-hidden="true"></i>
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />

                                    <ul class="drop ul drops">

                                        {/* <Link to="/dashboard/ConfigurationSetup/status"> */}
                                        <li onClick={() => setDemo(<Status />)}>
                                            {/* <input type="radio"
                                                    id="layoutfirstelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>
                                            <label for="layuoutfirstelement" style={{ fontSize: "13px" }}>
                                                Status                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/ConfigurationSetup/subjectForm"> */}
                                        <li onClick={() => setDemo(<SubjectForm />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Subject Form
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/purposeForm"> */}
                                        <li onClick={() => setDemo(<PurposeForm />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Purpose Form
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/subjectPriorityView"> */}
                                        <li onClick={() => setDemo(<SubjectPriorityView />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Subject Priority
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/ConfigurationSetup/positionName"> */}
                                        <li onClick={() => setDemo(<PositionName />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Position Name
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/equipmentName" > */}
                                        <li onClick={() => setDemo(<EquipmentName />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Equipment Name
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/consoleNumber"> */}
                                        <li onClick={() => setDemo(<ConsoleNumber />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Console Number
                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/ConfigurationSetup/vehicles"> */}
                                        <li onClick={() => setDemo(<Vehicles />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Vehicle
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/releasePurpose"> */}
                                        <li onClick={() => setDemo(<ReleasePurpose />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Release Purpose
                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/aircraftType"> */}
                                        <li onClick={() => setDemo(<AircraftType />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Aircraft Type                                            </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/ConfigurationSetup/airport"> */}
                                        <li onClick={() => setDemo(<Airport />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Airport                                           </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/ConfigurationSetup/shift"> */}
                                        <li onClick={() => setDemo(<Shift />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Shift                                           </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/staffReason"> */}
                                        <li onClick={() => setDemo(<StaffReason />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Staff Reason                                           </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/manoeuvringArea"> */}
                                        <li onClick={() => setDemo(<ManoeuvringArea />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Manoeuvring Area                                            </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/nationality" > */}
                                        <li onClick={() => setDemo(<Nationality />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Nationality                                           </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/destination"> */}
                                        <li onClick={() => setDemo(<Destination />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Destination                                      </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/designation"> */}
                                        <li onClick={() => setDemo(<Designation />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Designation                                      </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/ConfigurationSetup/department"> */}
                                        <li onClick={() => setDemo(<Department />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Department                                      </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/company"> */}
                                        <li onClick={() => setDemo(<Company />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Company                                      </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/instructionType"> */}
                                        <li onClick={() => setDemo(<InstructionType />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Instruction Type                                      </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/inspectionType"> */}
                                        <li onClick={() => setDemo(<InspectionType />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Inspection Type                                      </label>
                                        </li>
                                        {/* </Link> */}

                                        {/* <Link to="/dashboard/ConfigurationSetup/typeOfIncident"> */}
                                        <li onClick={() => setDemo(<TypeOfIncident />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Type of Incident                                      </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/dangerousGoods"> */}
                                        <li onClick={() => setDemo(<DangerousGoods />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Dangerous Goods                                     </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/notificationList"> */}
                                        <li onClick={() => setDemo(<NotificationList />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Email / Phone </label>
                                        </li>
                                        {/* </Link> */}
                                        {/* <Link to="/dashboard/ConfigurationSetup/runway"> */}
                                        <li onClick={() => setDemo(<Runway />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Runway </label>
                                        </li>
                                        {/* </Link> */}
                                        <li onClick={() => setDemo(<Authority />)}>
                                            {/* <input type="radio"
                                                    id="layoutsecondelement"
                                                    name="menu" /> */}
                                            <i class="mx-4" aria-hidden="true"></i>

                                            <label for="layoutsecondelement" style={{ fontSize: "13px" }} >
                                                Authority </label>
                                        </li>
                                        <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                    </ul>
                                </li>
                                {/* <!-- End Layout Dropdown --> */}




                                {/* <!-- Start Sticky Notes --> */}
                                <li style={{ marginBottom: "100px" }} onClick={() => setDemo(<AccessLogs />)}>
                                    <input type="radio" id="accessLogs" name="menu" />
                                    <label for="accessLogs">
                                        <img src={accessLog} height="30px" width="13%" style={{ borderRadius: "50px", marginRight: "3%", marginLeft: "2%" }} alt="home" />
                                        Access Logs
                                    </label>
                                    <hr style={{ color: "rgba(0,0,0,0.7)" }} />
                                </li>
                                {/* <!-- End Sticky Notes --> */}





                            </ul>

                        </aside>

                        {/* ---------------------
---- End Sidebar Menu ----
---------------------- */}





                    </div>
                </nav>

                <main className="page-content">
                    <div className="container-fluid">


                        <nav class="navbar navbar-expand-sm navbar-dark navbarss" style={{ background: "#DD3333", borderBottom: "1px solid rgba(0,0,0,0.01)", boxShadow: "0px 2px 50px rgba(0,0,0,0.2)", zIndex: "100" }} >
                            <div class="container-fluid ">
                                {/* <a class="navbar-brand " href="#">Navbar</a> */}
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse " id="navbarSupportedContent">
                                    <ul class="navbar-nav ms-auto">

                                        <li className="nav-item">
                                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                                <Tooltip title="Mails">
                                                    <IconButton onClick={MailClick} size="small" style={{ marginTop: "4px" }}>
                                                        <Badge badgeContent={1} color="success">
                                                            <EmailOutlinedIcon className="text-white" />
                                                        </Badge>       </IconButton>
                                                </Tooltip>
                                            </Box>

                                            <Menu
                                                anchorEl={mail}
                                                open={Mailopen}

                                                onClose={MailClose}
                                                onClick={MailClose}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 1.5,
                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 15,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MenuItem style={{ backgroundColor: "#ddd", height: "50px" }}>
                                                    <div style={{ display: "flex", marginTop: "15px" }}>
                                                        &nbsp;
                                                        <p style={{ fontSize: "13px" }}>1 Mail</p>
                                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                        <p style={{ fontSize: "13px" }}> <a href="#" style={{ textDecoration: "none" }}> View All</a></p>
                                                        &nbsp;
                                                    </div>
                                                </MenuItem>

                                                <Divider />

                                                <div style={{ height: "200px", overflow: "auto" }}>
                                                    <MenuItem>
                                                        <div style={{ backgroundColor: "greenyellow", padding: "0 5px", borderRadius: "20px" }}>
                                                            <span style={{ fontSize: "11px" }} className='my-1 mx-1' >No Total</span>

                                                        </div>
                                                        <p style={{ fontSize: "13px", paddingLeft: "10px" }} className='my-2' >Inspection for 22/11/2021</p>

                                                    </MenuItem>

                                                </div>
                                            </Menu>



                                        </li>
                                        <li className="nav-item">

                                            {/* <IconButton color="inherit" > */}
                                            {/* <Badge badgeContent={4} color="success">


                                                    <NotificationsIcon className="text-white" />
                                                </Badge> */}
                                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                                <Tooltip title="Notifications">
                                                    <IconButton onClick={Clicking} size="small" className="mx-2" style={{ marginTop: "3px" }}>
                                                        <Badge badgeContent={1} color="success">


                                                            <NotificationsIcon className="text-white" />
                                                        </Badge>          </IconButton>
                                                </Tooltip>
                                            </Box>

                                            <Menu
                                                anchorEl={notification}
                                                open={opening}
                                                //    style={{marginLeft:"50px"}}
                                                onClose={Closing}
                                                onClick={Closing}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 1.5,
                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 15,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MenuItem style={{ backgroundColor: "#ddd", height: "50px" }}>
                                                    <div style={{ display: "flex", marginTop: "15px" }}>
                                                        &nbsp;
                                                        <p style={{ fontSize: "13px" }}>1 Notifications</p>
                                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                        <p style={{ fontSize: "13px" }}> <a href="#" style={{ textDecoration: "none" }}> View All</a></p>
                                                        &nbsp;
                                                    </div>
                                                </MenuItem>

                                                <Divider />

                                                <div style={{ height: "200px", overflow: "auto" }}>
                                                    <MenuItem>
                                                        <div style={{ backgroundColor: "greenyellow", padding: "0 5px", borderRadius: "20px" }}>
                                                            <span style={{ fontSize: "11px" }} className='my-1 mx-1' >No Total</span>

                                                        </div>
                                                        <p style={{ fontSize: "13px", paddingLeft: "10px" }} className='my-2' >Inspection for 22/11/2021</p>

                                                    </MenuItem>

                                                </div>
                                            </Menu>
                                            {/* </IconButton> */}


                                        </li>


                                        <li class="nav-item">
                                            <IconButton color="inherit">

                                                <Badge color="secondary" style={{ marginTop: "0px" }}>
                                                    {
                                                        setSeconds
                                                    }

                                                </Badge>


                                            </IconButton>
                                        </li>


                                        <li class="nav-item">






                                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                                                {/* <Tooltip> */}

                                                <IconButton onClick={handleClick} size="small" sx={{ ml: 5 }}>
                                                    <FaUserCircle style={{ color: "white" }} size={30} />

                                                    <Typography className='text-white' style={{ fontFamily: "Poppins , sans-serif" }}>&nbsp; {(!userData) ? "Omair Ali" : userData.user_name}</Typography>
                                                </IconButton>
                                                {/* </Tooltip> */}
                                            </Box>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                onClick={handleClose}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        width: "250px",
                                                        mt: 1.5,

                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 100,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MenuItem>
                                                    {/* <Avatar />  Omair Ali */}
                                                    <FaUserCircle size={50} style={{ border: "2px solid red", borderRadius: "50px" }} />
                                                    <span className="span1 mx-4 text-center" style={{ fontSize: "25px" }}>
                                                        {(!userData) ? "Omair Ali" : userData.user_name}
                                                        <p style={{ fontSize: "14px" }}>
                                                            {(!userData) ? "Demo Position" : userData.web_position}
                                                            <br />
                                                            {(!userData) ? "--TRAINEE--" : `-- ${userData.web_role} --`}
                                                        </p> </span>

                                                </MenuItem>

                                                <Divider />

                                                {/* <Link to="/dashboard/userProfile" style={{ textDecoration: "none" }} className='text-dark'> */}
                                                    <MenuItem onClick={() => setDemo(<UserProfile />)} style={{ fontFamily: "Poppins , sans-serif", color: "#000" }}>
                                                        {/* <ListItemIcon> */}
                                                        <i class='bx bx-user' ></i>
                                                        {/* <PersonAdd fontSize="small" /> */}
                                                        {/* </ListItemIcon> */}
                                                        <span className='mx-3'>

                                                            My Profile
                                                        </span>
                                                    </MenuItem>
                                                {/* </Link> */}
                                                {/* <Link to="/dashboard/positionRelief" style={{ textDecoration: "none" }} className='text-dark'> */}
                                                    <MenuItem onClick={() => setDemo(<PositionRelief />)} style={{ fontFamily: "Poppins , sans-serif", color: "#000" }}>
                                                        {/* <ListItemIcon> */}
                                                        <i class='bx bx-cog'></i>
                                                        {/* <PersonAdd fontSize="small" /> */}
                                                        {/* </ListItemIcon> */}
                                                        <span className='mx-3'>

                                                            Position Relief
                                                        </span>
                                                    </MenuItem>
                                                {/* </Link> */}
                                                <Link to="/" style={{ textDecoration: "none" }} className='text-dark'>
                                                <MenuItem 
                                                // onClick={() => Logout()}
                                                 style={{ fontFamily: "Poppins , sans-serif", color: "#000" }}>
                                                    {/* <ListItemIcon> */}
                                                    <i class='bx bx-log-in-circle'></i>
                                                    {/* <PersonAdd fontSize="small" /> */}
                                                    {/* </ListItemIcon> */}
                                                    <span className='mx-3'>

                                                        Log Out
                                                    </span>
                                                </MenuItem>
                                                </Link>
                                            </Menu>


                                        </li>

                                        &nbsp;   &nbsp;   &nbsp;   &nbsp;



                                    </ul>
                                </div>
                            </div>
                        </nav>




                        <div style={{ marginTop: "8%" }}>


                            {demo}

                        </div>

                    </div>
                </main>

            </div>

        </div>
    )
}

export default Sidebars
