import DOMPurify from 'dompurify';
import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useSortBy } from "react-table";
import { FaEdit , FaTrash } from 'react-icons/fa';
import { api, authorityApi } from '../../api/api';
import InputsHi from '../../LOGs/input';
import SnackBar from '../../LOGs/SnackBar';
import Lottie from 'react-lottie';
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const ViewAuthority = () => {
    const [keyword, setKeyword] = useState('');
    const [status , setStatus] = useState([]);
    
    const [authority , setAuthority] = useState("");
    const [description , setDescription] = useState("");
    
    const [indexa , setIndexa] = useState("");
    // const [givenAuthority , setGivenAuthority] = useState("");
    // const [givenDescription , setGivenDescription] = useState("");

    const[contentLoaded , setContentLoaded] = useState(true);
    
    const[showEditor , setShowEditor] = useState(true);
    const[showDelete , setShowDelete] = useState(true);


    setTimeout(()=>{
        
        if(status.length === 0){
            setContentLoaded(false)
        }else(
            setContentLoaded(true)
        )

    },5000)









    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [severity, setSeverity] = useState("error");
    
    
    useEffect(() => {
        fetch(api + authorityApi, {method:"GET"} )
        .then((response) => response.json())
            .then((responseJson) => {
                setStatus(responseJson);              
            //    console.log(responseJson);              
            })
            .catch((error) => {
                console.error(error);
            });
    });



    const UpdateData = () =>{
        
        let authorityClean = DOMPurify.sanitize(authority, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });
    
    
        console.log(authorityClean);
        console.log(descriptionClean);
    
        const AuthorityData = 
    
            {authority: authorityClean,
            description: descriptionClean};
        
            let convertedAuthorityData = JSON.stringify(AuthorityData);
            console.log(convertedAuthorityData);
    
        
        fetch(
            `${api + authorityApi}/${indexa}`
            , {
            method: "PUT",
            headers : {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: convertedAuthorityData
        
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                if(responseJson){
                    setOpen(true);
                    setSeverity("success")
                    setMsg("Data Successfully Updated")
                    
                    setShowEditor(true);
                }
            })
            .catch((error) => {
                console.error(error);
                setOpen(true);
                    setSeverity("error")
                    setMsg(error)

                    setShowEditor(false);

            });




}




const DeleteData = (id)=>{
    fetch(
        `${api + authorityApi}/${id}`
        , {
        method: "DELETE",
        headers : {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
    
    })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson);
            if(responseJson === 1){
                setOpen(true);
                setSeverity("success")
                setMsg("Data Deleted Successfully")
                setShowDelete(true)
            }else{
                setOpen(true);
                setSeverity("error")
                setMsg("Data Not Deleted Successfully")
                setShowDelete(true)
            }
        })
        .catch((error) => {
            console.error(error);
            setOpen(true);
            setSeverity("error")
            setMsg(error)
            setShowDelete(true)
        });

      
}








    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require("../../../assests/loading.json"),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }

    const defaultErrorOptions = {
        loop: true,
        autoplay: true,
        animationData: require("../../../assests/notfound.json"),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }


      const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setOpen(false);
      };


  
    const mysubmit = () => {
        let keywordClean = DOMPurify.sanitize(keyword, { USE_PROFILES: { html: false } });
   
        console.log(keywordClean);
     
    }
    const [edit , setEdit] = useState(true)
	const [details , setDetails] = useState(true)
   
        return (
            <div className="container" style={{fontFamily:"Poppins , sans-serif"}}>
           
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">View Authorities</h1>
            </div>
            <form onSubmit={(e)=>e.preventDefault()}> 
            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Keyword:</label> <br />
                       <div className="App">
                    <InputsHi onChange={(e)=>setKeyword(e.target.value)} type="text" id="inp2" placeholder="Enter Keyword..." />
                </div>
                </div>
                
                
                
            </div>
            
              
    
    
                <br/>
                <br/>
            
    <SnackBar />
    {/* <button 
 className="btn px-4 py-2" 
 style={{ backgroundColor: "#DD3333", borderRadius: "50px", color: "white", marginBottom: "8%", float: "right", marginRight: "105px", fontFamily: "Poppins , sans-serif" }} 
 onClick={()=>mysubmit()}
>Submit</button>  */}
       <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
    
    </form>
<br />
<br />
<br />
<br />
<br />

    {/* <div class="portlet-body container" style={{width:"70%"}}> */}

    <form style={{display:"flex"}}>	
    <h4 className='fw-bold'>Authorities:</h4>
        <button class="pull-left btn btn-danger mx-4" style={{height:"40px"}}>Create New</button>
        <br/><br/>
        <br/>
        
    </form>			
    <div class="table-scrollable text-center">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th scope="col" style={{width:"50px"}}>
                        Authorities
                    </th>
                    <th scope="col" style={{width:"150px"}}>
                        Description
                    </th>
                    
                    <th scope="col" style={{width:"50px"}}>
                        Action
                    </th>
                    
                </tr>
            </thead>
            <tbody>
            {(status.length !== 0) ? status.map((e , i) => {
          return (
            <tr key={i}>
                        <td className='text-center' style={{fontSize:"14px"}}>
                            {e.authority}								</td>
                        <td  className='text-center' style={{fontSize:"14px"}}>
                            {e.description}
                        </td>
                        <td>
                            
                        <div className="row text-center">
<div className="col-md-3"></div>
<div className="col-md-3">




<div class="spinner-border text-danger" style={(showEditor) ? {display:"none"} : {display:"block", marginTop:"10px"}} role="status">
  <span class="visually-hidden">Loading...</span>
</div>


                                <span  onClick={()=>{
                                      setIndexa(e.id)
                                      setAuthority(e.authority)
                                      setDescription(e.description)
                                      setShowEditor(false)
                                    }} class="my-2" data-bs-toggle="modal" data-bs-target="#viewAuthority" style={(showEditor) ? {fontSize:"20px" , border:"none", display:"block"} : {fontSize:"20px" , border:"none" , display:"none"}}>
                                    <FaEdit 
                                                                     
                                  className="my-2" /></span>









                      
                                <div class="modal fade" id="viewAuthority" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Authorities</h5>
        <button type="button" class="btn-close" onClick={()=>setShowEditor(true)} data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
      <form onSubmit={(e) => e.preventDefault()}>
                <div className="row" style={{marginTop:"2%"}}>
                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "-56%" }} htmlFor="inp2" >Authority</label> <br />
                        <div className="App">
                             <input value={authority} className="mx-2 inpFoc radius" style={{marginBottom:"3%", padding:"13px 10px"}} type="text" 
                            onChange={(e) => setAuthority(e.target.value)}
                             id="inp2" placeholder="Enter Authority..." />
                        </div>
                    </div>
                    <div className="col-md-6"  >

                        <label htmlFor="inp3" className="fw-bold" style={{ marginLeft: "-54%" }} >Description</label> <br />
                        <div className="App">
                            <textarea value={description} rows="3" id="inp4" 
                            onChange={(e) => setDescription(e.target.value)}
                             className="px-2 mx-2 inpFoc radius" style={{marginBottom:"1%", padding:"10px 12px"}} type="text" placeholder="Enter Description ..." />
                        </div>
                    </div>


                </div>


                <br />
                <br />
<div className='modal-footer'>
    
                <button onClick={()=>setShowEditor(true)} data-bs-dismiss="modal"
                className="btn px-4 py-2" style={{ backgroundColor: "#FB6A6A", borderRadius: "50px", color: "white", marginRight: "1%", float: "right" }}>Cancel</button>
                
                <button data-bs-dismiss="modal"
                onClick={() => UpdateData()}
                className="btn px-4 py-2" style={{ backgroundColor: "#DD3333", borderRadius: "50px", color: "white",marginRight: "6%",  float: "right" }}>Submit</button>
                </div>
            </form>
    
      </div>

    </div>
  </div>
</div>















                                </div>
                                <div className="col-md-3">

                                <div class="spinner-border text-danger" style={(showDelete) ? {display:"none"} : {display:"block", marginTop:"10px"}} role="status">
  <span class="visually-hidden">Loading...</span>
</div>

                                <button class="my-2" onClick={()=>{DeleteData(e.id) 
                                  setShowDelete(false)}} style={(showDelete) ? {fontSize:"20px" , border:"none" , display:"block", backgroundColor:"transparent"} : {fontSize:"20px" , border:"none" , display:"none"}}>
                                    <FaTrash 
                                    style={ details ? { color:'black'} : {color : 'gray'} } 
                                    
                                    // onClick={()=> {(details === true) ? setDetails(false): setDetails(true)}}
                                     className="my-2" /></button>
                            </div>
                            
                            </div>


                        </td>











                        
                    </tr>









          );
        })
    :
  


<div style={{height:"100px"}}>
    
        <Lottie options={defaultOptions} style={(contentLoaded) ? {marginLeft:"200%", display:"block",marginTop:"50px" , paddingBottom:"100px", border:"0"} : {marginLeft:"225%", display:"none"} } height={250} width={250} />
  <div style={(contentLoaded) ? {display:"none"} : {display:"block",marginTop:"50px" , paddingBottom:"100px"} }>

    <Lottie options={defaultErrorOptions} style={{marginLeft:"225%"}} height={150} width={150} />
  <p style={(status.length === 0) ? {display:"block", marginLeft:"60%", width:"400%"} : {display:"none"}}>No Data Available</p>
  </div>
</div>


    }




                                        
                                        </tbody>
        </table>













    </div>



    <Stack spacing={2} sx={{ width: "100%" }}>

<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
  <Alert severity={severity} onClose={handleClose} style={{ fontFamily: "Poppins , sans-serif" }} >
    {msg}! &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp;
  </Alert>
</Snackbar>
</Stack>





    
</div>





    )
}

export default ViewAuthority
