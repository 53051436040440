import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const ElogFault = () => {

    const [timeStamp, setTimeStamp] = useState('');
    const [equipment, setEquipment] = useState('');
    const [description, setDescription] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });
        let equipmentClean = DOMPurify.sanitize(equipment, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(equipmentClean);
        console.log(descriptionClean);
    }


    return (
        <div style={{fontFamily:"Poppins, sans-serif"}}>
        {/* <Navbar/> */}
     
        <div className="App">
                        <h1 style={{ color: "#505050" }} className="fw-bold my-5">Elog Fault Reporting</h1>
                    </div>
        
                    <div className="row">
                        <div className="col-md-6">
                               <label className="fw-bold" style={{marginLeft:"15%" }}  htmlFor="inp2" >Time Stamp</label> <br />
                           <div className="App">
                            <InputsHi type="datetime-local" onChange={(e) => setTimeStamp(e.target.value)} id="inp2" />
                        </div>
                        </div>
                        <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%" }}  htmlFor="inp3" >Equipment</label> <br />
        <div className="App">
                            <InputsHi type="text" id="inp3" onChange={(e) => setEquipment(e.target.value)} placeholder="Enter Equipment" />
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%" }}  htmlFor="inp4" >Description</label> <br />
                          <div className="App">
                           <textarea id="inp4" onChange={(e) => setDescription(e.target.value)} className="px-2 mx-2 inpFoc radius" style={{ height: "80%", marginBottom:"3%" }} type="text" placeholder="Enter Description.." />
        </div>
                        </div>
                        <div className="col-md-6">
                       
                        </div>
        
                        </div>
        
                        <SnackBar />
           <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
        
                </div>
    )
}

export default ElogFault
