import React from 'react'
import InputsHi from '../../LOGs/input'
import SnackBarUpdate from '../../LOGs/update';

const PersonalInfo = () => {
    return (

        <div className="back7" style={{fontFamily:"Poppins , sans-serif"}} >
        

            <div className="row App">
                {/* <h1 style={{ color: "#505050" }} className="fw-bold my-5">Edit User</h1> */}
            </div><br/>

	    <div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>First Name: </span>
								<input id="ojti_exp" class="input-block-level form-control" type="text" name="first name" required="required" autocomplete="off"/>
							</div>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Last Name</span>
							<input id="investigator" class="input-block-level form-control" type="text" name="last name" required="required" autocomplete="off"/>
							</div><br/>
						</div>
						
	</div>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>User Name</span>
								<input id="ojti_exp" class="input-block-level form-control date-picker" type="datetime-local" name="user role" required="required" autocomplete="off"/>
							</div><br/>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Phone 1</span>
							<input id="investigator" class="input-block-level form-control date-picker" type="datetime-local" name="Designation" required="required" autocomplete="off"/>
							</div>
						</div>
	</div>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>Mobile</span>
								<input id="ojti_exp" class="input-block-level form-control date-picker" type="datetime-local" name="user role" required="required" autocomplete="off"/>
							</div>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Email</span>
							<input id="investigator" class="input-block-level form-control date-picker" type="datetime-local" name="Designation" required="required" autocomplete="off"/>
							</div>
						</div>
	</div><br/>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>Position</span>
								<input id="ojti_exp" class="input-block-level form-control " type="text" name="insurance" required="required"  autocomplete="off"/>
							</div><br/>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>User Initial</span>
							<input id="investigator" class="input-block-level form-control" type="text" name="authority" required="required" autocomplete="off"/>
							</div>
						</div>
	</div>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>Image</span>
								<input id="ojti_exp" class="input-block-level form-control" type="file" required="required" autocomplete="off"/>
							</div>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Phone 2: </span>
							<input id="investigator" class="input-block-level form-control date-picker" type="datetime-local" name="staffdate" required="required" autocomplete="off"/>
							</div>
						</div>
	</div>
	<br/>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>Date of Birth</span>
								<input id="ojti_exp" class="input-block-level form-control" type="text" required="required" autocomplete="off"/>
							</div>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Nationality: </span>
							<input id="investigator" class="input-block-level form-control" type="text" name="nationality" required="required" autocomplete="off"/>
							</div>
						</div>
	</div><br/>

	<div class="row pform">
       
	   <input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
		   <div class="col-md-6" style={{width:"40%"}}>
			   <div class="form-group">
				   <span>Address Line 1: </span>
				   <input id="ojti_exp" class="input-block-level form-control" type="text" required="required" autocomplete="off"/>
			   </div>
		   </div>
		   <div class="col-md-6" style={{width:"40%"}}>
			   <div class="form-group">
			   <span>Address Line 2: </span>
			   <input id="investigator" class="input-block-level form-control" type="text" name="nationality"  required="required" autocomplete="off"/>
			   </div>
		   </div>
</div>
								<SnackBarUpdate />
						</div>
						
					

    )
}

export default PersonalInfo;
