import React from 'react'
import InputsHi from '../../LOGs/input'
import SnackBarUpdate from '../../LOGs/update';
const WorkInfo = () => {
    return (
        <div className="back7" style={{fontFamily:"Poppins , sans-serif"}} >
        

            <div className="row App">
                {/* <h1 style={{ color: "#505050" }} className="fw-bold my-5">Edit User</h1> */}
            </div><br/>

	    <div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>User Role </span>
								<input id="ojti_exp" class="input-block-level form-control" type="text" name="User Role" required="required" autocomplete="off"/>
							</div>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Designation</span>
							<input id="investigator" class="input-block-level form-control" type="text" name="Designation" required="required" autocomplete="off"/>
							</div><br/>
						</div>
						
	</div>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>Medical Expiry Date</span>
								<input id="ojti_exp" class="input-block-level form-control date-picker" type="datetime-local" name="medical" required="required" autocomplete="off"/>
							</div><br/>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>ATC Expiry Date</span>
							<input id="investigator" class="input-block-level form-control date-picker" type="datetime-local" name="ATC" required="required" autocomplete="off"/>
							</div>
						</div>
	</div>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>ADP Exipry Date</span>
								<input id="ojti_exp" class="input-block-level form-control date-picker" type="datetime-local" name="ADP" required="required" autocomplete="off"/>
							</div>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Emirates ID Expiry Date</span>
							<input id="investigator" class="input-block-level form-control date-picker" type="datetime-local" name="Emirates" required="required" autocomplete="off"/>
							</div>
						</div>
	</div><br/>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>Insurance</span>
								<input id="ojti_exp" class="input-block-level form-control " type="text" name="insurance" required="required"  autocomplete="off"/>
							</div><br/>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Authority</span>
							<input id="investigator" class="input-block-level form-control" type="text" name="authority" required="required" autocomplete="off"/>
							</div>
						</div>
	</div>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>SAP ID</span>
								<input id="ojti_exp" class="input-block-level form-control" type="text" required="required" autocomplete="off"/>
							</div>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Staff Date </span>
							<input id="investigator" class="input-block-level form-control date-picker" type="datetime-local" name="staffdate" required="required" autocomplete="off"/>
							</div>
						</div>
	</div>
	<br/>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>Company </span>
								<input id="ojti_exp" class="input-block-level form-control" type="text" required="required" autocomplete="off"/>
							</div>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Agent Type </span>
							<select id="investigator" class="input-block-level form-control" type="option" name="agent type" required="required" autocomplete="off">
                            <option value="ATCO">ATCO</option>
                                <option value="ATCA">ATCA</option>

                            </select>
							</div>
						</div>
	</div><br/>

	
								<SnackBarUpdate />
						</div>
						
    )
}

export default WorkInfo;
