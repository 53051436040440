import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const NonDutyRunwayOperations = () => {

    const [timeStamp, setTimeStamp] = useState('');
    const [runway, setRunway] = useState('');
    const [description, setDescription] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });
        let runwayClean = DOMPurify.sanitize(runway, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(runwayClean);
        console.log(descriptionClean);
    }




    return (
        <div style={{fontFamily:"Poppins,sans-serif"}}>
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Non-Duty Runway Change</h1>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Time Stamp</label> <br />
                  <div className="App">
                    <InputsHi type="datetime-local" onChange={(e)=>setTimeStamp(e.target.value)} id="inp2" />
                </div>
                </div>
                <div className="col-md-6">
                    <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp3" >Runway</label> <br />
<div className="App">
                    <InputsHi type="text" id="inp3" onChange={(e)=>setRunway(e.target.value)} placeholder="Enter Runway.." />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp4" >Description</label> <br />
                  <div className="App">
                    <textarea id="inp4" className="px-2 mx-2 inpFoc radius" onChange={(e)=>setDescription(e.target.value)} style={{ height: "80%", marginBottom: "3%" }} type="text" placeholder="Enter Description.." />

                </div>
                </div>
            </div>


            {/* Check Boxxes */}

            <div style={{ marginLeft: "40px", marginTop: "80px", fontFamily: 'Poppins', fontWeight: '400' }}>

               

                <h5 style={{ fontFamily: 'Poppins', fontWeight: '600' }}>Co-ordinate non-duty operation with DWC:</h5>


                <ul style={{
                    listStyleType: "none", width: "550px",
                    display: "inline-block"
                }}>
                    <li><input type="checkbox" id="issue_non_standard_clearance_or_departure" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="issue_non_standard_clearance_or_departure"  >Issue non-standard clearance for departure</label></li>

                    <li ><input type="checkbox" id="resolve_traffic_conflicts_of_arrival_departure" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="resolve_traffic_conflicts_of_arrival_departure"> Resolve traffic conflicts of arrival/departure</label></li>

                </ul>


                <h5 style={{ fontFamily: 'Poppins', fontWeight: '600' }}>Change the following Settings:</h5>
                <ul style={{
                    listStyleType: "none", width: "550px",
                    display: "inline-block"
                }}>
                    <li>
                        <input type="checkbox" id="tb4" name="tb4" value="Boat" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb4"> ILS</label><br /></li>
                    <li>
                        <input type="checkbox" id="tb5" name="tb5" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb5"> Airfield lightning runway direction</label><br /><br />
                    </li>
                </ul>
                <h6 style={{ fontFamily: 'Poppins', fontWeight: '600', width: "500px" }}>Inform DWC that ILS and Lights are configured for RIU</h6>

            </div>
            {/* Check Boxxes End */}


            <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>

        </div>


    )
}

export default NonDutyRunwayOperations
