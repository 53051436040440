import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import InputsHi from '../LOGs/input';
import { FaEdit } from "react-icons/fa"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import Switches from '../LOGs/switch';
import DOMPurify from 'dompurify';

const Users = () => {
	const [users, setUsers] = useState([
		{
			fullName: "Mohammed Ali Omair",
			email: "mohammed@gmail.com",
			mobile: "0325-8424610",
			role: "admin"
		}])
	const [userName, setUserName] = useState('');
	const mysubmit = () => {
		let userNameClean = DOMPurify.sanitize(userName, { USE_PROFILES: { html: false } });

		console.log(userNameClean);


	}


	const [edit, setEdit] = useState(true)
	const [user, setUser] = useState(true)
	const [details, setDetails] = useState(true)

	function disableButton() {
		var btn = document.getElementById('btn');
		btn.disabled = true;
	}

	return (

		<div className="font back7">
			
			<div className="App">
				<h1 style={{ color: "#505050", fontFamily: "Poppins", fontSize: "45px" }} className="fw-bold my-5">Users</h1>
			</div>
			<br />


			<div>

				<div class="container">

					<div>
						<div class="row pform" style={{ paddingTop: "1%" }}>
							<form onSubmit={(e) => e.preventDefault()}>





								<div className="row">
									<div className="col-md-6">
										<label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Role</label> <br />
										<div className="App">
											<InputsHi type="text" placeholder="Enter Role..." id="inp2" />
										</div>
									</div>
									<div className="col-md-6">
										<label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Designation</label>
										<div className="App">
											<select id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom: "4%" }}>
												<option selected >-- Select Designation --</option>
												<option value="ATCO">ATCO</option>
												<option value="ATCA">ATCA</option>
											</select>
											<br />

										</div>
									</div>
								</div>


								<div className="row">

									<div className="col-md-6">
										<label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Initials</label>
										<div className="App">
											<select id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom: "4%" }}>
												<option selected >-- Select Initials --</option>
												<option value="OMA">OMA</option>
											</select>
											<br />

										</div>
									</div>
									<div style={{ paddingTop: "1%", paddingBottom: "1%" }}>
										<div>


											<button className="btn px-4 py-2 mx-3" style={{ backgroundColor: "red", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "6%", float: "right" }}>Filter</button>

											<button className="btn px-4 py-2 mx-3" style={{ backgroundColor: "#FB6A6A", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "6%", float: "right" }}>Cancel</button>

										</div>
									</div>
								</div>
							</form></div>
						<div class="table-scrollable">

							<table class="table table-striped table-bordered table-hover">
								<thead>

									<tr>

										<th scope="col" style={{ width: "60px" }} className="text-center">
											No.
										</th>
										<th scope="col" className="text-center">
											Full Name
										</th>
										<th scope="col" className="text-center">
											E-mail
										</th>

										<th scope="col" className="text-center">
											Mobile
										</th>
										<th scope="col" className="text-center">
											Role
										</th>

										<th scope="col" className="text-center" style={{ width: "15%" }}>
											Actions
										</th>
										<th scope="col" className="text-center" style={{ width: "10%" }}>
											Disable
										</th>
									</tr>
								</thead>
								<tbody>
									{users.map((e, i) => {
										return (
											<tr >

												<td style={{ fontSize: "15px" }} className="text-center">
													{i+1}
													</td>
												<td style={{ fontSize: "15px" }} className="text-center">
												{e.fullName}								</td>


												<td style={{ fontSize: "15px" }} className="text-center">
													{e.email}									</td>
												<td style={{ fontSize: "15px" }} className="text-center">
													{e.mobile}
												</td>
												<td style={{ fontSize: "15px" }} className="text-center">
													{e.role}
												</td>

												<td >

													<div className="row text-center">
														<div className="col-md-1"></div>
														<div className="col-md-3">
															{/* <Link to="/dashboard/tabst"> */}
															<button class="my-2" style={{ fontSize: "20px", border: "none" }}><FaEdit style={edit ? { color: 'black' } : { color: 'gray' }} onClick={() => { (edit === true) ? setEdit(false) : setEdit(true) }} className="my-2" /></button>
															{/* </Link> */}
														</div>
														<div className="col-md-3">

															<button class="my-2" style={{ fontSize: "20px", border: "none" }}><InfoIcon style={details ? { color: 'black' } : { color: 'gray' }} onClick={() => { (details === true) ? setDetails(false) : setDetails(true) }} className="my-2" /></button>
														</div>
														<div className="col-md-3">

															{/* <Link to="/dashboard/userProfile"> */}
															<button class="my-2" style={{ fontSize: "20px", border: "none" }}><AccountCircleIcon style={user ? { color: 'black' } : { color: 'gray' }} onClick={() => { (user === true) ? setUser(false) : setUser(true) }} className="my-2" /></button>
															{/* </Link> */}
														</div>

													</div>
												</td>
												<td>
													<div style={{ marginLeft: "35px" }}><Switches /></div></td>

											</tr>
										)
									})}


								</tbody>
							</table>
						</div>

					
					</div>
				</div>
			</div>
		</div>
	)
}

export default Users;
