import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';
// 
const SarPhaseDeclaration = () => {

    const [phase, setPhase] = useState('');
    const [incidentType, setIncidentType] = useState('');
    const [aircraftType, setAircraftType] = useState('');
    const [callSign, setCallSign] = useState('');
    const [pob, setPob] = useState('');
    const [timeOfLastContract, setTimeOfLastContract] = useState('');
    const [lastPosition, setLastPosition] = useState('');
    const [colorMarking, setColorMarking] = useState('');
    const [dangerousGoods, setDangerousGoods] = useState('');
    const [actionTaken, setactionTaken] = useState('');


    const mysubmit = () => {
        let phaseClean = DOMPurify.sanitize(phase, { USE_PROFILES: { html: false } });
        let incidentTypeClean = DOMPurify.sanitize(incidentType, { USE_PROFILES: { html: false } });
        let aircraftTypeClean = DOMPurify.sanitize(aircraftType, { USE_PROFILES: { html: false } });
        let callSignClean = DOMPurify.sanitize(callSign, { USE_PROFILES: { html: false } });
        let pobClean = DOMPurify.sanitize(pob, { USE_PROFILES: { html: false } });
        let timeOfLastContractClean = DOMPurify.sanitize(timeOfLastContract, { USE_PROFILES: { html: false } });
        let lastPositionClean = DOMPurify.sanitize(lastPosition, { USE_PROFILES: { html: false } });
        let colorMarkingClean = DOMPurify.sanitize(colorMarking, { USE_PROFILES: { html: false } });
        let dangerousGoodsClean = DOMPurify.sanitize(dangerousGoods, { USE_PROFILES: { html: false } });
        let actionTakenClean = DOMPurify.sanitize(actionTaken, { USE_PROFILES: { html: false } });

        console.log(phaseClean);
        console.log(incidentTypeClean);
        console.log(aircraftTypeClean);
        console.log(callSignClean);
        console.log(pobClean);
        console.log(timeOfLastContractClean);
        console.log(lastPositionClean);
        console.log(colorMarkingClean);
        console.log(dangerousGoodsClean);
        console.log(actionTakenClean);
    }

    return (
        <div style={{fontFamily:"Poppins , sans-serif"}}>
            {/*  */}
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">SAR Phase Declaration</h1>
            </div>


        
                <h5 style={{ color: "#505050", marginLeft: "50px", fontWeight: "600" , paddingBottom:"1.2%" }} className="fw-bold">Collect as much of the following as possible:</h5>
                <form onSubmit={(e)=>e.preventDefault()}>
                <div className="row ">
                <div className="col-md-6">
                    <label className="fw-bold" style={{marginLeft:"15%" }} htmlFor="inp2" >Phase</label> <br />
                    <div className="App">
                        <select id="inp3" onChange={(e) => setPhase(e.target.value)} className="px-3 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom: "5%" }}>
                            <option selected >Select Phase </option>
                            <option value="Incerfa">Incerfa</option>
                            <option value="Alterfa">Alterfa</option>
                            <option value="Detresfa">Detresfa</option>

                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="fw-bold" style={{marginLeft:"15%" }}  htmlFor="inp4" >Incident or emergency type</label> <br />
<div className="App">
                    <InputsHi type="text" id="inp4" onChange={(e) => setIncidentType(e.target.value)} autocomplete="on" placeholder="Incident or emergency type" />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold" style={{marginLeft:"15%" }}  htmlFor="inp5" >Aircraft type</label> <br />
                    <div className="App">
                        <InputsHi type="text" id="inp5" onChange={(e) => setAircraftType(e.target.value)} placeholder="Aircraft type" />
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="fw-bold "  style={{marginLeft:"15%" }}  htmlFor="inp6" >Call sign</label> <br />
<div className="App">
                    <InputsHi type="text" id="inp6" onChange={(e) => setCallSign(e.target.value)} placeholder="Call sign" />
                </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold "  style={{marginLeft:"15%" }}  htmlFor="inp7" >POB</label> <br />
                    <div className="App">
                        <InputsHi type="text" id="inp7" onChange={(e) => setPob(e.target.value)} placeholder="POB" />
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="fw-bold" htmlFor="inp8"  style={{marginLeft:"15%" }}  >Time, means, and unit of last contact</label> <br />
                    <div className="App">
                    <InputsHi type="text" id="inp8" onChange={(e) => setTimeOfLastContract(e.target.value)} placeholder="Time, means, and unit of last contact" />
                </div>
                </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold" htmlFor="inp4"  style={{marginLeft:"15%" }}  >Last position & how it was determined</label> <br />
                        <div className="App">
                            <InputsHi type="text" id="inp3" onChange={(e) => setLastPosition(e.target.value)} placeholder="Last position & how it was determined" />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="fw-bold" htmlFor="inp4"  style={{marginLeft:"15%" }}  >Color/distinctive markings</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp3" onChange={(e) => setColorMarking(e.target.value)} placeholder="Color/distinctive markings" />
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold" htmlFor="inp4"  style={{marginLeft:"15%" }}  >Dangerous goods</label> <br />
                        <div className="App">
                            <InputsHi type="text" onChange={(e) => setDangerousGoods(e.target.value)} id="inp3" placeholder="Dangerous goods" />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="fw-bold" htmlFor="inp4"  style={{marginLeft:"15%" }}  >Action taken</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp3" onChange={(e) => setactionTaken(e.target.value)} placeholder="Action taken" />
                    </div>
                    </div>
                </div>
       


            <div style={{ marginLeft: "40px", marginTop: "80px", fontFamily: 'Poppins', fontWeight: '400' }}>

                <h5 style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: "16px" }}>Pass the information above to ‘Natl SAR Ctr’ & ‘Area Sup’</h5>
                <h5 style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: "16px" }}>Notify the following of Phase Declaration</h5>




                <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb1"name="tb1" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb1"> Support Manager</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb2"name="tb2"/>
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb2"> GMC/ADC/CIC</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb3" name="tb3" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb3"> D/MGR (1 or 2) </label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb4" name="tb4" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb4"> Refer to IFER Manual </label><br/>
                    </li>
      
                </ul>
<br />


                <h5 style={{ fontFamily: 'Poppins', fontWeight: '500', fontSize: "14px" }}>Update OMAE ACC with any useful information AND when the emergency no longer exists</h5>

                <li>Only the National Search and Rescue Center can terminate an ALERFA or DETRESFA phase</li><br />
            </div>
            {/* Check Boxxes End */}
            <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
</form>
        </div>
    )
}

export default SarPhaseDeclaration;
