import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';
// 
const Runway = () => {
    const [runway, setRunway] = useState('');
    const [description, setDescription] = useState('');

    const mysubmit = () => {
        let runwayClean = DOMPurify.sanitize(runway, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });


        console.log(runwayClean);
        console.log(descriptionClean);

    }
    return (
        <div className="container" style={{fontFamily:"Poppins , sans-serif"}}>
            {/*  */}
                 <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Runway</h1>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Runway</label> <br />
                   <div className="App">
                <InputsHi type="text"  onChange={(e) => setRunway(e.target.value)} id="inp2" placeholder="Enter Runway..." />
            </div>
            </div>
			<div className="col-md-6"  >

            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Description</label> <br />
			<div className="App">
                           <textarea id="inp4"  onChange={(e) => setDescription(e.target.value)} className="px-2 mx-2 inpFoc radius" style={{ height: "80%", marginBottom:"3%" }} type="text" placeholder="Enter Description ..." />
        </div>
            </div>
			
			
        </div>
        
        
            <br/>
            <br/>
        
<SnackBar />
<button onClick={() => mysubmit()} className="btn px-4 py-2 mx-3" style={{ backgroundColor: "#FB6A6A", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "8%", float: "right" }}>Cancel</button>
<button className="btn px-4 py-2 mx-1" style={{backgroundColor:"blue", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>View Runway</button>
</form>
        </div>
    )
}

export default Runway;
