import "./elogs.scss";
import React, { useEffect, useState } from 'react';
import Navbar from "../navbar/topnav";
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import RunwayChange from "../Runway/runwayChange";
import METCondition from "../conditions/metCondition";
import Lottie from 'react-lottie';
import { api, formlogApi } from "../api/api";
// import * as animationData from '../../assests/loading.json';



const Elog = () => {
const [modalOpen , setModalOpen] = useState(<METCondition />) 
    const [object, setObject] = useState();
    

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: require("../../assests/loading.json"),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }
  

    useEffect(() => {
      fetch(api + formlogApi)
      .then((response) => response.json())
          .then((responseJson) => {
            setObject(responseJson);              
             console.log(responseJson);              
          })
          .catch((error) => {
              console.error(error);
          });
  }, []);


    return (
        <div style={{fontFamily:"Poppins , sans-serif" }}>

   
<div class="table-container container-fluid"  style={{marginTop:"50px" }}>
  <h2 className="py-2 px-1 text-dark fs-1 mx-4 my-4 my_head" style={{ fontWeight: "bolder", fontFamily: "Poppins" }}>
                            <span className="px-1 py-1" style={{ borderRadius: "150px", marginRight: "30px" }}>
                                <BallotOutlinedIcon style={{ color: "#000", fontSize: "40px" }} />
                            </span>
  <a id="style-2" className="anchs">
                            E - LOG
                            </a>
                            </h2>

<table className="thisTable border border-dark table-hover">
    <thead className="text-center">
      <tr>
        <th  style={{width:"70px"}}>Subject</th>
        <th style={{width:"50px"}}>Initial</th>
        <th style={{width:"200px"}}>Description</th>
        <th style={{width:"100px"}}>Management Comments</th>
        <th style={{width:"100px"}}>ATC Comments</th>
        <th style={{width:"80px"}}>ROSI</th>
        <th style={{width:"60px"}}>FRN Status</th>
        <th style={{width:"80px"}}>UTC</th>
      </tr>
    </thead>

    <tbody  className="border border-dark" >
      {/* { (object) ? object.map((e,i)=>{ */}
        {/* return( */}
          {/* <tr data-bs-toggle="modal" data-bs-target="#staticBackdrop"  key={i} style={{cursor:"pointer"}} onClick={()=>console.log(e)} >
          <td   className={ e.status === "open" ? 'text-center unpaid' : 'paid text-center' }>{e.log_table}</td>
          <td   className={ e.status === "open" ? 'text-center unpaid' : 'paid text-center' }>{e.initial}</td>
          <td   className={ e.status === "open" ? 'text-center unpaid' : 'paid text-center' }>{e.log_type}</td>
          <td   className={ e.status === "open" ? 'text-center unpaid' : 'paid text-center' }>{e.origin}</td>
          <td   className={ e.status === "open" ? 'text-center unpaid' : 'paid text-center' }>{e.origin}</td>
          <td   className={ e.status === "open" ? 'text-center unpaid' : 'paid text-center' }>{e.ref_identifier}</td>
          <td  c className={ e.status === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ e.status === "open" ? 'status paide' : 'status status-unpaid' }>{e.status}</p>
          </td>
          <td  className={ e.status === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ e.status === "open" ? 'statusa paide' : 'statusa status-unpaid' } style={{fontSize:"12px" , borderRadius:"5px"}}>
              {e.created_at.slice(8, 10)  + "/" +  e.created_at.slice(5, 7) + "/" +  e.created_at.slice(0, 4) + " "}
               {e.created_at.slice(11, 19)}</p>
          </td>
        </tr> */}
       
       {/* )
      })
    : 
    */}
    {/* <Lottie options={defaultOptions} style={{marginLeft:"450%"}} height={150} width={150} /> */}








    <tr data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{cursor:"pointer"}} >
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Testing</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>OMA</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Initial</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Origin</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Origin</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>IMA</td>
          <td  c className={ "open" === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ "open" === "open" ? 'status paide' : 'status status-unpaid' }>Open</p>
          </td>
          <td  className={ "open" === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ "open" === "open" ? 'statusa paide' : 'statusa status-unpaid' } style={{fontSize:"12px" , borderRadius:"5px"}}>
              26/07/2022 10:07
              </p>
          </td>
        </tr>



        <tr data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{cursor:"pointer"}} >
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Testing</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>OMA</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Initial</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Origin</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Origin</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>IMA</td>
          <td  c className={ "open" === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ "open" === "open" ? 'status paide' : 'status status-unpaid' }>Open</p>
          </td>
          <td  className={ "open" === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ "open" === "open" ? 'statusa paide' : 'statusa status-unpaid' } style={{fontSize:"12px" , borderRadius:"5px"}}>
              26/07/2022 10:07
              </p>
          </td>
        </tr>
    <tr data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{cursor:"pointer"}} >
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Testing</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>OMA</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Initial</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Origin</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Origin</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>IMA</td>
          <td  c className={ "open" === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ "open" === "open" ? 'status paide' : 'status status-unpaid' }>Open</p>
          </td>
          <td  className={ "open" === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ "open" === "open" ? 'statusa paide' : 'statusa status-unpaid' } style={{fontSize:"12px" , borderRadius:"5px"}}>
              26/07/2022 10:07
              </p>
          </td>
        </tr>
    <tr data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{cursor:"pointer"}} >
          <td   className={ "Closed" === "open" ? 'text-center unpaid' : 'paid text-center' }>Testing</td>
          <td   className={ "Closed" === "open" ? 'text-center unpaid' : 'paid text-center' }>OMA</td>
          <td   className={ "Closed" === "open" ? 'text-center unpaid' : 'paid text-center' }>Initial</td>
          <td   className={ "Closed" === "open" ? 'text-center unpaid' : 'paid text-center' }>Origin</td>
          <td   className={ "Closed" === "open" ? 'text-center unpaid' : 'paid text-center' }>Origin</td>
          <td   className={ "Closed" === "open" ? 'text-center unpaid' : 'paid text-center' }>IMA</td>
          <td  c className={ "Closed" === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ "Closed" === "open" ? 'status paide' : 'status status-unpaid' }>Closed</p>
          </td>
          <td  className={ "Closed" === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ "Closed" === "open" ? 'statusa paide' : 'statusa status-unpaid' } style={{fontSize:"12px" , borderRadius:"5px"}}>
              26/07/2022 10:07
              </p>
          </td>
        </tr>
    <tr data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{cursor:"pointer"}} >
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Testing</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>OMA</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Initial</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Origin</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>Origin</td>
          <td   className={ "open" === "open" ? 'text-center unpaid' : 'paid text-center' }>IMA</td>
          <td  c className={ "open" === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ "open" === "open" ? 'status paide' : 'status status-unpaid' }>Open</p>
          </td>
          <td  className={ "open" === "open" ? 'text-center unpaid px-3' : 'paid text-center px-3' } style={{paddingTop:"10px"}}>
            <p className={ "open" === "open" ? 'statusa paide' : 'statusa status-unpaid' } style={{fontSize:"12px" , borderRadius:"5px"}}>
              26/07/2022 10:07
              </p>
          </td>
        </tr>












    {/* } */}
     
    </tbody>
  </table>


<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
    <div class="modal-content">
    <div class="modal-header">
        <div style={{marginLeft:"97%"}}>
        <button type="button" class="btn-Close  btn text-dark" data-bs-dismiss="modal" aria-label="Close">&#x2716;</button>
        </div>
      </div>
      <div class="modal-body">
        {modalOpen}
      </div>
      
    </div>
  </div>
</div>

     
   
</div>




        </div>
    )
}

export default Elog;
