import React, { useState } from 'react'
import $ from "jquery"
import SnackBar from "../components/LOGs/SnackBar"

const TableForm = (props) => {

	$(function () {
		$('.check-all').on('click', function () {
			$('.checkInp').prop('checked', true);
		});
		$('.uncheck-all').on('click', function () {
			$('.checkInp').prop('checked', false);
		});
	});

	return (
		<div>
			<center>
				<div style={{ width: "80%" }}>

					<div id="admin" >
						<br />
						<br />
						<h2 className="px-2 fw-bold">{props.name}</h2>
						<br />	
						<button style={{ color: "white" }} class="btn btn-success mx-2 check-all" >Check All</button>
						<button style={{ color: "white" }} class="btn btn-success mx-2 uncheck-all" >Uncheck All</button>
<br />
<br />
						<form>
							<input type="hidden" name="id" value="1" autocomplete="off" />
							<div class="table-scrollable my-3">
								<p> ( User can View LVO Checklist )
									( User can View Daily Checklist - only for ATCA(S) )</p>
								<table class="table table-bordered border-primary" style={{ border: "1px solid grey" }} id="tb">
									<thead>
										<tr>
											<th  className="fw-bold" style={{ fontSize: "14px" , width:"10px" }}>No #</th>
											<th className="fw-bold" style={{ fontSize: "14px" , width:"200px" }}>Name of Permisions</th>

											<th className="fw-bold text-center" style={{ fontSize: "14px" , width:"20px" }} >Actions</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td >1</td>
											<td style={{ fontSize: "14px" }}>Inspection Entry ( User can Log Inspection Entry in E-Log) </td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb1" />
												{/* {/* <label for="tb1"></label> */}
																					</td>

										</tr>
										<tr>
											<td>2</td>
											<td style={{ fontSize: "14px" }}>Unusual Entry ( User can add Unusual Entry in Elog)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb2" />
											 {/* <label for="tb2"></label> */}
												 
												</td>



										</tr>					 		<tr>
											<td>3</td>
											<td style={{ fontSize: "14px" }} >Staff Reason ( User can Log Staff Related Entries in E-Log)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb3" />
											 {/* <label for="tb3"></label> */}
												
												 </td>


										</tr>
										<tr>
											<td>4</td>
											<td style={{ fontSize: "14px" }}>Emergency Check List ( User can Log Incidents / Accident / Emergency Checklist in E-Log)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb4" />
												{/* <label for="tb4"></label> */}
												 </td>


										</tr>
										<tr>
											<td>5</td>
											<td style={{ fontSize: "14px" }}>General Entry Logs ( User can Log General Entry in E-Log)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb5" />
												{/* <label for="tb5"></label> */}
												 </td>


										</tr>
										<tr>
											<td>6</td>
											<td style={{ fontSize: "14px" }}>Fault Reporting ( User can Log Fault in E-Log)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb6" />
												{/* <label for="tb6"></label> */}
												 </td>


										</tr>
										<tr>
											<td>7</td>
											<td style={{ fontSize: "14px" }}>View Fault Reports ( User can View Fault Reports only in E-Log)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb7" />
												{/* <label for="tb7"></label> */}
												 </td>


										</tr>
										<tr>
											<td>8</td>
											<td style={{ fontSize: "14px" }}>Update Faults ( User can Add more Details ) </td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb8" />
												{/* <label for="tb8"></label> */}
												 </td>


										</tr>
										<tr>
											<td>9</td>
											<td style={{ fontSize: "14px" }}>Aircraft Diversion ( User can Log Aircraft Diversion in E-Log)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb9" />
												{/* <label for="tb9"></label> */}
												 </td>


										</tr>
										<tr>
											<td>10</td>
											<td style={{ fontSize: "14px" }}>Runway Change ( User can Log Runway Change Entry in E-Log)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb10" />
												{/* <label for="tb10"></label> */}
												 </td>


										</tr>
										<tr>
											<td>11</td>
											<td style={{ fontSize: "14px" }}>Domain Parameters ( User can add Domainn Parameters)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb11" />
												{/* <label for="tb11"></label> */}
												 </td>


										</tr>
										<tr>
											<td>12</td>
											<td style={{ fontSize: "14px" }}>Add Instructions ( User can Add Instructions )</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb12" />
												{/* <label for="tb12"></label> */}
												 </td>


										</tr>
										<tr>
											<td>13</td>
											<td style={{ fontSize: "14px" }}>View Instructions ( User can View Instructions )</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb13" />
												{/* <label for="tb13"></label> */}
												 </td>


										</tr>
										<tr>
											<td>14</td>
											<td style={{ fontSize: "14px" }}>Instructions Notifications ( User can View Expiry of Insturction)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb14" />
												{/* <label for="tb14"></label> */}
												 </td>


										</tr>
										<tr>
											<td>15</td>
											<td style={{ fontSize: "14px" }}>Remove Instructions ( User can Remove Insturction)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb15" />
												{/* <label for="tb15"></label> */}
												 </td>


										</tr>

										<tr>
											<td>16</td>
											<td style={{ fontSize: "14px" }}>Generate Reports ( User can Create Reports )</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb16" />
												{/* <label for="tb16"></label> */}
												 </td>


										</tr>

										<tr>
											<td>17</td>
											<td style={{ fontSize: "14px" }}>Access Management Field in log view ( User can View Activities Performed on AT e Log Application )</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb17" />
												{/* <label for="tb17"></label> */}
												 </td>


										</tr>
										<tr>
											<td>18</td>
											<td style={{ fontSize: "14px" }}>Access Actions/Further Information Field in log view</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb18" />
												{/* <label for="tb18"></label> */}
												 </td>


										</tr>

										<tr>
											<td>19</td>
											<td style={{ fontSize: "14px" }}>Get Runway Inspection Notifications ( User will get Notifications of Runway Inspection if less then 4 in a day ) </td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb19" />
												{/* <label for="tb19"></label> */}
												 </td>


										</tr>

										<tr>
											<td>20</td>

											<td style={{ fontSize: "14px" }}>Position Releif Checklist ( User can view Position Relief Chekclist)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb20" />
												{/* <label for="tb20"></label> */}
												 </td>


										</tr>
										<tr>
											<td>21</td>
											<td style={{ fontSize: "14px" }}>LVO Checklist</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb21" />

												{/* <label for="tb21"></label> */}
												 </td>


										</tr>
										<tr>
											<td>22</td>
											<td style={{ fontSize: "14px" }}>Daily Checklist</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb22" />
												{/* <label for="tb22"></label> */}
												 </td>


										</tr>
										<tr>
											<td>23</td>
											<td style={{ fontSize: "14px" }}>All Notifications ( User can view Notificaton of License Expiry of all Users)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb23" />
												{/* <label for="tb23"></label> */}
												 </td>


										</tr>

										<tr>
											<td>24</td>
											<td style={{ fontSize: "14px" }}>User Management ( User can add/update/disable  Users)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb24" />
												{/* <label for="tb24"></label> */}
												 </td>


										</tr>
										<tr>
											<td>25</td>
											<td style={{ fontSize: "14px" }}>Training Sessions ( User can Add Trainning Session)</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb25" />
												{/* <label for="tb25"></label> */}
												 </td>


										</tr>
										<tr>
											<td>26</td>
											<td style={{ fontSize: "14px" }}>OJTI Manager</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb26" />
												{/* <label for="tb26"></label> */}
												 </td>


										</tr>
										<tr>
											<td>27</td>
											<td style={{ fontSize: "14px" }}>OJTI</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb27" />

												{/* <label for="tb27"></label> */}
												 </td>


										</tr>
										<tr>
											<td>28</td>
											<td style={{ fontSize: "14px" }}>Trainee</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb28" />
												{/* <label for="tb28"></label> */}
												 </td>


										</tr>

										<tr>
											<td>29</td>
											<td style={{ fontSize: "14px" }}>Feedback</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb29" />
												{/* <label for="tb29"></label> */}
												 </td>


										</tr>
										<tr>
											<td>30</td>
											<td style={{ fontSize: "14px" }}>Feedback Notifications</td>


											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb30" />
												{/* <label for="tb30"></label> */}
												 </td>


										</tr>
										<tr>
											<td>31</td>
											<td style={{ fontSize: "14px" }}>Add Management Feedback </td>


											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb31" />
												{/* <label for="tb31"></label> */}
												 </td>


										</tr>
										<tr>
											<td>32</td>
											<td style={{ fontSize: "14px" }}>Feedback View All</td>

											<td className="text-center">  <input type="checkbox" className="checkInp" id="tb32" />
												{/* <label for="tb32"></label> */}
												 </td>


										</tr>
									</tbody>
								</table>
							</div>
							<div>
								<SnackBar />
							</div>


						</form>			</div>

				</div>
			</center>
		</div>
	)
}

export default TableForm;
