import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import InputsHi from "../LOGs/input";
import SnackBar from "../LOGs/SnackBar";

const OtherInspection = () => {
 
    const [timeStamp, setTimeStamp] = useState('');
    const [inspectionType, setInspectionType] = useState('');
    const [vehicle, setVehicle] = useState('');
    const [status, setStatus] = useState('');
    const [runway, setRunway] = useState('');
    const [description, setDescription] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });

        let inspectionTypeClean = DOMPurify.sanitize(inspectionType, { USE_PROFILES: { html: false } });
        let vehicleClean = DOMPurify.sanitize(vehicle, { USE_PROFILES: { html: false } });
        let statusClean = DOMPurify.sanitize(status, { USE_PROFILES: { html: false } });
        let runwayClean = DOMPurify.sanitize(runway, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(inspectionTypeClean);
        console.log(vehicleClean);
        console.log(statusClean);
        console.log(runwayClean);
        console.log(descriptionClean);
    }


return (
    <>
    <div className="font back2">
    {/*  */}
 
        <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Other Inspection</h1>
        </div>
<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Time Stamp</label> <br />
            <div className="App">
            <InputsHi type="datetime-local" onChange={(e)=>setTimeStamp(e.target.value)} id="inp2" />
            </div>
            </div>
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Inspection Type</label> <br />
            <div className="App">
            <InputsHi type="text" id="inp2" onChange={(e)=>setInspectionType(e.target.value)} placeholder="Enter Inspection Type" />
            </div>
            </div>
        </div>
        <div className="row">
        <div className="col-md-6">
            <label htmlFor="inp2" style={{marginLeft:"15%"}} className="fw-bold" >Vehicle</label> <br />
            <div className="App">
            <InputsHi type="text" id="inp2" onChange={(e)=>setVehicle(e.target.value)} placeholder="Enter Vehicle" />
            </div>
            </div>
       
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Status</label>
            <div className="App">
            <select id="inp3" onChange={(e)=>setStatus(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >-- Select --</option>
            <option value="NTR">NTR</option>
            <option value="Other">Other</option>
            </select>
            </div>
            </div>
             <br />
            </div>
            <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Runway</label>
            <div className="App">
            <select onChange={(e)=>setRunway(e.target.value)} id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected > -- Select Runway -- </option>
            <option value="Demo Runways">Demo Runways</option>
            <option value="Runway Demo">Runway Demo</option>
            <option value="Demo Runway">Demo Runway</option>
            <option value="Test Runway">Test Runway</option>
            </select>
            </div>
            </div>
            
      
            <div className="col-md-6">
                <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp4" >Description</label> <br />
                  <div className="App">
                    <textarea id="inp4" onChange={(e)=>setDescription(e.target.value)} placeholder="Enter Description..." className="px-2 mx-2 inpFoc radius" style={{ height: "80%", marginBottom:"3%" }} type="text" />

                </div>
                </div>
            
        </div>
       
        <div style={{paddingBottom:"1%", marginTop:"3 %"}}>
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
        </div>
</form>
    </div>
    </>
)
}
export default OtherInspection;