import React from 'react'
import InputsHi from '../../LOGs/input'
import SnackBarUpdate from '../../LOGs/update';

const TravelDocument = () => {
    return (
        <div className="font back7">
           
		 
        <br/>
        <br/>
        <br/>
              <div className="App">
                        <h1 style={{ color: "#505050" }} className="fw-bold "></h1>
                </div>
                
        <br />
        <form>
                    <div className="row">
                        <div className="col-md-6">
                        <label htmlFor="inp2" className="fw-bold"  style={{marginLeft:"15%"}} >Passport #</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp2" required="required"/>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <label htmlFor="inp3" className="fw-bold"  style={{marginLeft:"15%"}} > Passport Expiry Date: </label> <br />
                     <div className="App">
                     <InputsHi type="date" id="inp3" required="required"/>
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <label htmlFor="inp4" className="fw-bold"  style={{marginLeft:"15%"}}>Visa #</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp4" required="required"/>
                        </div>
                        </div>
        <div className="col-md-6">
            <label htmlFor="inp5" className="fw-bold"  style={{marginLeft:"15%"}}>Visa Expiry Date: </label> <br />
                <div className="App">

                        <InputsHi type="date" id="inp5" required="required"/>
                                  
                        </div>
                        </div>
        
                        
                    </div>
                   
                    <div style={{paddingBottom:"3%", marginTop:"5%"}}>
        
                        <SnackBarUpdate/>
                        {/* <button className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>Cancel</button> */}
                    </div>
        </form>
        
                </div>
                
    )
}

export default TravelDocument
