import React from 'react'
import "../../App.css"
import "../LOGs/profile.css";
import SnackBarUpdate from '../LOGs/update';
import Profile from '../LOGs/profile';

const EditUser = () => {
    return (


        <div className="back7" style={{fontFamily:"Poppins , sans-serif"}} >
        
<br/>
<br/>
            <div className="row App">
               <Profile />
            </div>

<center>
            <div class="row pform container" style={{paddingTop:"1%"}}>
              
            		    <input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6 contaier">
							<div class="form-group">
                       
								<span>First Name:</span> <b class="required">*</b>
								<input id="idPassw" class="input-block-level form-control" type="text" name="firstname" required="required" autocomplete="off"/>
							</div>
                            
						</div>
                        
						<div class="col-md-6">
							<div class="form-group">
								<span>Last Name</span> <b class="required">*</b>
								<input id="idPassw" class="input-block-level form-control" type="text" name="lastname" required="required" autocomplete="off"/>
								
							</div>
						</div>
					</div>

                    <div class="row pform container" style={{paddingTop:"1%"}}>
            		    <input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6 contaier">
							<div class="form-group">
								<span>Username:</span> <b class="required">*</b>
								<input id="idPassw" class="input-block-level form-control" type="text" name="firstname" required="required" autocomplete="off"/>
							</div>
                            
						</div>
                        
						<div class="col-md-6">
							<div class="form-group">
								<span>Phone1</span> <b class="required">*</b>
								<input id="phone" class="input-block-level form-control" type="phone" name="phone" required="required" autocomplete="off"/>
								
							</div>
						</div>
					</div>

                    <div class="row pform container" style={{paddingTop:"1%"}}>
            		    <input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6 contaier">
							<div class="form-group">
								<span>Mobile</span> <b class="required">*</b>
								<input id="idPassw" class="input-block-level form-control" type="text" name="firstname" required="required" autocomplete="off"/>
							</div>
                            
						</div>
                        
						<div class="col-md-6">
							<div class="form-group">
								<span>Email</span> <b class="required">*</b>
								<input id="idPassw" class="input-block-level form-control" type="email" name="email" required="required" autocomplete="off"/>
								
							</div>
						</div>
					</div>

                    <div class="col-md-4">
                       
						</div>
                        <div class="row pform container" style={{paddingTop:"1%"}}>
						<div class="col-md-6">
							<div class="form-group">
								<span>Position</span> <b class="required"></b>
								<input id="idPassw" class="input-block-level form-control" type="text" name="position" required="required" autocomplete="off"/>
								
							</div>
						</div>
					</div>

                    <div class="row pform container" style={{paddingTop:"1%"}}>
            		    <input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6 contaier">
							<div class="form-group">
								<span>Phone2</span> <b class="required">*</b>
								<input id="idPassw" class="input-block-level form-control" type="phone" name="phone" required="required" autocomplete="off"/>
							</div>
                            
						</div>
                        
						<div class="col-md-6">
							<div class="form-group">
								<span>Date of Birth</span> <b class="required">*</b>
								<input id="idPassw" class="input-block-level form-control" type="date" name="DOB" required="required" autocomplete="off"/>
								
							</div>
						</div>
					</div>

                    <div class="row pform container" style={{paddingTop:"1%"}}>
            		    <input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6 contaier">
							<div class="form-group">
								<span>Nationaly</span> <b class="required">*</b>
								<input id="idPassw" class="input-block-level form-control" type="country" name="country" required="required" autocomplete="off"/>
							</div>
                            
						</div>
                        
						<div class="col-md-6">
							<div class="form-group">
								<span>Address 1</span> <b class="required">*</b>
								<input id="idPassw" class="input-block-level form-control" type="text" name="address" required="required" autocomplete="off"/>
								
							</div>
						</div>
					

                    <div class="row pform container" style={{paddingTop:"1%"}}>
            		    <input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6 contaier">
							<div class="form-group">
								<span>Address 2</span> <b class="required">*</b>
								<input id="idPassw" class="input-block-level form-control" type="text" name="address" required="required" autocomplete="off"/>
							</div>
                                  
					</div>
						</div>
                  
					</div>
</center>
                 
                  
	 <SnackBarUpdate />
					</div>    
                   
    )
}

export default EditUser;
