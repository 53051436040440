import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';

const CreateFeedback = () => {

    const [timeStamp, setTimeStamp] = useState('');
    const [referenceNumber, setReferenceNumber] = useState('ATC_W_619ddb5c2d963_24-11-2021');
    const [purpose, setPurpose] = useState('');
    const [subject, setSubject] = useState('');
    const [details, setDetails] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });
        let referenceNumberClean = DOMPurify.sanitize(referenceNumber, { USE_PROFILES: { html: false } });
        let purposeClean = DOMPurify.sanitize(purpose, { USE_PROFILES: { html: false } });
        let subjectClean = DOMPurify.sanitize(subject, { USE_PROFILES: { html: false } });
        let detailsClean = DOMPurify.sanitize(details, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(referenceNumberClean);
        console.log(purposeClean);
        console.log(subjectClean);
        console.log(detailsClean);
    }


    return (
        <div className="font back7">
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Create Feedback</h1>
            </div>

<form onSubmit={(e)=>e.preventDefault()}>
            <div className="row">
         
                <div className="col-md-6">
                <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}  >Date & Time</label> <br />
                <div className="App">
                <InputsHi type="dateTime-local" onChange={(e)=>setTimeStamp(e.target.value)} id="inp3" />
                </div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp4" className="fw-bold"  style={{marginLeft:"15%"}}   >Reference Number</label> <br />
                <div className="App">
                <input className="px-3 py-2 mx-2 inpFoc radius" onChange={(e)=>setReferenceNumber(e.target.value)}  id="inp7" value={referenceNumber} style={{ backgroundColor: "#e9edc9",marginBottom:"4%" }} disabled id="outlined-disabled" type="text" />
                </div>
                </div>
            </div>
            <div className="row">
               
               
                <div className="col-md-6">
                <label htmlFor="inp5" className="fw-bold"  style={{marginLeft:"15%"}} >Purpose</label> <br />
                <div className="App">
                <InputsHi type="text" id="inp5" onChange={(e)=>setPurpose(e.target.value)}  placeholder="Enter Purpose..." />
</div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp6" className="fw-bold" style={{marginLeft:"15%"}}>Subject</label> <br />
                <div className="App">
                <InputsHi type="text" onChange={(e)=>setSubject(e.target.value)}  placeholder="Enter Subject" id="inp6" />
                </div>       
                </div>       
            </div>
            <div className="row">
                
               
                <div className="col-md-6">
               
                <label htmlFor="inp7" className="fw-bold" style={{marginLeft:"15%"}}>Any Other Details</label> <br />
                <div className="App">
                <textarea id="inp7" onChange={(e)=>setDetails(e.target.value)}  className="px-2 mx-2 inpFoc radius"style={{ height: "80%", marginBottom:"3%" }} type="text" placeholder="Any Other Details..."/>

                </div>
                </div>
                            
            </div>
           

            <div style={{paddingBottom:"3%", marginTop:"5%"}}>
<SnackBar  />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
            </div>
</form>

        </div>
    )
}

export default CreateFeedback;
