import "./boxes.css"
import $ from "jquery"
import { useState } from "react";

const Boxes = () => {
    var content = document.getElementById("content");
    var button = document.getElementById("show-more");
    const [values , setValues] = useState(false)
// const ShowMre = ()=> {
    
    //     if(content.className == "open"){
    //         content.className = "";
    //         button.innerHTML = "&#8681;";
    //     } else {
    //         content.className = "open";
    //         button.innerHTML = "&#8679;";
    //     }
    
    // };

    return (
        <div>
            {/* <div className="container" style={{marginTop:"50px"}}>
            <div className="row">
                <div className="col-md-2 " >
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                        <div>
                        <span className="fw-bold px-3">SHJ</span>
                        </div>
                        <div className="py-2"  style={{ borderTop: "1px solid gray" , backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>MET Condition</h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Visibility:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>Open</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>MTR:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>Open</span>
                       
                        </div>

                    </div>
                </div>
                <div className="col-md-2">
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                    
                    <div>
                        <span className="fw-bold px-3">OMSJ</span>
                        </div>
                        <div className="py-2"  style={{ borderTop: "1px solid black", backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>RWY OPS</h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>OPS: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>30</span>
                        <br />
                        <span className="text-dark fw-bold px-3" style={{ fontSize: "14px" }}>NON OPS:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>13</span>
                       
                     
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                <div style={{border:"1px solid gray",marginTop:"10px"}}>
                 
                    <div>
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-2"  style={{ borderTop: "1px solid darkcyan" , backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark fw-bold text-center mx-2" style={{ marginBottom: "15px" }}>TAXIWAY</h6>
                        <span className="text-dark fw-bold px-3" style={{ fontSize: "14px" }}>Non OPS:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>11</span>
                        <br/>
                        <span className="text-dark px-3" style={{ fontSize: "14px" }}></span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       
                  
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                <div style={{border:"1px solid gray",marginTop:"10px"}}>
                  
                    <div>
                        <span className="fw-bold px-2">Unit Name:</span>
                        </div>
                        <div className="py-2"  style={{ borderTop: "1px solid darkcyan" , backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-white fw-bold text-center mx-2" style={{ marginBottom: "15px" }}>LVO</h6>
                        <span className="text-white fw-bold  px-3" style={{ fontSize: "14px" }}>Visibility:</span>
                        <span className="text-white" style={{ fontSize: "15px" }}>Closed</span>
                        <br />
                        <span className="text-white px-3" style={{ fontSize: "14px" }}></span>
                        <span className="text-white" style={{ fontSize: "15px" }}></span>
                       </div>

                    </div>
                </div>
                <div className="col-md-2" >
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                        <div>
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="grad1 py-2"  style={{ borderTop: "1px solid darkcyan"}}>

                        <h6 className="text-white fw-bold text-center mx-2" style={{ marginBottom: "15px" }}>Emergency</h6>
                        <span className="text-white fw-bold px-3" style={{ fontSize: "14px" }}>Visibility:</span>
                        <span className="text-white" style={{ fontSize: "15px" }}>Open</span>
                        <br />
                        <span className="text-white fw-bold px-3" style={{ fontSize: "14px" }}></span>
                        <span className="text-white" style={{ fontSize: "15px" }}></span>
                       
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                    
                    <div>
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-2"  style={{ borderTop: "1px solid black", backgroundColor:"rgb(214,214,214)"  }}>

                        <h6 className="text-dark fw-bold text-center mx-2" style={{ marginBottom: "15px" }}>Diversions</h6>
                        <span className="text-dark fw-bold px-3" style={{ fontSize: "14px" }}>OPS:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>12</span>
                        <br />
                        <span className="text-dark fw-bold px-3" style={{ fontSize: "14px" }}></span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       
                     
                        </div>
                    </div>
                </div>
            </div>
           
        


            <div className="row">
               

                
               
                <div className="col-md-2">
                <div style={{border:"1px solid gray",marginTop:"10px"}}>
                 
                    <div className="py-1">
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)"  }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Open Faults </h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>OPS:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>00</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}></span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       
                  
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                <div style={{border:"1px solid gray",marginTop:"10px"}}>
                  
                    <div className="py-1">
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Movement</h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>OPS:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>01</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>VFR:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       </div>
                        
                    </div>
                </div>
   

    
                <div className="col-md-2" >
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                        <div className="py-1">
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Sick</h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Visibility:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>Open</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}></span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       
                        </div>
                    </div>
                </div>

                
                <div className="col-md-2">
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                    
                    <div className="py-1">
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-3"  style={{ borderTop: "1px solid black", backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Unread</h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>SI:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>12</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Memo</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>08</span>
                       
                     
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                <div style={{border:"1px solid gray",marginTop:"10px"}}>
                 
                    <div className="py-1">
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Open Feedback </h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Count:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>10</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}></span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       
                  
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                <div style={{border:"1px solid gray",marginTop:"10px"}}>
                  
                    <div className="py-1">
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Open Entries</h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>OPS:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>01</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Count:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>10</span>
                       </div>
                        
                    </div>
                </div>
          </div>
            <div className="row">
                <div className="col-md-2" >
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                        <div className="py-1">
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Flight Plan</h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Visibility:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>12 hours</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}></span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       
                        </div>
                    </div>
                </div>

                
                <div className="col-md-2">
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                    
                    <div className="py-1">
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-3"  style={{ borderTop: "1px solid black", backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>ATCO</h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>On Break:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>12</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Online</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>20</span>
                       
                     
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                <div style={{border:"1px solid gray",marginTop:"10px"}}>
                 
                    <div className="py-1">
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}> ATCA </h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Self-briefed:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>10</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}></span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       
                  
                        </div>
                    </div>
                </div>
                
            </div>
            </div> */}
        
        <div id="content" style={{marginTop:"50px"}}>
{/* <div> */}
  <input type="checkbox" class="read-more-state" onChange={(e)=>setValues(e.target.checked)} id="post-2" />

  {/* <ul class="read-more-wrap">
    <li>lorem</li>
    <li>lorem 2</li>
    <li class="read-more-target">lorem 3</li>
    <li class="read-more-target">lorem 4</li>
  </ul> */}
  
  {/* <label for="post-2" class="read-more-trigger"></label> */}
{/* </div> */}

<div class="read-more-wrap container-fluid">
        <div className="row">
                <div className="col-md-2 " >
                    <div style={{border:"1px solid gray",marginTop:"10px" , width:"99%"}}>
                        <div>
                        <span className="fw-bold px-3">SHJ</span>
                        </div>
                        <div className="py-2"  style={{ borderTop: "1px solid gray" , backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>MET Condition</h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Visibility:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>Open</span>
                        <br />
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>MTR:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>Open</span>
                       
                        </div>

                    </div>
                </div>
                <div className="col-md-2">
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                    
                    <div>
                        <span className="fw-bold px-3">OMSJ</span>
                        </div>
                        <div className="py-2"  style={{ borderTop: "1px solid black", backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>RWY OPS</h6>
                        <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>OPS: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>30</span>
                        <br />
                        <span className="text-dark fw-bold px-3" style={{ fontSize: "14px" }}>NON OPS:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>13</span>
                       
                     
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                <div style={{border:"1px solid gray",marginTop:"10px"}}>
                 
                    <div>
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-2"  style={{ borderTop: "1px solid darkcyan" , backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark fw-bold text-center mx-2" style={{ marginBottom: "15px" }}>TAXIWAY</h6>
                        <span className="text-dark fw-bold px-3" style={{ fontSize: "14px" }}>Non OPS:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>11</span>
                        <br/>
                        <span className="text-dark px-3" style={{ fontSize: "14px" }}></span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       
                  
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                <div style={{border:"1px solid gray",marginTop:"10px"}}>
                  
                    <div>
                        <span className="fw-bold px-2">Unit Name:</span>
                        </div>
                        <div className="py-2"  style={{ borderTop: "1px solid darkcyan" , backgroundColor:"rgb(214,214,214)" }}>

                        <h6 className="text-dark fw-bold text-center mx-2" style={{ marginBottom: "15px" }}>LVO</h6>
                        <span className="text-dark fw-bold  px-3" style={{ fontSize: "14px" }}>Visibility:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>Closed</span>
                        <br />
                        <span className="text-dark px-3" style={{ fontSize: "14px" }}></span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       </div>

                    </div>
                </div>
                <div className="col-md-2" >
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                        <div>
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="grad1 py-2"  style={{ borderTop: "1px solid darkcyan"}}>

                        <h6 className="text-white fw-bold text-center mx-2" style={{ marginBottom: "15px" }}>Emergency</h6>
                        <span className="text-white fw-bold px-3" style={{ fontSize: "14px" }}>Visibility:</span>
                        <span className="text-white" style={{ fontSize: "15px" }}>Open</span>
                        <br />
                        <span className="text-white fw-bold px-3" style={{ fontSize: "14px" }}></span>
                        <span className="text-white" style={{ fontSize: "15px" }}></span>
                       
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <div style={{border:"1px solid gray",marginTop:"10px"}}>
                    
                    <div>
                        <span className="fw-bold px-3">Unit Name:</span>
                        </div>
                        <div className="py-2"  style={{ borderTop: "1px solid black", backgroundColor:"rgb(214,214,214)"  }}>

                        <h6 className="text-dark fw-bold text-center mx-2" style={{ marginBottom: "15px" }}>Diversions</h6>
                        <span className="text-dark fw-bold px-3" style={{ fontSize: "14px" }}>OPS:</span>
                        <span className="text-dark" style={{ fontSize: "15px" }}>12</span>
                        <br />
                        <span className="text-dark fw-bold px-3" style={{ fontSize: "14px" }}></span>
                        <span className="text-dark" style={{ fontSize: "15px" }}></span>
                       
                     
                        </div>
                    </div>
                </div>
            </div>
            <label for="post-2" class="read-more-trigger" style={{display:"flex" , justifyContent:"right"}}>{values ? "Show Less" : "Show More"}</label>


<div className="row read-more-target">
               

                
               
               <div className="col-md-2">
               <div style={{border:"1px solid gray",marginTop:"10px"}}>
                
                   <div className="py-1">
                       <span className="fw-bold px-3">Unit Name:</span>
                       </div>
                       <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)"  }}>

                       <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Open Faults </h6>
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>OPS:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>00</span>
                       <br />
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}></span>
                       <span className="text-dark" style={{ fontSize: "15px" }}></span>
                      
                 
                       </div>
                   </div>
               </div>
               <div className="col-md-2">
               <div style={{border:"1px solid gray",marginTop:"10px"}}>
                 
                   <div className="py-1">
                       <span className="fw-bold px-3">Unit Name:</span>
                       </div>
                       <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                       <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Movement</h6>
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>OPS:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>01</span>
                       <br />
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>VFR:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}></span>
                      </div>
                       
                   </div>
               </div>
  

   
               <div className="col-md-2" >
                   <div style={{border:"1px solid gray",marginTop:"10px"}}>
                       <div className="py-1">
                       <span className="fw-bold px-3">Unit Name:</span>
                       </div>
                       <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                       <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Sick</h6>
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Visibility:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>Open</span>
                       <br />
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}></span>
                       <span className="text-dark" style={{ fontSize: "15px" }}></span>
                      
                       </div>
                   </div>
               </div>

               
               <div className="col-md-2">
                   <div style={{border:"1px solid gray",marginTop:"10px"}}>
                   
                   <div className="py-1">
                       <span className="fw-bold px-3">Unit Name:</span>
                       </div>
                       <div className="py-3"  style={{ borderTop: "1px solid black", backgroundColor:"rgb(214,214,214)" }}>

                       <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Unread</h6>
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>SI:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>12</span>
                       <br />
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Memo</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>08</span>
                      
                    
                       </div>
                   </div>
               </div>
               <div className="col-md-2">
               <div style={{border:"1px solid gray",marginTop:"10px"}}>
                
                   <div className="py-1">
                       <span className="fw-bold px-3">Unit Name:</span>
                       </div>
                       <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                       <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Open Feedback </h6>
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Count:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>10</span>
                       <br />
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}></span>
                       <span className="text-dark" style={{ fontSize: "15px" }}></span>
                      
                 
                       </div>
                   </div>
               </div>
               <div className="col-md-2">
               <div style={{border:"1px solid gray",marginTop:"10px"}}>
                 
                   <div className="py-1">
                       <span className="fw-bold px-3">Unit Name:</span>
                       </div>
                       <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                       <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Open Entries</h6>
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>OPS:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>01</span>
                       <br />
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Count:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>10</span>
                      </div>
                       
                   </div>
               </div>
         </div>
           <div className="row read-more-target">
               <div className="col-md-2" >
                   <div style={{border:"1px solid gray",marginTop:"10px"}}>
                       <div className="py-1">
                       <span className="fw-bold px-3">Unit Name:</span>
                       </div>
                       <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                       <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>Flight Plan</h6>
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Visibility:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>12 hours</span>
                       <br />
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}></span>
                       <span className="text-dark" style={{ fontSize: "15px" }}></span>
                      
                       </div>
                   </div>
               </div>

               
               <div className="col-md-2">
                   <div style={{border:"1px solid gray",marginTop:"10px"}}>
                   
                   <div className="py-1">
                       <span className="fw-bold px-3">Unit Name:</span>
                       </div>
                       <div className="py-3"  style={{ borderTop: "1px solid black", backgroundColor:"rgb(214,214,214)" }}>

                       <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}>ATCO</h6>
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>On Break:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>12</span>
                       <br />
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Online</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>20</span>
                      
                    
                       </div>
                   </div>
               </div>
               <div className="col-md-2">
               <div style={{border:"1px solid gray",marginTop:"10px"}}>
                
                   <div className="py-1">
                       <span className="fw-bold px-3">Unit Name:</span>
                       </div>
                       <div className="py-3"  style={{ borderTop: "1px solid darkcyan", backgroundColor:"rgb(214,214,214)" }}>

                       <h6 className="text-dark text-center mx-2 fw-bold" style={{ marginBottom: "15px" }}> ATCA </h6>
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}>Self-briefed:</span>
                       <span className="text-dark" style={{ fontSize: "15px" }}>10</span>
                       <br />
                       <span className="text-dark px-3 fw-bold" style={{ fontSize: "14px" }}></span>
                       <span className="text-dark" style={{ fontSize: "15px" }}></span>
                      
                 
                       </div>
                   </div>
               </div>
               
           </div>
           </div>


</div>


{/* <a id="show-more" onClick={()=>ShowMre()}>&#8681;</a> */}
		

        </div>
        
    )
}

export default Boxes;