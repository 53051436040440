import React , {useEffect} from 'react'
import "./chat.css"
import $ from 'jquery'; 


const Chat = () => {

    
       const hide=()=>{

            $('.chat_body').slideToggle("slow");  
          
            
        }

       
  useEffect(()=>{



      $('.chat_body').slideUp(0);

 



  },[])

        


          
    return (


   
           
<div style={{fontFamily:"Poppins , sans-serif"}}>



 
           
<div class="chat_box" >
<div class="chat_head usera" 
onClick={()=>hide()}
>
  &nbsp; (5)&nbsp; Online Users&nbsp; 
  {/* <ArrowDropDownIcon /> */}
</div>
  
<div class="chat_body">
<div class="user" style={{borderRadius:"0px"}}>Omair Ali
<br />
  <span className="spnas"> --ADMIN--</span>
 
  </div>
  
  <div class="user" style={{borderRadius:"0px"}}>John Doe
<br />
  <span className="spnas"> --OMSJ--</span>
 
  </div>
  <div class="user" style={{borderRadius:"0px"}}>Muzammil Khan
<br />
  <span className="spnas" > --TRAINEE--</span>
 
  </div>
  <div class="user" style={{borderRadius:"0px"}}>Abdullah Hussain
<br />
  <span className="spnas" > --TRAINEE--</span>
 
  </div>
  <div class="user" style={{borderRadius:"0px"}}>Ibad Ali
<br />
  <span className="spnas" > --TRAINEE--</span>
 
  </div>
</div>
</div>

</div>







        
    )
}

export default Chat
