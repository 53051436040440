import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const RunwayChange = () => {

    const [timeStamp, setTimeStamp] = useState('');
    const [runway, setRunway] = useState('');
    const [description, setDescription] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });
        let runwayClean = DOMPurify.sanitize(runway, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(runwayClean);
        console.log(descriptionClean);
    }


    return (
        <div style={{fontFamily:"Poppins, sans-serif"}}>

<div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-4">Runway Change</h1>
            </div>

            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}} htmlFor="inp2" >Time Stamp</label> <br />
                       <div className="App">
                    <InputsHi type="datetime-local" onChange={(e)=>setTimeStamp(e.target.value)} id="inp2" />
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp3" >Runway</label> <br />
<div className="App">
                    <InputsHi type="text" onChange={(e)=>setRunway(e.target.value)} id="inp3" placeholder="Enter Runway.." />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp4" >Description</label> <br />
                    <div className="App">
                    <textarea id="inp4" onChange={(e)=>setDescription(e.target.value)} className="px-2 mx-2 inpFoc radius" style={{ height:"80%", marginBottom:"3%" }} type="text" placeholder="Enter Description.." />

                </div>
                </div>
            
                </div>
               

{/* Check Boxxes */}

      <div style={{marginLeft:"40px",marginTop:"80px", fontFamily:'Poppins',fontWeight:'400'}}>

        <h5 style={{fontFamily:'Poppins',fontWeight:'600'}}>Co-ordinate</h5>

        <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                        <input type="checkbox" id="tb1" name="tb1" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb1"> Last Arrival</label><br /></li>
                    <li>
                        <input type="checkbox" id="tb2" name="tb2" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb2">Last Departure</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb3" name="tb3" />
                        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb3">First operation on the new runway</label><br />
                    </li>
            
                </ul>






        <h5 style={{fontFamily:'Poppins',fontWeight:'600'}}>Change the following Settings:</h5>

        <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                        <input type="checkbox" id="tb4" name="tb4" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb4"> ILS</label><br /></li>
                    <li>
                        <input type="checkbox" id="tb5" name="tb5" />
                        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb5">Airfield lightning runway direction</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb6" name="tb6" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb6">BA/BG Stop bar</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb7" name="tb7" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb7">ATIS Update</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb8" name="tb8" />
                        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb8">Rotate SID Display</label><br />
                    </li>
            
                </ul>





        <h5 style={{fontFamily:'Poppins',fontWeight:'600'}}>Confirm All departures have the corrects SIDs</h5>
        <h5 style={{fontFamily:'Poppins',fontWeight:'500'}}>Notify the following of the runway in use:</h5>



        <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                        <input type="checkbox" id="tb9" name="tb9" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb9">DPN</label><br /></li>
                    <li>
                        <input type="checkbox" id="tb10" name="tb10" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb10">DXB Tower</label><br />
                    </li>
                             
                </ul>

 </div>
 {/* Check Boxxes End */}
 <div style={{marginBottom:"60px"}}>
 <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>

 </div>
 </div>


    )
}

export default RunwayChange
