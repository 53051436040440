import React from 'react'
import SnackBarUpdate from '../../LOGs/update';

const Training = () => {
    return (
        <div className="back7" style={{fontFamily:"Poppins , sans-serif"}} >
        

            <div className="row App">
                {/* <h1 style={{ color: "#505050" }} className="fw-bold my-5">Edit User</h1> */}
            </div><br/>

	    <div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>OJTI Expiry Date </span>
								<input id="ojti_exp" class="input-block-level form-control date-picker" type="datetime-local" name="OJTI" required="required" autocomplete="off"/>
							</div>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>Investigator Expiry Date</span>
							<input id="investigator" class="input-block-level form-control date-picker" type="datetime-local" name="Investigator" required="required" autocomplete="off"/>
							</div><br/>
						</div>
						
	</div>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>Examiner expiry date</span>
								<input id="ojti_exp" class="input-block-level form-control date-picker" type="datetime-local" name="Examiner" required="required" autocomplete="off"/>
							</div><br/>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>LCE expiry date</span>
							<input id="investigator" class="input-block-level form-control date-picker" type="datetime-local" name="LCE" required="required" autocomplete="off"/>
							</div>
						</div>
	</div>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>ELP Exipry Date</span>
								<input id="ojti_exp" class="input-block-level form-control date-picker" type="datetime-local" name="ELP" required="required" autocomplete="off"/>
							</div>
						</div>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
							<span>COC expiry date</span>
							<input id="investigator" class="input-block-level form-control date-picker" type="datetime-local" name="COC" required="required" autocomplete="off"/>
							</div>
						</div>
	</div><br/>

	<div class="row pform">
       
					<input type="hidden" name="agentcode" value="146" class="ui-autocomplete-input" autocomplete="off"/>
						<div class="col-md-6" style={{width:"40%"}}>
							<div class="form-group">
								<span>ATCL expiry</span>
								<input id="ojti_exp" class="input-block-level form-control date-picker" type="datetime-local" name="ATCL expiry" required="required"  autocomplete="off"/>
					</div>
                    <br/>
				</div>
						
	</div>

	

	<br/>

	<SnackBarUpdate />
	</div>
    )
}

export default Training;
