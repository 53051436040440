import React, { useState } from 'react';
import SnackBar from "../LOGs/SnackBar";
import InputsHi from '../LOGs/input';
import DOMPurify from 'dompurify';

const RWYInspection = () => {
 
    const [inspectionCompleted, setInspectionCompleted] = useState('');
    const [vehicle, setVehicle] = useState('');
    const [runwayAcceptable, setRunwayAcceptable] = useState('');
    const [status, setStatus] = useState('');
    const [inspectedRunway, setInspectedRunway] = useState('');
    const [remarks, setRemarks] = useState('');

    var timeStampFilter = inspectionCompleted.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });

        let vehicleClean = DOMPurify.sanitize(vehicle, { USE_PROFILES: { html: false } });
        let runwayAcceptableClean = DOMPurify.sanitize(runwayAcceptable, { USE_PROFILES: { html: false } });
        let statusClean = DOMPurify.sanitize(status, { USE_PROFILES: { html: false } });
        let inspectedRunwayClean = DOMPurify.sanitize(inspectedRunway, { USE_PROFILES: { html: false } });
        let remarksClean = DOMPurify.sanitize(remarks, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(vehicleClean);
        console.log(runwayAcceptableClean);
        console.log(statusClean);
        console.log(inspectedRunwayClean);
        console.log(remarksClean);
    }

return (
    <>
    <div className="font back2">
    
 
        <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">RWY Inspection</h1>
        </div>
<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >RWY Inspection completed at</label> <br />
          <div className="App">
            <InputsHi onChange={(e)=>setInspectionCompleted(e.target.value)} type="datetime-local" id="inp2" />
            </div>
            </div>
            <div className="col-md-6">
            <label htmlFor="inp2" style={{marginLeft:"15%"}} className="fw-bold" >Vehicle</label> <br />
            <div className="App">
            <InputsHi type="text" onChange={(e)=>setVehicle(e.target.value)} id="inp2" placeholder="Enter Vehicle" />
            </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Runway Acceptable for Use?</label>
            <div className="App">
            <select onChange={(e)=>setRunwayAcceptable(e.target.value)} id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >-- Select --</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            </select>
             <br />
       
            </div>
            </div>
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Status</label>
            <div className="App">
            <select onChange={(e)=>setStatus(e.target.value)} id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >-- Select --</option>
            <option value="NTR">NTR</option>
            <option value="Other">Other</option>
            </select>
            </div>
            </div>
             <br />
            </div>
            <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Inspected Runway/Manoeuvring Area</label>

<div className="App">
            <select id="inp3" onChange={(e)=>setInspectedRunway(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected > -- Select Runway -- </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            </select>
            </div>
            </div>
            
      
        
       
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Remarks <br/> </label> <br />
          <div className="App">
            <InputsHi type="text" onChange={(e)=>setRemarks(e.target.value)} id="inp2" placeholder="Enter Remarks" />
            </div>
            </div>
            
        </div>
       
        <div style={{paddingBottom:"1%", marginTop:"1%"}}>
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
        </div>
</form>
    </div>
    </>
)
}
export default RWYInspection;