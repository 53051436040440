import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';

const AircraftReport = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const mysubmit = () => {
        let fromDateClean = DOMPurify.sanitize(fromDate, { USE_PROFILES: { html: false } });
        let toDateClean = DOMPurify.sanitize(toDate, { USE_PROFILES: { html: false } });

        console.log(fromDateClean);
        console.log(toDateClean);
    }



    return (
        <div style={{fontFamily:"Poppins , sans-serif"}}>
            {/*  */}
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Aircraft Diversion Report</h1>
            </div>
<form onSubmit={(e)=>e.preventDefault()}>
            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >From Date</label> <br />
                    <div className="App">
                    <InputsHi type="date" onChange={(e)=>setFromDate(e.target.value)} id="inp2" />
                </div>
                </div>
               
                <div className="col-md-6">
               
                <label className="fw-bold" style={{marginLeft:"15%"}} >To Date</label> <br />
                <div className="App">
                <InputsHi type="date" onChange={(e)=>setToDate(e.target.value)} id="inp2" />
                </div>
                </div>
            </div>
    <div className="py-4">

            
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
</div>
</form>

        </div>
    )
}

export default AircraftReport;
