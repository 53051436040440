import React, { useState } from 'react'
import SnackBar from "../LOGs/SnackBar";
import InputsHi from "../LOGs/input";
import DOMPurify from 'dompurify';




const SubjectForm = () => {
    const [subject, setSubject] = useState('');
    const [reports, setReports] = useState([]);
    const [typeA, setTypeA] = useState('');
    const [piority, setPiority] = useState('');
    const [typeB, setTypeB] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState('');


  function handleChange(e) {
    let isChecked = e.target.value;
    reports.push(isChecked)
  }

    const mysubmit = () => {
        let subjectClean = DOMPurify.sanitize(subject, { USE_PROFILES: { html: false } });
        let reportsClean = DOMPurify.sanitize(reports, { USE_PROFILES: { html: false } });
        
        let typeAClean = DOMPurify.sanitize(typeA, { USE_PROFILES: { html: false } });
        let piorityClean = DOMPurify.sanitize(piority, { USE_PROFILES: { html: false } });
        
        let typeBClean = DOMPurify.sanitize(typeB, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });
        let activeClean = DOMPurify.sanitize(active, { USE_PROFILES: { html: false } });

        console.log(subjectClean);
        console.log(reportsClean);
        console.log(typeAClean);
        console.log(piorityClean);
        console.log(typeBClean);
        console.log(descriptionClean);
        console.log(activeClean);
    }


    return (
<div className="back7" style={{fontFamily:"Poppins , sans-serif"}}>
                        <div className="App">
                <h1 style={{ color: "#505050", fontFamily:"Poppins",fontSize:"45px" }} className="fw-bold my-5">Subject Form</h1>
            </div><br/><br/>

	       			
<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Subject</label> <br />
                   <div className="App">
                <InputsHi type="text" id="inp2" onChange={(e)=>setSubject(e.target.value)} placeholder="Enter Subject..." />
            </div>
            </div>
            <div className="col-md-6">
            <label className="fw-bold"  htmlFor="inp3" style={{marginLeft:"15%"}} >For Reports</label> <br />
<div className="row">
	<div className="col-md-1"></div>
	<div className="col-md-5">
<ul style={{
                    listStyleType: "none", width: "350px",
                    display: "inline-block",
					paddingLeft:"100px"
                }}>
                    <li className="my-1">
                    <input type="checkbox" onChange={(e)=>handleChange(e)} id="tb1"name="tb1" />
        <label style={{ width: "220px", display: "inline-block" }} className="text-dark mx-2" for="tb1">Supervisor Report </label><br/></li>
                    <li className="my-1">
                    <input type="checkbox"  onChange={(e)=>handleChange(e)}  id="tb2"name="tb2"/>
        <label style={{ width: "220px", display: "inline-block" }} className="text-dark mx-2" for="tb2">Management Report</label><br/>
                    </li>
                   
                   
                   
                   
                </ul>
            </div>

			<div className="col-md-6">
			<ul style={{
                    listStyleType: "none", width: "350px",
                    display: "inline-block"
                }}>
                   
                   
                    <li className="my-1">
                    <input type="checkbox"  onChange={(e)=>handleChange(e)}  id="tb3"name="tb3" />
        <label style={{ width: "250px", display: "inline-block" }} className="text-dark mx-2" for="tb3">General Report</label><br/></li>
                    <li className="my-1">
                    <input type="checkbox"  onChange={(e)=>handleChange(e)}  id="tb4"name="tb4"/>
        <label style={{ width: "250px", display: "inline-block" }} className="text-dark mx-2" for="tb4">Daily Report</label><br/>
                    </li>
                   
                   
                </ul>
			</div>
            </div>
            </div>
        </div>
        <div className="row">
            
            <div className="col-md-6">


            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Type</label> <br />
            <div className="App">
                  <div className="row my-2">
                      <div className="col-md-3"></div>
                      <div className="col-md-2">
              <input className='mx-2' onChange={(e)=>setTypeA(e.target.value)} type="radio" id="atco" name="desire" value="ATCO" />
 <label for="atco" className="text-dark">ATCO</label>
</div>
<div className="col-md-2"></div>
<div className="col-md-2">
 <input type="radio" className='mx-2'  onChange={(e)=>setTypeA(e.target.value)}  id="atca" name="desire" value="ATCA" />
 <label for="atca"  className="text-dark">ATCA</label>
</div>
<div className="col-md-3"></div>
</div>
                </div>
            </div>
        
            <div className="col-md-6"  >



            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Subject Priority*</label> <br />
            <div className="App">
                <select  onChange={(e)=>setPiority(e.target.value)} id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >-- Select Priority --</option>
    <option value="Demo Low">Demo Low</option>
   
  </select>  



            </div>


            </div>
            </div>
            <div className="row" >
            
			<div className="col-md-6">


<label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Type</label> <br />
<div className="App">
	  <div className="row my-2">
		  <div className="col-md-3"></div>
		  <div className="col-md-2">
  <input className='mx-2' onChange={(e)=>setTypeB(e.target.value)} type="radio" id="special" name="type" value="Special" />
<label for="special" className="text-dark">Special</label>
</div>
<div className="col-md-2"></div>
<div className="col-md-2">
 <input type="radio" className='mx-2'  onChange={(e)=>setTypeB(e.target.value)}  id="general" name="type" value="General" />
 <label for="general" className="text-dark">General</label>
</div>
<div className="col-md-3"></div>
</div>
	</div>
</div>
           
            <div className="col-md-6"  >

            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Description</label> <br />
			<div className="App">
                           <textarea  onChange={(e)=>setDescription(e.target.value)} id="inp4" className="px-2 mx-2 inpFoc radius" style={{ height: "80%", marginBottom:"3%" }} type="text" placeholder="Enter Description ..." />
        </div>
            </div>
            </div>


<div className="row">

<div className="col-md-6">
<label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Active</label> <br />

			<ul style={{
                    listStyleType: "none", width: "350px",
                    display: "inline-block" , marginLeft:"20%"
                }}>
                   
                   
                    <li className="my-1">
                    <input type="checkbox"  onChange={ active ? (e) => {(e.target.checked)?setActive("Active"):setActive("Not Active")} : setActive("Not Active")  } id="tb5"name="tb5" value="Active" />
        <label style={{ width: "250px", display: "inline-block" }} className="text-dark mx-2" for="tb5">Active</label><br/></li>
                   
                   
                   
                </ul>
			</div>
</div>

            <br/>
            <br/>
        
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
<button className="btn px-4 py-2 mx-1" style={{backgroundColor:"blue", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>View Subjects</button>
</form>
		
	</div>




    )
}

export default SubjectForm;
