import "../../App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import InputsHi from "../LOGs/input";
import SnackBar from "../LOGs/SnackBar";
import DOMPurify from "dompurify";
import { useState } from "react";
const Aircraft = () => {
    const [airportInformed, setAirportInformed] = useState('');
    const [aircraftCallSign, setAircraftCallSign] = useState('');
    const [aircraftDiverts, setAircraftDiverts] = useState('');
    const [aircraftType, setAircraftType] = useState('');
    const [SSRCode, setSSRCode] = useState('');
    const [pointOfDeparture, setPointOfDeparture] = useState('');
    const [intendedDestination, setIntendedDestination] = useState('');
    const [newDestination, setNewDestination] = useState('');
    const [timeArrival, setTimeArrival] = useState('');
    const [SASOCCInformed, setSASOCCInformed] = useState('');
    const [details, setDetails] = useState('');
    const [diversionMsg, setDiversionMsg] = useState('');
    const [diversionMsgTimeStamp, setDiversionMsgTimeStamp] = useState('');


    const mysubmit = () => {
        let airportInformedClean = DOMPurify.sanitize(airportInformed, { USE_PROFILES: { html: false } });
        let aircraftCallSignClean = DOMPurify.sanitize(aircraftCallSign, { USE_PROFILES: { html: false } });
        let aircraftDivertsClean = DOMPurify.sanitize(aircraftDiverts, { USE_PROFILES: { html: false } });
        let aircraftTypeClean = DOMPurify.sanitize(aircraftType, { USE_PROFILES: { html: false } });
        let SSRCodeClean = DOMPurify.sanitize(SSRCode, { USE_PROFILES: { html: false } });
        let pointOfDepartureClean = DOMPurify.sanitize(pointOfDeparture, { USE_PROFILES: { html: false } });
        let intendedDestinationClean = DOMPurify.sanitize(intendedDestination, { USE_PROFILES: { html: false } });
        let newDestinationClean = DOMPurify.sanitize(newDestination, { USE_PROFILES: { html: false } });
        let timeArrivalClean = DOMPurify.sanitize(timeArrival, { USE_PROFILES: { html: false } });
        let SASOCCInformedClean = DOMPurify.sanitize(SASOCCInformed, { USE_PROFILES: { html: false } });
        let detailsClean = DOMPurify.sanitize(details, { USE_PROFILES: { html: false } });
        let diversionMsgClean = DOMPurify.sanitize(diversionMsg, { USE_PROFILES: { html: false } });
        let diversionMsgTimeStampClean = DOMPurify.sanitize(diversionMsgTimeStamp, { USE_PROFILES: { html: false } });

        console.log(airportInformedClean);
        console.log(aircraftCallSignClean);
        console.log(aircraftDivertsClean);
        console.log(aircraftTypeClean);
        console.log(SSRCodeClean);
        console.log(pointOfDepartureClean);
        console.log(intendedDestinationClean);
        console.log(newDestinationClean);
        console.log(timeArrivalClean);
        console.log(SASOCCInformedClean);
        console.log(detailsClean);
        console.log(diversionMsgClean);
        console.log(diversionMsgTimeStampClean);
    }

    return (
        <>

            <div className="back7" style={{ fontFamily: "Poppins , sans-serif" }} >

                <div className="App">
                    <h1 style={{ color: "#505050" }} className="fw-bold my-5">Aircraft Diversion</h1>
                </div>

                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }}  >Airport ATC Informed @ Time</label> <br />
                            <div className="App">
                                <InputsHi type="time" onChange={(e) => setAirportInformed(e.target.value)} id="inp2" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Aircraft Callsign</label> <br />
                            <div className="App">
                                <InputsHi type="text" id="inp2" onChange={(e) => setAircraftCallSign(e.target.value)} placeholder="Enter Aircraft Callsign..." />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }}  >Time When Aircraft Diverts*</label> <br />
                            <div className="App">
                                <InputsHi type="time" onChange={(e) => setAircraftDiverts(e.target.value)} id="inp2" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Aircraft Type*</label> <br />
                            <div className="App">
                                <InputsHi type="text" id="inp2" onChange={(e) => setAircraftType(e.target.value)} placeholder="Enter Aircraft Type..." />
                            </div>
                        </div>


                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Transponder Code*</label> <br />
                            <div className="App">
                                <InputsHi type="text" onChange={(e)=>setSSRCode(e.target.value)} id="inp2" required placeholder="Enter Transponder Code..." />
                            </div>
                        </div>
                        <div className="col-md-6">

                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Point of Departure*</label> <br />
                            <div className="App">
                                <InputsHi type="text" onChange={(e)=>setPointOfDeparture(e.target.value)} id="inp2" required placeholder="Enter Point of Departure..." />
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Intended Destination*</label> <br />
                            <div className="App">
                                <InputsHi type="text" id="inp2" onChange={(e)=>setIntendedDestination(e.target.value)}  required placeholder="Enter Intended Destination..." />
                            </div>
                        </div>
                        <div className="col-md-6">

                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >New Destination*</label> <br />
                            <div className="App">
                                <InputsHi type="text" onChange={(e)=>setNewDestination(e.target.value)} id="inp2" placeholder="Enter New Destination..." />
                            </div>
                        </div>


                    </div>


                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Actual Time of Arrival</label> <br />
                            <div className="App">
                                <InputsHi type="time" onChange={(e)=>setTimeArrival(e.target.value)} id="inp2" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Informed @ Time</label> <br />
                            <div className="App">
                                <InputsHi type="time" onChange={(e)=>setSASOCCInformed(e.target.value)} id="inp2" />
                            </div>

                        </div>


                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }}>Details / Reasons</label> <br />
                            <div className="App">
                                <InputsHi type="text" id="inp2" onChange={(e)=>setDetails(e.target.value)} placeholder="Enter Details..." />
                            </div>
                        </div>
                        <div className="col-md-6">

                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Diversion Arrival MSG Sent</label> <br />
                            <div className="App">
                                <div className="row my-2">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-2">
                                        <input type="radio" onChange={(e)=>setDiversionMsg(e.target.value)} id="yes" name="desire" value="Yes" />
                                        <label for="yes" className="text-dark mx-2">Yes</label>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-2">
                                        <input type="radio"  onChange={(e)=>setDiversionMsg(e.target.value)} id="no" name="desire" value="No" />
                                        <label for="no" className="text-dark mx-2">No</label>
                                    </div>

                                    <div className="col-md-3"></div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">

                            <label htmlFor="inp2" className="fw-bold" style={{ marginLeft: "15%" }} >Diversion Arrival MSG Sent Time Stamp</label> <br />
                            <div className="App">
                                <InputsHi onChange={(e)=>setDiversionMsgTimeStamp(e.target.value)} type="time" id="inp2" />
                            </div>
                        </div>



                    </div>


                    <div style={{ paddingBottom: "1%", marginTop: "1%" }}>
                        <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
                    </div>
                </form>

            </div>
        </>
    )
}
export default Aircraft;