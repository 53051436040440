import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';

const LocalStandby = () => {

    const [landingRWY, setLandingRWY] = useState('');
    const [aircraftType, setAircraftType] = useState('');
    const [ETA, setETA] = useState('');
    const [pob, setPob] = useState('');
    const [reasonForAction, setReasonForAction] = useState('');
    const [pilotIntentions, setPilotIntentions] = useState('');


    const mysubmit = () => {
        let landingRWYClean = DOMPurify.sanitize(landingRWY, { USE_PROFILES: { html: false } });
        let aircraftTypeClean = DOMPurify.sanitize(aircraftType, { USE_PROFILES: { html: false } });
        let ETAClean = DOMPurify.sanitize(ETA, { USE_PROFILES: { html: false } });
        let pobClean = DOMPurify.sanitize(pob, { USE_PROFILES: { html: false } });
        let reasonForActionClean = DOMPurify.sanitize(reasonForAction, { USE_PROFILES: { html: false } });
        let pilotIntentionsClean = DOMPurify.sanitize(pilotIntentions, { USE_PROFILES: { html: false } });

        console.log(landingRWYClean);
        console.log(aircraftTypeClean);
        console.log(ETAClean);
        console.log(pobClean);
        console.log(reasonForActionClean);
        console.log(pilotIntentionsClean);
    }



    return (
        <div className="font back7">
    {/*  */}
 
        <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Local Standby</h1>
        </div>
<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">

         
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Landing RWY or Location on Airport</label> <br />
          <div className="App">
            <InputsHi type="text"  onChange={(e) => setLandingRWY(e.target.value)}  placeholder="Landing RWY or Location on Airport" id="inp2" />
            </div>
            </div>
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold"  style={{marginLeft:"15%"}} >Aircraft Type</label> <br />
            <div className="App">
            <InputsHi type="text"  onChange={(e) => setAircraftType(e.target.value)}  id="inp2" placeholder="Enter Aircraft Type(Auto Complete)" />
            </div>
            </div>
        </div>
        <div className="row">
        <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold"  style={{marginLeft:"15%"}} >ETA</label> <br />
            <div className="App">
            <InputsHi type="time"  onChange={(e) => setETA(e.target.value)}  id="inp2" />
            </div>
            </div>
       
            <div className="col-md-6">
            <label htmlFor="inp4" className="fw-bold"  style={{marginLeft:"15%"}} >POB (if known)</label> <br />
            <div className="App">
            <InputsHi type="text" id="inp4"  onChange={(e) => setPob(e.target.value)}  placeholder="POB (if known)" />
            </div>
            </div>
             <br />
            </div>
            <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp4" className="fw-bold"  style={{marginLeft:"15%"}} >Reason for Action</label> <br />
            <div className="App">
            <InputsHi type="text" id="inp4"  onChange={(e) => setReasonForAction(e.target.value)}  placeholder="Reason for Action" />
            </div>
            </div>
            
      
            <div className="col-md-6">
            <label htmlFor="inp4" className="fw-bold"  style={{marginLeft:"15%"}} >Pilot’s Intentions</label> <br />
         <div className="App">
            <InputsHi type="text" id="inp4"  onChange={(e) => setPilotIntentions(e.target.value)}  placeholder="Pilot’s Intentions" />
                </div>
                </div>
            
        </div>
            
            
      
            <div className="col-md-6">
           
                </div>
   
       {/* Check Boxxes */}

      <div style={{marginLeft:"90px",marginTop:"80px", fontFamily:'Poppins',fontWeight:'400'}}>

<h5 style={{fontFamily:'Poppins',fontWeight:'600'}}>Pass the information above to:</h5>




<ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb1"name="tb1" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb1"> GMC/ADC</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb2"name="tb2"/>
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb2">ATCA</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb3" name="tb3" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb3"> DPN DL (if necessary, ask for increased spacing/ stop arrival traffic) </label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb4" name="tb4" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb4"> Support Manager (use Support Manager On-Call list) </label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb5" name="tb5" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb5"> Split ADC/GMC (if necessary) </label><br/>
                    </li>
      
                </ul>

<br />





        <h7 style={{fontFamily:'Poppins',fontSize:"14px",fontWeight:'500'}}>Notify the following when Local Standby is cancelled</h7><br/><br/>




        <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb6"name="tb6" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb6"> GMC/ADC</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb7"name="tb7"/>
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb7">ATCA</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb8" name="tb8" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb8"> DPN DLDL (if necessary, revise arrival spacing/ resume arrival traffic) </label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb9" name="tb9" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb9">Support Manager </label><br/>
                    </li>
               
      
                </ul>






   

        <h6 style={{fontFamily:'Poppins',fontSize:"15px",fontWeight:'500'}}>Request RWY Inspection if required</h6>
        <br/>
        <h4 style={{fontFamily:'Poppins',fontSize:"15px",fontWeight:'600'}}>File ROSI</h4>

</div>
{/* Check Boxxes End */}
        <div style={{paddingBottom:"1%", marginTop:"3 %"}}>
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
        </div>
</form>
    </div>
    )
}

export default LocalStandby;
