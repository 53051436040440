import "../../App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import InputsHi from '../LOGs/input'
import SnackBar from "../LOGs/SnackBar";
import { useState } from "react";
import DOMPurify from "dompurify";

const METCondition = () => {
    const [timeStamp, setTimeStamp] = useState('');
    const [metCondition, setMetCondition] = useState('');
    const [visibility, setVisibility] = useState('');
    const [ceiling, setCeiling] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });     
        let metConditionClean = DOMPurify.sanitize(metCondition, { USE_PROFILES: { html: false } });
        let visibilityClean = DOMPurify.sanitize(visibility, { USE_PROFILES: { html: false } });
        let ceilingClean = DOMPurify.sanitize(ceiling, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(metConditionClean);
        console.log(visibilityClean);
        console.log(ceilingClean);
    }


    return (
        <div className="font back7">
           
<br />
<br />


            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold ">MET Condition</h1>
            </div>
<br />
<form onSubmit={(e)=>e.preventDefault()} style={{paddingTop:"20px"}}>
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold"  style={{marginLeft:"15%"}} >Time Stamp</label> <br />
                <div className="App">
                <InputsHi type="datetime-local" onChange={(e)=>setTimeStamp(e.target.value)} id="inp2" />
                </div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp3" className="fw-bold"  style={{marginLeft:"15%"}}  >MET Condition</label> <br />
             <div className="App">
                <select id="inp3"  onChange={(e)=>setMetCondition(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >-- Select Condition --</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>            
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp4" className="fw-bold"  style={{marginLeft:"15%"}}>Visibility in Meter</label> <br />
                <div className="App">
                <select id="inp4"  onChange={(e)=>setVisibility(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >-- Select Visibility --</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>   
                </div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp5" className="fw-bold"  style={{marginLeft:"15%"}}>Ceiling</label> <br />
              <div className="App">
                <select id="inp5"  onChange={(e)=>setCeiling(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >-- Select Ceiling --</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>            
                </div>
                </div>

                
            </div>
           
            <div style={{paddingBottom:"3%", marginTop:"5%"}}>

                <SnackBar/>
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
            </div>
</form>

        </div>
    )
}
export default METCondition;