import React , {useState} from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';
import DOMPurify from 'dompurify';


const TrainingSession = () => {

    const [date, setDate] = useState('');
    const [UTCStart, setUTCStart] = useState('');
    const [UTCEnd, setUTCEnd] = useState('');
    const [duration, setDuration] = useState('05:20');
    const [position, setPosition] = useState('');
    const [trainee, setTrainee] = useState('');
    const [complexity, setComplexity] = useState('');
    const [objective, setObjective] = useState('');
    const [density, setDensity] = useState('');
    const [weakness, setWeakness] = useState('');
    const [sessionObservations, setSessionObservations] = useState('');
    const [strengths, setStrengths] = useState('');


    const mysubmit = () => {
        let dateClean = DOMPurify.sanitize(date, { USE_PROFILES: { html: false } });
        let UTCStartClean = DOMPurify.sanitize(UTCStart, { USE_PROFILES: { html: false } });
        let UTCEndClean = DOMPurify.sanitize(UTCEnd, { USE_PROFILES: { html: false } });
        let durationClean = DOMPurify.sanitize(duration, { USE_PROFILES: { html: false } });
        let positionClean = DOMPurify.sanitize(position, { USE_PROFILES: { html: false } });
        let traineeClean = DOMPurify.sanitize(trainee, { USE_PROFILES: { html: false } });
        let complexityClean = DOMPurify.sanitize(complexity, { USE_PROFILES: { html: false } });
        let objectiveClean = DOMPurify.sanitize(objective, { USE_PROFILES: { html: false } });
        let densityClean = DOMPurify.sanitize(density, { USE_PROFILES: { html: false } });
        let weaknessClean = DOMPurify.sanitize(weakness, { USE_PROFILES: { html: false } });
        let sessionObservationsClean = DOMPurify.sanitize(sessionObservations, { USE_PROFILES: { html: false } });
        let strengthsClean = DOMPurify.sanitize(strengths, { USE_PROFILES: { html: false } });

        console.log(dateClean);
        console.log(UTCStartClean);
        console.log(UTCEndClean);
        console.log(durationClean);
        console.log(positionClean);
        console.log(traineeClean);
        console.log(complexityClean);
        console.log(objectiveClean);
        console.log(densityClean);
        console.log(weaknessClean);
        console.log(sessionObservationsClean);
        console.log(strengthsClean);
    }




    return (



        <div className="font back7">
        
     
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Training Session</h1>
            </div>
    
    <form onSubmit={(e)=>e.preventDefault()}>
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Date</label> <br />
                <div className="App">
                <InputsHi type="date" onChange={(e)=>setDate(e.target.value)} id="inp2" />
                </div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold clas5" style={{marginLeft:"15%"}} >UTC Start</label> <br />
                <div className="App">
                <InputsHi type="time" onChange={(e)=>setUTCStart(e.target.value)}  id="inp2"  />
                </div>
                </div>
            </div>
            <div className="row">
            <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold clas5" style={{marginLeft:"15%"}} >UTC End</label> <br />
                <div className="App">
                <InputsHi type="time"  onChange={(e)=>setUTCEnd(e.target.value)}  id="inp2"  />
                </div>
                </div>
                
                <div className="col-md-6">
    
                <label htmlFor="inp2" className="fw-bold clas5" style={{marginLeft:"15%"}} >Duration</label> <br />
                <div className="App">
                <input className="px-3 py-2 mx-2 inpFoc radius"  onChange={(e)=>setDuration(e.target.value)} value={duration} id="inp7" style={{ backgroundColor: "#e9edc9",marginBottom:"4%" }} disabled id="outlined-disabled" type="text" />
    </div>
    
               
                </div>
                </div>
    
                <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold clas5" style={{marginLeft:"15%"}} >Position</label>
              
                <div className="App">
                <select id="inp3"  onChange={(e)=>setPosition(e.target.value)}  className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
                <option selected > -- Select User -- </option>
                <option value="Demo Position">Demo Position</option>
                <option value="Positon Name">Positon Name</option>
                </select>
    </div>
    
                </div>
    
                
          
          
                <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Trainee</label>
                <div className="App">
                <select id="inp3"  onChange={(e)=>setTrainee(e.target.value)}  className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
                <option selected > -- Select Trainee -- </option>
                <option value="OMA">OMA</option>
                </select>
                </div>
                </div>
                
            </div>
    
    
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Complexity</label>
                <div className="App">
                <select id="inp3"  onChange={(e)=>setComplexity(e.target.value)}  className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
                <option selected > -- Select Complexity -- </option>
                <option value="Easy">Easy</option>
                <option value="Moderate">Moderate</option>
                <option value="Complex">Complex</option>
    
                </select>
                </div>
                </div>
    
                
          
          
                <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Objective</label> <br />
                <div className="App">
                <InputsHi type="text"  onChange={(e)=>setObjective(e.target.value)}  id="inp2"  />
               </div>
                </div>
                
            </div>
    
    
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Density</label>
                <div className="App">
                <select id="inp3"  onChange={(e)=>setDensity(e.target.value)}  className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
                <option selected > -- Select Density -- </option>
                <option value="Light">Light</option>
                <option value="Heavy">Heavy</option>
                <option value="Medium">Medium</option>
    
                </select>
                </div>
                </div>
    
                
          
          
                <div className="col-md-6">
                
                <label className="fw-bold clas7" style={{marginLeft:"15%"}}  htmlFor="inp4" >Weakness</label> <br />
                <div className="App">
                        <textarea id="inp4"  onChange={(e)=>setWeakness(e.target.value)}  className="px-2 mx-2 inpFoc radius" style={{marginBottom:"3%" }} type="text"  />
               </div>
                </div>
                
            </div>
    
            <div className="row">
                <div className="col-md-6">
                <label className="fw-bold clas7" style={{marginLeft:"15%"}}  htmlFor="inp4" >Session Observations </label> <br />
                <div className="App">
                        <textarea id="inp4"  onChange={(e)=>setSessionObservations(e.target.value)}  className="px-2 mx-2 inpFoc radius" style={{ marginBottom:"3%" }} type="text"  />
               </div>
                </div>
    
                
          
          
                <div className="col-md-6">
                
                <label className="fw-bold clas7" style={{marginLeft:"15%"}} htmlFor="inp4" >Strengths</label> <br />
                <div className="App">
                        <textarea id="inp4"  onChange={(e)=>setStrengths(e.target.value)}  className="px-2 mx-2 inpFoc radius" style={{ height:"60%", marginBottom:"3%" }} type="text"  />
               </div>
                </div>
                
            </div>
    
    
    
    
           
            <div style={{paddingBottom:"1%", marginTop:"1%"}}>
    <SnackBar/>
    <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
            </div>
    </form>
    
        </div>
    )
}

export default TrainingSession;
