import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';


const PurposeForm = () => {
    const [purpose, setPurpose] = useState('');
    const [description, setDescription] = useState('');


    const mysubmit = () => {
        let purposeClean = DOMPurify.sanitize(purpose, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });

        console.log(purposeClean);
        console.log(descriptionClean);
    }
    return (
        <div className="container-fluid" style={{fontFamily:"Poppins , sans-serif"}}>
            {/*  */}
            
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Purpose Form</h1>
            </div>

<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Purpose</label> <br />
                   <div className="App">
                <InputsHi onChange={(e)=>setPurpose(e.target.value)} type="text" id="inp2" placeholder="Enter Purpose..." />
            </div>
            </div>
            <div className="col-md-6"  >

<label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Description</label> <br />
<div className="App">
               <textarea id="inp4" onChange={(e)=>setDescription(e.target.value)} className="px-2 mx-2 inpFoc radius" style={{ height: "80%", marginBottom:"3%" }} type="text" placeholder="Enter Description ..." />
</div>
</div>
            
        </div>
       

            <br/>
            <br/>
        
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
<button className="btn px-4 py-2 mx-1" style={{backgroundColor:"blue", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>View Subjects</button>
</form>
		

        </div>
    )
}

export default PurposeForm;
