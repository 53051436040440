import React , {useState} from 'react'
import { FaEdit , FaTrash } from 'react-icons/fa';
import InfoIcon from '@mui/icons-material/Info';
import Switches from '../LOGs/switch';

const UserRoles = () => {
	const [edit1 , setEdit1] = useState(true)
	const [deletx1 , setDeletx1] = useState(true)
	const [details1 , setDetails1] = useState(true)


const [userRolesData , setUserRolesData] = useState([
	{
	sno : "1",
	role : "ATCA",

},
	{
	sno : "2",
	role : "Administration",

},
	{
	sno : "3",
	role : "AIS",

},
	{
	sno : "4",
	role : "CIC",

},
	{
	sno : "5",
	role : "AIC",

},
	{
	sno : "6",
	role : "ATCO",

},
	{
	sno : "7",
	role : "Admin Assistant",

},
	{
	sno : "8",
	role : "ATCO-Trainee",

},
	{
	sno : "9",
	role : "ATCO-OJTI",

},
	{
	sno : "10",
	role : "Head of Quality",

},
	{
	sno : "11",
	role : "Head of Operations",

},
	{
	sno : "12",
	role : "Head of Safety",

},
	{
	sno : "13",
	role : "Head of Training",

},
	{
	sno : "14",
	role : "ATCA-Trainee",

},
	{
	sno : "15",
	role : "Demo",

},
	{
	sno : "16",
	role : "Demo role",

},
	

])



    return (

        <div className="font back7">
            
        <div className="App">
            <h1 style={{ color: "#505050", fontFamily:"Poppins",fontSize:"45px" }} className="fw-bold my-5">User Roles</h1>
        </div>
        <br/>
        <div class="portlet light">
			
			<div class="portlet-body container">
				<div class="row pform" style={{paddingTop:"1%"}}>
				</div>
				<button className="btn px-3 py-2 mx-7" value="Cancel" onclick="clearAccessLogsForm();" style={{backgroundColor:"#ff3333", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"1%"}}>Add New Role</button>

                <div class="table-scrollable">
					
					<table class="table table-striped table-bordered table-hover">
						<thead>
							
							<br/>
							<tr>
								
								<th scope="col">
									S.No
								</th>
								<th scope="col" colSpan={3}>
									Role
								</th>
								<th scope="col">
									Actions
								</th>
								<th  scope="col">
									Disable
								</th>
								
							</tr>
						</thead>
						<tbody>
												
												{userRolesData.map((e,i)=>{

return(
<tr key={i}>
<td style={{fontSize:"15px"}} >
	{e.sno}								</td>
<td colSpan={3} style={{fontSize:"15px"}}>
	 {e.role}								</td>
<td>


<div className="row text-center">
<div className="col-md-1"></div>
<div className="col-md-3">
		{/* <Link to="/dashboard/tabst"> */}
		<button class="my-2" style={{fontSize:"20px" , border:"none"}}><FaEdit style={ edit1 ? { color:'black'} : {color : 'gray'} } onClick={()=> {( edit1 === true)? setEdit1(false): setEdit1(true)}} className="my-2"   /></button>
		{/* </Link> */}
		</div>
		<div className="col-md-3">

		<button class="my-2" style={{fontSize:"20px" , border:"none"}}><InfoIcon style={ details1 ? { color:'black'} : {color : 'gray'} } onClick={()=> {(details1 === true)?setDetails1(false): setDetails1(true)}} className="my-2" /></button>
	</div>
	<div className="col-md-3">

		{/* <Link to="/dashboard/userProfile"> */}
		<button class="my-2" style={{fontSize:"20px" , border:"none"}}><FaTrash style={ deletx1 ? { color:'black'} : {color : 'gray'} } onClick={()=> {(deletx1 === true)?setDeletx1(false): setDeletx1(true)}} className="my-2" /></button>
		{/* </Link> */}
		</div>
		
	</div>

	
</td>

<td>
<div style={{marginLeft:"75px"}}><Switches /></div></td>
</tr>






												)
												})}
													
											
							
									
							
														
						</tbody>
						
					</table>
				</div>
				<div class="add-new-role-form-wrap" style={{display:"none"}}>
					
				</div>
				<div class="edit-role-form-wrap" style={{display:"none"}}>
					
				</div>
				<div class="container">

					<div class="modal fade" id="myModal1" role="dialog">
						<div class="modal-dialog">
							
							
							<div class="modal-content">
								<div class="modal-header">
									<button type="button" class="close" data-dismiss="modal">×</button>
									<h4 class="modal-title">Add Role</h4>
								</div>
								<div class="modal-body">
									<div class="add-new-role-form-wrap">
					<form action="https://demo.at-elog.com/index/insertRole" accept-charset="utf-8" method="post" class="add-new-role-form" id="form_data">											<label>Role: </label>
											<input type="text" name="role" class="form-control" placeholder="Role..." required="required" autocomplete="off"/><br/>
											<input type="submit" class="btn btn-success btn_submit btn-circle" value="Add" autocomplete="off"/>
								</form>									</div>
								</div>
								<div class="modal-footer">
									<button type="button" class="btn btn-default btn-circle" data-dismiss="modal">Close</button>
								</div>
							</div>
							
						</div>
					</div>
					
				</div>
				
				<div class="container">
					
		
					<div class="modal fade" id="myModal2" role="dialog">
						<div class="modal-dialog">
							
							
							<div class="modal-content">
								<div class="modal-header">
									<button type="button" class="close" data-dismiss="modal">×</button>
									<h4 class="modal-title">Edit Unit</h4>
								</div>
								<div class="modal-body">
									<div class="edit-role-form-wrap">
									<form action="https://demo.at-elog.com/index/insertRole" accept-charset="utf-8" method="post" class="edit-role-form" id="data_form">											<label>Role: </label>
											<input type="text" name="role" class="form-control" value="" required="required" autocomplete="off"/>
											<input type="submit" class="btn btn-success btn_submit2" value="Update" autocomplete="off"/>
										</form>									</div>
								</div>
								<div class="modal-footer">
									<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
								</div>
							</div>
							
						</div>
					</div>
					
				</div>
			</div>
            <br/>
		</div>
        </div>
    )
}

export default UserRoles
