import React, { useState } from 'react';
import Navbar from "../navbar/topnav";
import SnackBar from "../LOGs/SnackBar";
import InputsHi from '../LOGs/input';
import DOMPurify from 'dompurify';

const AircraftAcci = () => {
 
    const [landingRWY, setLandingRWY] = useState('');
    const [aircraftType, setAircraftType] = useState('');
    const [timeOfIncident, setTimeOfIncident] = useState('');
    const [pob, setPob] = useState('');
    const [fuel, setFuel] = useState('');
    const [natureofEmergency, setNatureofEmergency] = useState('');
    const [pilotIntentions, setpilotIntentions] = useState('');


    const mysubmit = () => {
        let landingRWYClean = DOMPurify.sanitize(landingRWY, { USE_PROFILES: { html: false } });
        let aircraftTypeClean = DOMPurify.sanitize(aircraftType, { USE_PROFILES: { html: false } });
        let timeOfIncidentClean = DOMPurify.sanitize(timeOfIncident, { USE_PROFILES: { html: false } });
        let pobClean = DOMPurify.sanitize(pob, { USE_PROFILES: { html: false } });
        let fuelClean = DOMPurify.sanitize(fuel, { USE_PROFILES: { html: false } });
        let natureofEmergencyClean = DOMPurify.sanitize(natureofEmergency, { USE_PROFILES: { html: false } });
        let pilotIntentionsClean = DOMPurify.sanitize(pilotIntentions, { USE_PROFILES: { html: false } });

        console.log(landingRWYClean);
        console.log(aircraftTypeClean);
        console.log(timeOfIncidentClean);
        console.log(pobClean);
        console.log(fuelClean);
        console.log(natureofEmergencyClean);
        console.log(pilotIntentionsClean);
    }

return (
    <>
    <div className="font back7">
    {/*  */}
 
        <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Aircraft Accident</h1>
        </div>
<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">

         
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Landing RWY or Location on Airport</label> <br />
          <div className="App">
            <InputsHi type="text" onChange={(e)=>setLandingRWY(e.target.value)} placeholder="Landing RWY or Location on Airport" id="inp2" />
            </div>
            </div>
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Aircraft Type</label> <br />
            <div className="App">
            <InputsHi type="text" id="inp2" onChange={(e)=>setAircraftType(e.target.value)}  placeholder="Enter Aircraft Type" />
            </div>
            </div>
        </div>
        <div className="row">
        <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Time of Incident</label> <br />
            <div className="App">
            <InputsHi type="time" onChange={(e)=>setTimeOfIncident(e.target.value)}  id="inp2" />
            </div>
            </div>
       
            <div className="col-md-6">
            <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}} >POB (if known)</label> <br />
            <div className="App">
            <InputsHi type="text" id="inp4" onChange={(e)=>setPob(e.target.value)}  placeholder="POB (if known)" />
            </div>
            </div>
             <br />
            </div>
            <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}} >Fuel (if known)</label> <br />
            <div className="App">
            <InputsHi type="text" id="inp4" onChange={(e)=>setFuel(e.target.value)}  placeholder="Fuel (if known)" />
            </div>
            </div>
            
      
            <div className="col-md-6">
            <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}} >Nature of Emergency / Accident</label> <br />
            <div className="App">
            <InputsHi type="text" id="inp4" onChange={(e)=>setNatureofEmergency(e.target.value)}  placeholder="Nature of Emergency / Accident" />
                </div>
                </div>
            
        </div>
            <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}} >Pilot's Intentions</label> <br />
            <div className="App">
            <InputsHi type="text" id="inp4" onChange={(e)=>setpilotIntentions(e.target.value)}  placeholder="Pilot's Intentions" />
            </div>
            </div>
            
      
            <div className="col-md-6">
           
                </div>
            
        </div>
       {/* Check Boxxes */}

      <div style={{marginLeft:"90px",marginTop:"80px", fontFamily:'Poppins',fontWeight:'400'}}>



  


<h5 style={{fontFamily:'Poppins',fontWeight:'600'}}>Pass the information above to:</h5>
<ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb1"name="tb1" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb1">GMC/ADC</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb2"name="tb2"/>
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb2">ATCA</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb3" name="tb3" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb3"> DPN DL (if necessary, ask for increased spacing/ stop arrival traffic)</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb4" name="tb4" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb4"> PABX HL</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb7" name="tb7" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb7"> Support Manager (use On-Call list)</label><br/><br/>

                    </li>
                </ul>

        <h6 style={{fontFamily:'Poppins',fontSize:"15px",fontWeight:'600'}}>If the emergency/accident is OFF the Airport,<br  />  only then pass the information to:</h6><br/><br/>




        <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb8"name="tb8" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb8"> Police Airwing</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb9"name="tb9"/>
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb9"> Coast Guard</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb10" name="tb10" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb10"> Port Room</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb11" name="tb11" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb11"> Split ADC/GMC</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb12" name="tb12" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb12">Refer to IFER Manual</label><br/><br/>

                    </li>
                </ul>










       

<h6 style={{fontFamily:'Poppins',fontWeight:'600'}}>Request reports from:</h6>



<ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb13" name="tb13"/>
        <label  style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2"  for="tb13"> Met (official weather observation)</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb014" name="tb014"/>
        <label style={{ width: "550px", display: "inline-block" }} className="text-dark mx-2" for="tb014">AFL Mobile (request airfield lighting status report)</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb015" name="tb015"/>
        <label style={{ width: "550px", display: "inline-block" }} className="text-dark mx-2" for="tb015"> U/S CNS EQIP (request status report for all equipment)</label><br/>
                    </li>
            
                    <ul style={{marginLeft:"30px", marginTop:"10px"}}>
                    <li style={{fontWeight:'600'}}>NAVAID's</li>
<li style={{fontWeight:'600'}}>Met Equipment</li><br/>
                    </ul>
                    
                </ul>








       

      
<h6 style={{fontFamily:'Poppins',fontSize:"15px",fontWeight:'500'}}>Notify the following when Full Emergency is cancelled</h6>

<ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb16"name="tb16" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb16">GMC/ADC</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb17"name="tb17"/>
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb17">ATCA</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb18" name="tb18" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb18"> DPN DL (inform them of arrival spacing requirements)</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb19" name="tb19" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb19"> Support Manager</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb20" name="tb20" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb20">PABX HL</label><br/><br/>

                    </li>
                </ul>



        <h6 style={{fontFamily:'Poppins',fontSize:"15px",fontWeight:'500'}}>Request a runway inspection (if applicable)</h6>
        <br/>
        <h4 style={{fontFamily:'Poppins',fontSize:"19px",fontWeight:'600'}}>File ROSI (within 3 hours) (Ref OM 8.4.2)</h4>

</div>
{/* Check Boxxes End */}
        <div style={{paddingBottom:"1%", marginTop:"3 %"}}>
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
        </div>
</form>
    </div>
    </>
)
}
export default AircraftAcci;