import "../../App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import InputsHi from '../LOGs/input'
// import SnackBar from "../LOGs/SnackBar";
import { useState } from "react";
import DOMPurify from "dompurify";
import SnackBar from "../LOGs/SnackBar";
// import csurf from "csurf";
import { useSelector } from "react-redux";

const Routine = () => {
    // var csurf = require('csurf')

    const count = useSelector((state) => state.myObj)


    const [subject, setsubject] = useState('');
    const [vehicle, setVehicle] = useState('');
    const [description, setDescription] = useState('');
    let subjectclean = DOMPurify.sanitize(subject, { USE_PROFILES: { html: false } });
    let vehicleClean = DOMPurify.sanitize(vehicle, { USE_PROFILES: { html: false } });
    let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });
   

const mysubmit = () => {
console.log(subjectclean);
console.log(vehicleClean);
console.log(descriptionClean);

    }

    
    // var cookieParser = require('cookie-parser')
    // var csrf = require('csurf')
    // var bodyParser = require('body-parser')
    // var express = require('express')
     
    // setup route middlewares
    // var csrfProtection = csrf({ cookie: true })
    // var parseForm = bodyParser.urlencoded({ extended: false })
     
    // create express app
    // var app = require('express')
     
    // parse cookies
    // we need this because "cookie" is true in csrfProtection
    // app.use(cookieParser())
     
    // app.get('/form', csrfProtection, function (req, res) {
      // pass the csrfToken to the view
    //   res.render('send', { csrfToken: req.csrfToken() })
    // })
     
    // app.post('/process', parseForm, csrfProtection, function (req, res) {
    //   res.send('data is being processed')
    // })
    return (
        <div className="font back7">
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Routine Entry</h1>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
   
                <div className="row">

                    <div className="col-md-6">

                        <label className="fw-bold" htmlFor="inp2" style={{ marginLeft: "15%" }}>Subject</label> <br />

                        <div className="App">
                            <InputsHi type="text" id="inp2" onChange={(e) => setsubject(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="fw-bold" htmlFor="inp3" style={{ marginLeft: "15%" }}>Vehicle</label> <br />

                        <div className="App">
                            <InputsHi type="text" id="inp3" onChange={(e) => setVehicle(e.target.value)} placeholder="Enter Vehicle" />
                        </div>

                    </div>
                </div>
                <div className="row">

                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp4" >Description</label> <br />
                        <div className="App">
                            <textarea id="inp4" onChange={(e) => setDescription(e.target.value)} placeholder="Enter Description..." className="px-2 mx-2 inpFoc" style={{ height: "80%", marginBottom: "3%" }} type="text" />
                        </div>
                    </div>


                </div>
                <div className="my-5">
                    <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
                </div>
            </form>

        </div>
    )
}
export default Routine;