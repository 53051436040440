import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';
// import Navbar from '../navbar/topnav';   

const PositionLogSummaryRep = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [type, setType] = useState('');
    const [designation, setDesignation] = useState('');
    const [positions, setPositions] = useState('');
    const [initials, setInitials] = useState('');


    const mysubmit = () => {
        let fromDateClean = DOMPurify.sanitize(fromDate, { USE_PROFILES: { html: false } });
        let toDateClean = DOMPurify.sanitize(toDate, { USE_PROFILES: { html: false } });
        let typeClean = DOMPurify.sanitize(type, { USE_PROFILES: { html: false } });
        let designationClean = DOMPurify.sanitize(designation, { USE_PROFILES: { html: false } });
        let positionsClean = DOMPurify.sanitize(positions, { USE_PROFILES: { html: false } });
        let initialsClean = DOMPurify.sanitize(initials, { USE_PROFILES: { html: false } });

        console.log(fromDateClean);
        console.log(toDateClean);
        console.log(typeClean);
        console.log(designationClean);
        console.log(positionsClean);
        console.log(initialsClean);
    }

    return (
        <div className="font back7">
        {/*  */}
     
        <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Position Log Summary Report</h1>
        </div>
<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >From Date</label> <br />
                   <div className="App">
                <InputsHi type="date"  onChange={(e) => setFromDate(e.target.value)}  id="inp2" />
            </div>
            </div>
            <div className="col-md-6">
            <label className="fw-bold"  htmlFor="inp3" style={{marginLeft:"15%"}} >To Date</label> <br />
<div className="App">
                <InputsHi type="date"  onChange={(e) => setToDate(e.target.value)}  id="inp3" />
            </div>
            </div>
        </div>
        <div className="row">
            
            <div className="col-md-6">


            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Type</label> <br />
            <div className="App">
                <select id="inp3"  onChange={(e) => setType(e.target.value)}  className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >Select Type</option>
    <option value="OJTI">OJTI</option>
    <option value="SOLO">SOLO</option>
    <option value="TRAINEE">TRAINEE</option>
  </select>  



            </div>
            </div>
        
            <div className="col-md-6"  >



            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Designation</label> <br />
            <div className="App">
                <select id="inp3"  onChange={(e) => setDesignation(e.target.value)}  className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >Select Designation</option>
    <option value="ATCO">ATCO</option>
    <option value="ATCA">ATCA</option>

  </select>  



            </div>


            </div>
            </div>
            <div className="row" >
            
            <div className="col-md-6">



            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Positions</label> <br />
            <div className="App">
                <select id="inp3"  onChange={(e) => setPositions(e.target.value)}  className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >Select Positions</option>
    <option value="Demo Position">Demo Position</option>
    <option value="Position Name">Position Name</option>

  </select>  



            </div>


         
            </div>
           
            <div className="col-md-6"  >

            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Initials</label> <br />
            <div className="App">
                <select id="inp3"  onChange={(e) => setInitials(e.target.value)}  className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >Select Initials</option>
    <option value="OMA">OMA</option>

  </select>  


            </div>
            </div>
            </div>
            <br/>
            <br/>
        
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
</form>

    </div>
    )
}

export default PositionLogSummaryRep;
