import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const StaffEntry = () => {

    const [timeStamp, setTimeStamp] = useState('');
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });     
        let subjectClean = DOMPurify.sanitize(subject, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(subjectClean);
        console.log(descriptionClean);
    }

    return (
        <div style={{ fontFamily: "Poppins , sans-serif" }} >
            


            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Staff Entry</h1>
            </div>
            <form onSubmit={(e)=>e.preventDefault()}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp3" >Time Stamp</label> <br />
                        <div className="App">
                            <InputsHi onChange={(e)=>setTimeStamp(e.target.value)} type="datetime-local" id="inp3" />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp4" >Subject</label> <br />
                        <div className="App">
                            <InputsHi type="text" onChange={(e)=>setSubject(e.target.value)} placeholder="Enter Subject..." id="inp4" />
                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp4" >Description</label> <br />
                        <div className="App" >
                            <textarea id="inp4" onChange={(e)=>setDescription(e.target.value)} placeholder="Enter Description..." className="px-2 mx-2 inpFoc radius" style={{ height: "70%", marginBottom: "5%", paddingBottom: "5%" }} type="text" />
                        </div>
                    </div>

                </div>


                <SnackBar />


       <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>

            </form>
        </div>
    )
}

export default StaffEntry;
