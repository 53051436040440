import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const CNSFaultReporting = () => {


    const [timeStamp, setTimeStamp] = useState('');
    const [consoleNumber, setConsoleNumber] = useState('');
    const [position, setPosition] = useState('');
    const [notam, setNotam] = useState('');
    const [equipment, setEquipment] = useState('');
    const [description, setDescription] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });
        let consoleNumberClean = DOMPurify.sanitize(consoleNumber, { USE_PROFILES: { html: false } });
        let positionClean = DOMPurify.sanitize(position, { USE_PROFILES: { html: false } });
        let notamClean = DOMPurify.sanitize(notam, { USE_PROFILES: { html: false } });
        let equipmentClean = DOMPurify.sanitize(equipment, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(consoleNumberClean);
        console.log(positionClean);
        console.log(notamClean);
        console.log(equipmentClean);
        console.log(descriptionClean);
    }



    return (
        <div style={{ fontFamily: "Poppins, sans-serif" }}>
            {/*  */}
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">CNS Fault Reporting</h1>
            </div>
            <form>
                <div className="row ">
                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp2" >Time Stamp</label> <br />
                        <div className="App" >
                            <InputsHi type="datetime-local" onChange={(e) => setTimeStamp(e.target.value)} id="inp2" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp3" >Console</label> <br />
                        <div className="App">
                            <InputsHi type="number" id="inp3" onChange={(e)=>setConsoleNumber(e.target.value)} placeholder="Enter Console Number..." />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold " style={{ marginLeft: "15%" }} htmlFor="inp4" >Position</label> <br />
                        <div className="App">
                            <InputsHi type="text" onChange={(e) => setPosition(e.target.value)} id="inp4" placeholder="Enter Position..." />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="inp3" className="fw-bold" style={{ marginLeft: "15%" }}  >NOTAM*</label> <br />
                        <div className="App">
                            <select id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius" onChange={(e)=>setNotam(e.target.value)} style={{ marginBottom: "4%" }}>
                                <option selected >-- Select NOTAM --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>

                </div>



                <div className="row ">
                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp2" >Equipment*</label> <br />
                        <div className="App" >
                            <InputsHi type="text" placeholder="Enter Equipment" onChange={(e)=>setEquipment(e.target.value)} id="inp2" required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp4" >Description</label> <br />
                        <div className="App">
                            <textarea id="inp4" className="px-2 mx-2 inpFoc radius" onChange={(e)=>setDescription(e.target.value)} style={{ height: "80%", marginBottom: "3%" }} type="text" placeholder="Enter Description..." />

                        </div>
                    </div>
                </div>
            </form>
            <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>

        </div>
    )
}

export default CNSFaultReporting
