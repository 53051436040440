
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'
import DOMPurify from 'dompurify';
const FullEmergency = () => {
    const [landingRWY, setLandingRWY] = useState('');
    const [aircraftType, setAircraftType] = useState('');
    const [ETA, setETA] = useState('');
    const [pob, setPob] = useState('');
    const [fuel, setFuel] = useState('');
    const [natureOfEmergency, setNatureOfEmergency] = useState('');
    const [pilotIntentions, setPilotIntentions] = useState('');


    const mysubmit = () => {
        let landingRWYClean = DOMPurify.sanitize(landingRWY, { USE_PROFILES: { html: false } });
        let aircraftTypeClean = DOMPurify.sanitize(aircraftType, { USE_PROFILES: { html: false } });
        let ETAClean = DOMPurify.sanitize(ETA, { USE_PROFILES: { html: false } });
        let pobClean = DOMPurify.sanitize(pob, { USE_PROFILES: { html: false } });
        let fuelClean = DOMPurify.sanitize(fuel, { USE_PROFILES: { html: false } });
        let natureOfEmergencyClean = DOMPurify.sanitize(natureOfEmergency, { USE_PROFILES: { html: false } });
        let pilotIntentionsClean = DOMPurify.sanitize(pilotIntentions, { USE_PROFILES: { html: false } });

        console.log(landingRWYClean);
        console.log(aircraftTypeClean);
        console.log(ETAClean);
        console.log(pobClean);
        console.log(fuelClean);
        console.log(natureOfEmergencyClean);
        console.log(pilotIntentionsClean);
    }
    return (
        <div style={{ fontFamily: "Poppins , sans-serif" }}>
            {/*  */}
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Full Emergency</h1>

            </div>

            <div className="row">

                <div class="col-md-4 " style={{ border: "2px solid red", marginLeft: "180px" }}>
                    <h4 class="text-danger py-3 px-2">ETA Less Than 10 Minutes:</h4>
                    <ul style={{ listStyle: "none", fontFamily: "Poppins" }}>
                        <li><i class="fas fa-check mx-2"></i>Press Crash Alarm.</li><br />
                        <li><i class="fas fa-check mx-2"></i>Wait for rescue vehicle response on frequency</li><br />
                        <li><i class="fas fa-check mx-2"></i>State “Full Emergency”</li>
                    </ul>
                </div>
                <div className="col-md-1"></div>
                <div class="col-md-4" style={{ border: "2px solid red" }}>
                    <h4 class="text-danger py-3 px-2">ETA More Than 10 Minutes:</h4>
                    <ul style={{ listStyle: "none", fontFamily: "Poppins" }}>
                        <li><i class="fas fa-check mx-2"></i>Press RFFS on the VCCS</li><br />
                        <li><i class="fas fa-check mx-2"></i>State “Full Emergency”</li>
                        <br /><br /><br />
                    </ul>
                </div>


            </div>
            <h4 style={{ color: "black", fontFamily: "Poppins", marginLeft: "8%" }} className="fw-bold my-5">Pass the following:</h4>
            <form onSubmit={(e)=>e.preventDefault()}>
            <div className="row ">
                <div className="col-md-6">

                    <label className="fw-bold clas5" style={{ marginLeft: "15%", width: "50%" }} htmlFor="inp2" >Landing RWY or Location on Airport</label> <br />
                    <div className="App">
                        <InputsHi type="text" id="inp2"  onChange={(e) => setLandingRWY(e.target.value)} placeholder="Landing RWY or Location on Airport" />
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="fw-bold clas5" style={{ width: "50%", marginLeft: "1.2%" }} htmlFor="inp3" >Aircraft Type</label> <br />

                    <InputsHi type="text" id="inp3"   onChange={(e) => setAircraftType(e.target.value)} placeholder="Aircraft Type (Auto Complete)" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold clas5" style={{ marginLeft: "15%", width: "50%" }} htmlFor="inp4" >ETA</label> <br />
                    <div className="App">
                        <InputsHi type="time" id="inp3"  onChange={(e) => setETA(e.target.value)} />
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="fw-bold" style={{ width: "50%", marginLeft: "1.2%" }} htmlFor="inp4" >POB (if known)</label> <br />

                    <InputsHi type="text" id="inp3"  onChange={(e) => setPob(e.target.value)} placeholder="POB (if known)" />
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold clas5" style={{ marginLeft: "15%", width: "50%" }} htmlFor="inp4" >Fuel (if known)</label> <br />
                    <div className="App">
                        <InputsHi type="text" id="inp3"  onChange={(e) => setFuel(e.target.value)} placeholder="Fuel (if known)" />
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="fw-bold" htmlFor="inp4" style={{ marginLeft: "8px" }} >Nature of Emergency/ Accident</label> <br />

                    <InputsHi type="text" id="inp3"  onChange={(e) => setNatureOfEmergency(e.target.value)} placeholder="Nature of Emergency/ Accident" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold clas5" style={{ marginLeft: "15%", width: "50%" }} htmlFor="inp4" >Pilot's Intentions</label> <br />
                    <div className="App">
                        <InputsHi type="text" id="inp3"  onChange={(e) => setPilotIntentions(e.target.value)} placeholder="Pilot's Intentions" />
                    </div>
                </div>
            </div>



            <div style={{ marginLeft: "40px", marginTop: "80px", fontFamily: 'Poppins', fontWeight: '400' }}>

                <h5 style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: "16px" }}>Pass the information above to:</h5>

                <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                        <input type="checkbox" id="tb1" name="tb1" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb1"> GMC/ADC</label><br /></li>
                    <li>
                        <input type="checkbox" id="tb2" name="tb2" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb2">ATCA</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb3" name="tb3" />
                        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb3"> DPN DL (if necessary, ask for increased spacing/ stop arrival traffic)</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb4" name="tb4" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb4">PABX HL</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb5" name="tb5" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb5"> Support Manager (use On-Call list)</label><br />

                    </li>





                </ul>



                <h5 style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: "14px" }}>If the emergency/accident is OFF the Airport, only then pass the information to:</h5>


                <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                        <input type="checkbox" id="tb6" name="tb6" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb6">Police Airwing</label><br /></li>
                    <li>
                        <input type="checkbox" id="tb7" name="tb7" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb7">Coast Guard</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb8" name="tb8" />
                        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb8">Port Room</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb9" name="tb9" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb9">Split ADC/GMC</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb10" name="tb10" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb10">Refer to IFER Manual</label><br />

                    </li>





                </ul>




                <h5 style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: "16px" }}>Request reports from:</h5><br />




                <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                        <input type="checkbox" id="tb11" name="tb11" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb11"> Met (official weather observation)</label><br /></li>
                    <li>
                        <input type="checkbox" id="tb12" name="tb12" />
                        <label style={{ width: "450px", display: "inline-block" }} className="text-dark mx-2" for="tb12">AFL Mobile (request airfield lighting status report)</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb13" name="tb13" />
                        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb13"> U/S CNS EQIP (request status report for all equipment)</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb14" name="tb14" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb14">NAVAID's</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb15" name="tb15" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb15">Met equipment</label><br />

                    </li>





                </ul>





                <h6 style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: "13px" }}> Notify the following when Full Emergency is cancelled</h6><br />


                <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                        <input type="checkbox" id="tb16" name="tb16" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb16">  GMC/ADC</label><br /></li>
                    <li>
                        <input type="checkbox" id="tb17" name="tb17" />
                        <label style={{ width: "450px", display: "inline-block" }} className="text-dark mx-2" for="tb17">ATCA</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb18" name="tb18" />
                        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb18">DPN DL (inform them of arrival spacing requirements)</label><br />
                    </li>
                    <li>
                        <input type="checkbox" id="tb19" name="tb19" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb19">Support Manager</label><br />
                    </li>

                    <li>
                        <input type="checkbox" id="tb20" name="tb20" />
                        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb20">PABX HL</label><br />

                    </li>






                </ul>



                <p style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: "13px" }}> Request a runway inspection (if applicable)</p><br /><br />
                <label for="tb26" style={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: "16px" }}>File ROSI (within 3 hours) (Ref OM 8.4.2)</label><br />

            </div>
            {/* Check Boxxes End */}
            <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
</form>
        </div>
    )
}

export default FullEmergency
