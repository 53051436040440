import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createStore , applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import {composeWithDevTools} from "redux-devtools-extension";

import rootReducer from "../src/config/reducers/rootReducer"

const store = createStore(rootReducer , composeWithDevTools());

ReactDOM.render(
  <React.StrictMode>
   <Provider store={store}>

    <App />
    
   </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
