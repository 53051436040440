import Routers from './config/routers';

function App() {
  return (

    <Routers />

  );
}

export default App;
