import React, { useState, useEffect } from 'react';
import $ from "jquery"
import logo from "../../images/logo2.png"
import { FaArrowLeft, FaUserCircle } from "react-icons/fa"
import { FaWifi } from "react-icons/fa"
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material';
import { BsWifiOff } from 'react-icons/bs';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

const Sidebars = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    function Click1() {
        $(".page-wrapper").removeClass("toggled");
    };
    function Click2() {
        $(".page-wrapper").addClass("toggled");
    };


    const [setSeconds, setnext] = useState(<FaWifi className="text-white" />)
    useEffect(() => {
        if (navigator.onLine === !true) {
            setTimeout(() => {
                setnext(<BsWifiOff className="text-white" />)
            }, 100);
        } else {
            setTimeout(() => {
                setnext(<FaWifi className="text-white" />)
            }, 100);
        }
    });



    return (
        <div>
            <div className="page-wrappers chiller-theme toggled my-5">
                <Link  to="/dashboard" style={{textDecoration:"none"}}>
                        <button id="show-sidebar" className="fs-5" style={{background:"transparent", borderRadius:"50px", border:"none"}}>
                        <FaArrowLeft className="text-white" />
                        </button>
                    </Link>
           
                   

                        <nav class="navbar navbar-expand-sm navbar-dark navbarss" style={{ background: "rgb(237, 28, 36)"   }} >
                            <div class="container-fluid ">
                                {/* <a class="navbar-brand " href="#">Navbar</a> */}
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse " id="navbarSupportedContent">
                                    <ul class="navbar-nav ms-auto">

                                        <li className="nav-item">
                                            <IconButton color="inherit" >
                                                <Badge badgeContent={2} color="success">
                                                    <EmailOutlinedIcon className="text-white" />
                                                </Badge>

                                            </IconButton>



                                        </li>
                                        <li className="nav-item">

                                            <IconButton color="inherit" >
                                                <Badge badgeContent={4} color="success">


                                                    <NotificationsIcon className="text-white" />
                                                </Badge>

                                            </IconButton>


                                        </li>


                                        <li class="nav-item">
                                            <IconButton color="inherit">

                                                <Badge color="secondary">
                                                    {
                                                        setSeconds
                                                    }
                                                </Badge>


                                            </IconButton>
                                        </li>


                                        <li class="nav-item">


{/* 
                                            <div className="hovers1 dropdown mx-5 fs-7 text-white" style={{ cursor: "pointer" }}>
                                                <FaUserCircle size={30} />
                                                <span className="span1 my-2" style={{ fontSize: "14px" }}> Omair Ali<span className="disp"> Demo Position TRAINEE </span></span>
                                                <div class="profile">
                                                    <div class="dropdown-content">
                                                        <ul>
                                                            <li> <FaUserCircle size={50} style={{ border: "2px solid red", borderRadius: "50px" }} /><span className="span1 mx-4" style={{ fontSize: "25px" }}> Omair Ali<p style={{ fontSize: "13px" }}> Demo Position <br /> --TRAINEE-- </p> </span>
                                                            </li>
                                                            <Divider />
                                                            <Link to="/dashboard/userProfile" className="text-dark">
                                                            <li className="hord" style={{ marginTop: "5px" }}><i class='bx bx-user'></i><span>My Profile</span></li>
                                                            </Link>
                                                            <Link to="/dashboard/positionRelief" className="text-dark">
                                                            <li className="hord"><i class='bx bx-cog'></i><span>Position Relief</span></li>
                                                          </Link>
                                                          <Link to="/" className="text-dark">
                                                            <li className="hord"><i class='bx bx-log-in-circle'></i><span>Logout</span></li>
                                                        </Link>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
 */}

<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
 
 {/* <Tooltip> */}

   <IconButton onClick={handleClick} size="small" sx={{ ml: 5 }}>
     <FaUserCircle style={{color:"white"}}  size={30} />

     <Typography className='text-white' style={{fontFamily:"Poppins , sans-serif"}}>&nbsp; Omair Ali</Typography>
   </IconButton>
 {/* </Tooltip> */}
</Box>
<Menu
 anchorEl={anchorEl}
 open={open}
 onClose={handleClose}
 onClick={handleClose}
 PaperProps={{
   elevation: 0,
   sx: {
     overflow: 'visible',
     filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
     width:"250px",
     mt: 1.5,
    
     '& .MuiAvatar-root': {
       width: 32,
       height: 32,
       ml: -0.5,
       mr: 1,
     },
     '&:before': {
       content: '""',
       display: 'block',
       position: 'absolute',
       top: 0,
       right: 100,
       width: 10,
       height: 10,
       bgcolor: 'background.paper',
       transform: 'translateY(-50%) rotate(45deg)',
       zIndex: 0,
     },
   },
 }}
 transformOrigin={{ horizontal: 'right', vertical: 'top' }}
 anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
>
 <MenuItem>
   {/* <Avatar />  Omair Ali */}
   <FaUserCircle size={50} style={{ border: "2px solid red", borderRadius: "50px" }} /><span className="span1 mx-4" style={{ fontSize: "25px" }}> Omair Ali<p style={{ fontSize: "13px" }}> Demo Position <br /> --TRAINEE-- </p> </span>
 </MenuItem>

 <Divider />

 <Link to="/dashboard/userProfile"  style={{textDecoration:"none"}} className='text-dark'>
 <MenuItem style={{fontFamily:"Poppins , sans-serif" , color:"#000"}}>
   {/* <ListItemIcon> */}
   <i class='bx bx-user' ></i>
     {/* <PersonAdd fontSize="small" /> */}
   {/* </ListItemIcon> */}
   <span className='mx-3'>

   My Profile
   </span>
 </MenuItem>
 </Link>
 <Link to="/dashboard/positionRelief" style={{textDecoration:"none"}} className='text-dark'>
 <MenuItem style={{fontFamily:"Poppins , sans-serif" , color:"#000"}}>
   {/* <ListItemIcon> */}
   <i class='bx bx-cog'></i>
     {/* <PersonAdd fontSize="small" /> */}
   {/* </ListItemIcon> */}
   <span className='mx-3'>

  Position Relief
   </span>
 </MenuItem>
 </Link>
 <Link to="/"  style={{textDecoration:"none"}} className='text-dark'>
 <MenuItem style={{fontFamily:"Poppins , sans-serif" , color:"#000"}}>
   {/* <ListItemIcon> */}
   <i class='bx bx-log-in-circle'></i>
        {/* <PersonAdd fontSize="small" /> */}
   {/* </ListItemIcon> */}
   <span className='mx-3'>

   Log Out
   </span>
 </MenuItem>
 </Link>
</Menu>


                                        </li>

&nbsp;   &nbsp;   &nbsp;   &nbsp;

                                    </ul>
                                </div>
                            </div>
                        </nav>





            </div>
        </div>
    )
}

export default Sidebars;

                      