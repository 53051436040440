import "../../App.css"
// import { FaArrowLeft } from "react-icons/fa";
// import logos from "../images/logo.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import InputsHi from "../LOGs/input";
import { Link } from "react-router-dom";
import Navbar from "../navbar/topnav";
import SnackBar from "../LOGs/SnackBar";
import { useState } from "react";
import DOMPurify from "dompurify";

const Special = () => {
    const [timeStamp, setTimeStamp] = useState('');
    const [subject, setSubject] = useState('');
    const [vehicle, setVehicle] = useState('');
    const [status, setStatus] = useState('');
    const [ROSI, setROSI] = useState('');
    const [description, setDescription] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });     
        let subjectClean = DOMPurify.sanitize(subject, { USE_PROFILES: { html: false } });
        let vehicleClean = DOMPurify.sanitize(vehicle, { USE_PROFILES: { html: false } });
        let statusClean = DOMPurify.sanitize(status, { USE_PROFILES: { html: false } });
        let ROSIClean = DOMPurify.sanitize(ROSI, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(subjectClean);
        console.log(vehicleClean);
        console.log(statusClean);
        console.log(ROSIClean);
        console.log(descriptionClean);
    }

    return (
        <div className="font back7">
            
            {/* <Navbar/> */}
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Unusual Entry</h1>
            </div>

<form onSubmit={(e)=>e.preventDefault()}>
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Time Stamp</label> <br />
              <div className="App">
                <InputsHi type="datetime-local" onChange={(e)=>setTimeStamp(e.target.value)} id="inp2" />
                </div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}  >Subject*</label> <br />
                <div className="App">
                <InputsHi type="text" onChange={(e)=>setSubject(e.target.value)} id="inp3" />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}}>Vehicle</label> <br />
                <div className="App">
                <InputsHi type="text" id="inp4" onChange={(e)=>setVehicle(e.target.value)} placeholder="Enter Vehicle" />
                </div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp5" className="fw-bold" style={{marginLeft:"15%"}}>Status</label> <br />
                <div className="App">
                <select onChange={(e)=>setStatus(e.target.value)} id="inp5" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >Select Status</option>
    <option value="Open">Open</option>
    <option value="Close">Close</option>
  </select>        

                </div>
                </div>
                
            </div>
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}}>ROSI</label> <br />
                <div className="App">
                <InputsHi type="text" onChange={(e)=>setROSI(e.target.value)} id="inp4" placeholder="Enter ROSI" />
                </div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}} >Description</label> <br />
                    <div className="App">
                    <textarea id="inp4" onChange={(e)=>setDescription(e.target.value)} placeholder="Enter Description..." className="px-2 mx-2 inpFoc radius"  type="text" />
            
                </div>
                </div>

                
            </div>
           
            <div style={{paddingBottom:"3%", marginTop:"3%"}}>
                <SnackBar />
            <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
            </div>
</form>

        </div>
    )
}
export default Special;