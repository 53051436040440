import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import { FaEdit , FaTrash } from 'react-icons/fa';
import InputsHi from '../../LOGs/input';
import SnackBar from '../../LOGs/SnackBar';

const ViewSubject = () => {
    const [keyword, setKeyword] = useState('');
    const [status , setStatus] = useState([
		{
			 subject : "testing",
             subjectPiority: "demo low",
             subjectType: "Special",
			active: "yes",
		},
		{
            subject : "testing",
            subjectPiority: "demo low",
            subjectType: "Special",
           active: "yes",

		},
		{
            subject : "testing",
            subjectPiority: "demo low",
            subjectType: "Special",
           active: "yes",

		},
		{
            subject : "testing",
            subjectPiority: "demo low",
            subjectType: "Special",
           active: "yes",

		},
	
	])

  
    const mysubmit = () => {
        let keywordClean = DOMPurify.sanitize(keyword, { USE_PROFILES: { html: false } });
   
        console.log(keywordClean);
     
    }
    const [edit , setEdit] = useState(true)
	const [details , setDetails] = useState(true)
   
        return (
            <div className="container" style={{fontFamily:"Poppins , sans-serif"}}>
           
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">View  Subject View</h1>
            </div>

            <form onSubmit={(e)=>e.preventDefault()}> 
            <div className="row">
            <div className="col-md-6">
                
              
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp4" >Search By Type:</label> <br />
<div className="App">
                    

<select  onChange={(e)=>setKeyword(e.target.value)} id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
<option selected>-- Select Subject Type--</option>
                                <option value="Special Subjects">Special Subjects</option>
                        
                                <option value="General Subjects">General Subjects</option>
                              
  </select> 



                </div>
                </div>
                
                
                
            </div>
            
              
    
    
                <br/>
                <br/>
            
    <SnackBar />
       <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
    
    </form>
<br />
<br />
<br />
<br />
<br />


    <form style={{display:"flex"}}>	
    <h4 className='fw-bold'> Subject View:</h4>
        <button class="pull-left btn btn-danger mx-4" style={{height:"40px"}}>Create New</button>
        <br/>   
        
    </form>		
    <hr />	
        <br/><br/>
    <div class="table-scrollable text-center">
        <table class="table table-bordered table-hover">
            <thead>
                <tr>
                    <th scope="col" style={{width:"50px"}}>
                    Subject   
                    </th>
                    <th scope="col" style={{width:"150px"}}>
                    Subject Priority	                    </th>
                    <th scope="col" style={{width:"50px"}}>
                    Subject Type                  </th>
               
                    
                    <th scope="col" style={{width:"50px"}}>
                    Active
                    </th>
                    <th scope="col" style={{width:"50px"}}>
                        Action
                    </th>
                    
                </tr>
            </thead>
            <tbody>
                {status.map((e,i)=>{
                    return(
                        <tr key={i}>
                        <td style={{fontSize:"15px"}}>
                            {e.subject}								</td>
                        <td style={{fontSize:"15px" , display:"flex" }} >
                            <div style={{width:"12px" , height:"12px" , backgroundColor:"yellowgreen" , borderRadius:"50px" , marginTop:"5px"}}></div>
                           <p className='mx-2'>{e.subjectPiority}</p> 
                        </td>
                        <td style={{fontSize:"15px"}}>
                            {e.subjectType}
                        </td>
                        <td style={{fontSize:"15px"}}> 
                            {e.active}
                        </td>
                        <td>
                            
                        <div className="row text-center">
<div className="col-md-3"></div>
<div className="col-md-3">
                                {/* <Link to="/dashboard/tabst"> */}
                                <button class="my-2" style={{fontSize:"20px" , border:"none"}}><FaEdit style={ edit ? { color:'black'} : {color : 'gray'} } onClick={()=> {(edit === true)?setEdit(false): setEdit(true)}} className="my-2"   /></button>
                                {/* </Link> */}
                                </div>
                                <div className="col-md-3">

                                <button class="my-2" style={{fontSize:"20px" , border:"none"}}>
                                    <FaTrash style={ details ? { color:'black'} : {color : 'gray'} } onClick={()=> {(details === true)?setDetails(false): setDetails(true)}} className="my-2" /></button>
                            </div>
                            
                            </div>


                        </td>
                        
                    </tr>
                    )
                })}
                                        
                                        
                                        </tbody>
        </table>
    </div>
    
</div>





      
    )
}

export default ViewSubject;
