import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';

const IncidentReport = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [emergency, setEmergency] = useState('');
    const [subject, setSubject] = useState('');


    const mysubmit = () => {
        let fromDateClean = DOMPurify.sanitize(fromDate, { USE_PROFILES: { html: false } });
        let toDateClean = DOMPurify.sanitize(toDate, { USE_PROFILES: { html: false } });
        let emergencyClean = DOMPurify.sanitize(emergency, { USE_PROFILES: { html: false } });
        let subjectClean = DOMPurify.sanitize(subject, { USE_PROFILES: { html: false } });

        console.log(fromDateClean);
        console.log(toDateClean);
        console.log(emergencyClean);
        console.log(subjectClean);
    }
    return (
        <div style={{ fontFamily: "Poppins , sans-serif" }}>
            {/*  */}
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Incident Report</h1>
            </div>
            <form  onSubmit={(e)=>e.preventDefault()}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp2">From Date</label> <br />
                        <div className="App">

                            <InputsHi type="date"  onChange={(e)=>setFromDate(e.target.value)} id="inp2" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp2" >To Date</label> <br />
                        <div className="App">
                            <InputsHi type="date"  onChange={(e)=>setToDate(e.target.value)} id="inp2" />
                        </div>
                    </div>
                   </div>


                   <div className="row">
            <div className="col-md-6">
                
              
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp4" >Subject</label> <br />
<div className="App">
                    

<select id="inp3"  onChange={(e)=>setEmergency(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>

<option selected>-- Select Emergency --</option>
                                <option value="Full Emergency">Full Emergency</option>
                                <option value="Aircraft Ground Incident">Aircraft Ground Incident</option>
                                <option value="Local Standby">Local Standby</option>
                                <option value="Aircraft Crash">Aircraft Crash</option>
                                <option value="Bomb Warning">Bomb Warning</option>
                                <option value="Medical Emergency">Medical Emergency</option>
                                <option value="Drone UAS">Drone/ UAS</option>
                                <option value="Laser Incident">Laser Incident</option>
                                <option value="Unlawful Interference">Hijack/Unlawful Interference</option>
                                <option value="Evacuation">Evacuation</option>
                                <option value="Weather Standby">Weather Standby</option>
  </select> 



                </div>
                </div>
            <div className="col-md-6">
                
              
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp4" >Subject</label> <br />
<div className="App">
                    

<select  onChange={(e)=>setSubject(e.target.value)} id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
<option selected>-- Select Subject --</option>
<option value="Test">Test</option>
                                <option value="Demo Subject">Demo Subject</option>
                                <option value="Demo">Demo</option>
                        
                                <option value="Test Subject">Test Subject</option>
                                <option value="Demo_Subject"> Demo_Subject</option>
                                <option value="Subject"> Subject</option>
                                <option value="Test"> Test</option>
                                <option value="Test123">Test123</option>
                                <option value="Demo">Demo</option>
                                <option value="Subject">Subject</option>

  </select> 



                </div>
                </div>
                </div>
                          
                                
                          


                          
                             
                           


                <SnackBar />
                <button  onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{ backgroundColor: "#FB6A6A", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "8%", float: "right" }}>Cancel</button>
            </form>

        </div>
    )
}

export default IncidentReport;
