import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import Aircraft from "../components/aircraftDiversion/aircraft";

import Login from "../components/login/login"
import Special from "../components/specialEntry/special";
import ReportableDelays from "../components/generalEntry/reportabledelays";
import Routine from "../components/generalEntry/routine";
import Pilotsreports from "../components/generalEntry/pilotsreports";
import FMFaultReporting from "../components/FaultReporting/fmFaultReporting";
import CNSFaultReporting from "../components/FaultReporting/cnsFaultReporting";
import LVO from "../components/lvo/lvo";
import Movement from "../components/totalMovement/movement";
import DailyChecklist from "../components/dailyChecklist/dailyChecklist";
import AccessLogs from "../components/accessLogs/accessLogs";
import StaffEntry from "../components/staff/staffEntry";
import RWYInspection from "../components/Inspection/rwyInspection";
import OtherInspection from "../components/Inspection/otherInsp";
import Rostering from "../components/staff/rostering";
import AircraftAcci from "../components/Emergency/aircraftAcci";
import RunwayChange from "../components/Runway/runwayChange";
import ElogFault from "../components/FaultReporting/elogFault";
import NonDutyRunwayOperations from "../components/Runway/nonDutyRunwayOperations";
import OperationalCondition from "../components/conditions/operationalCondition";
import Memo from "../components/document/memo";
import Si from "../components/document/si";
import Ti from "../components/document/ti";
import OpsManual from "../components/document/opsManual";
import DemoInstruction from "../components/document/demoInstruction";
import BombWarning from "../components/Emergency/bombWarning";
import TrainingHours from "../components/Training/trainingHours";
import TrainingReport from "../components/Training/trainingReport";
import TrainingSession from "../components/Training/trainingSession";
import HijackUnlawfulInterference from "../components/Emergency/hijackUnlawfulInterference";
import GroundIncident from "../components/Emergency/groundIncident";
import LaserIncident from "../components/Emergency/laserIncident";
import LocalStandby from "../components/Emergency/localStandby";
import GeneralMedicalEmergency from "../components/Emergency/generalMedicalEmergency";
import SarPhaseDeclaration from "../components/Emergency/sarPhaseDeclaration";
import METCondition from "../components/conditions/metCondition";
import FullEmergency from "../components/Emergency/fullEmergency";
import Sidebars from "../components/sidebar/sidebars";
import CreateFeedback from "../components/Feedback/createFeedback";
import ManageFeedback from "../components/Feedback/manageFeedback";
import PositionLogSummaryRep from "../components/Reports/positionLogSummaryRep";
import PositionLogReport from "../components/Reports/positionLogReport";
import BasicTabs from "../components/UserManagment/webapp";
import Status from "../components/ConfigurationSetup/status";
import AirCraftReport from "../components/Reports/airCraftReport";
import DailyReport from "../components/Reports/dailyReport";
import FaultReport from "../components/Reports/faultReport";
import IncidentReport from "../components/Reports/incidentReport";
import SupervisorReport from "../components/Reports/supervisorReport";
import TotalMovementReport from "../components/Reports/totalMovementReport";
import InstructionReport from "../components/Reports/instructionReport";
import AttendanceReport from "../components/Reports/attendanceReport";
import SubjectForm from "../components/ConfigurationSetup/subjectForm";
import PurposeForm from "../components/ConfigurationSetup/purposeForm";
import SubjectPriorityView from "../components/ConfigurationSetup/subjectPriorityView";
import PositionName from "../components/ConfigurationSetup/positionName";
import EquipmentName from "../components/ConfigurationSetup/equipmentName";
import ConsoleNumber from "../components/ConfigurationSetup/consoleNumber";
import Vehicles from "../components/ConfigurationSetup/vehicles";
import ReleasePurpose from "../components/ConfigurationSetup/releasePurpose";
import AircraftType from "../components/ConfigurationSetup/aircraftType";
import Airport from "../components/ConfigurationSetup/airport";
import Shift from "../components/ConfigurationSetup/shift";
import StaffReason from "../components/ConfigurationSetup/staffReason";
import ManoeuvringArea from "../components/ConfigurationSetup/manoeuvringArea";
import Nationality from "../components/ConfigurationSetup/nationality";
import Destination from "../components/ConfigurationSetup/destination";
import Department from "../components/ConfigurationSetup/department";
import Company from "../components/ConfigurationSetup/company";
import InstructionType from "../components/ConfigurationSetup/instructionType";
import InspectionType from "../components/ConfigurationSetup/inspectionType";
import TypeOfIncident from "../components/ConfigurationSetup/typeOfIncident";
import DangerousGoods from "../components/ConfigurationSetup/dangerousGoods";
import NotificationList from "../components/ConfigurationSetup/notificationList";
import Runway from "../components/ConfigurationSetup/runway";
import Authority from "../components/ConfigurationSetup/authority";
import Designation from "../components/ConfigurationSetup/designation";
import AddUser from "../components/UserManagment/addUser";
import Users from "../components/UserManagment/users";
import UserRoles from "../components/UserManagment/userRoles";
import GeneralReport from "../components/Reports/generalReport";
import ManagementReport from "../components/Reports/managementReport";
import EditUser from "../components/UserManagment/editUser";
import Tabst from "../components/UserManagment/Tabs/tabst";
import UserProfile from "../components/UserManagment/userProfile/userProfile";
import Elog from "../components/elog/elog";
import addInstruction from "../components/document/addInstruction";
import ErrorPg from "../components/errorPg/errorPg";
import PositionRelief from "../components/LOGs/positionRelief";
import Scroll from "../components/sidebar/scroll"
import ViewAuthority from "../components/ConfigurationSetup/views/viewAuthority";
import ViewRunway from "../components/ConfigurationSetup/views/viewRunway";
import ViewEmail from "../components/ConfigurationSetup/views/viewEmail";
import ViewDangerousGoods from "../components/ConfigurationSetup/views/viewDangerousGoods";
import ViewTypeOfIncident from "../components/ConfigurationSetup/views/viewTypeOfIncident";
import ViewInspectionType from "../components/ConfigurationSetup/views/viewInspectionTypes";
import ViewInstructionType from "../components/ConfigurationSetup/views/viewInstructionType";
import ViewCompany from "../components/ConfigurationSetup/views/viewCompany";
import ViewDepartment from "../components/ConfigurationSetup/views/viewDepartment";
import ViewDesignation from "../components/ConfigurationSetup/views/viewDesignation";
import ViewDestination from "../components/ConfigurationSetup/views/viewDestination";
import ViewNationality from "../components/ConfigurationSetup/views/viewNationality";
import ViewRunwayAreas from "../components/ConfigurationSetup/views/viewRunwayAreas";
import ViewStaffReason from "../components/ConfigurationSetup/views/viewStaffReason";
import ViewShift from "../components/ConfigurationSetup/views/viewShift";
import ViewAirport from "../components/ConfigurationSetup/views/viewAirport";
import ViewAircraftType from "../components/ConfigurationSetup/views/viewAircraftType";
import ViewReleasePurpose from "../components/ConfigurationSetup/views/viewReleasePurpose";
import ViewVehicle from "../components/ConfigurationSetup/views/viewVehicle";
import ViewConsoleNumber from "../components/ConfigurationSetup/views/viewConsoleNumber";
import ViewEquipmentName from "../components/ConfigurationSetup/views/viewEquipmentName";
import ViewPositionList from "../components/ConfigurationSetup/views/viewPositionList";
import ViewPurposeView from "../components/ConfigurationSetup/views/purposeView";
import ViewSubjectView from "../components/ConfigurationSetup/views/viewSubjectView";
import ViewSubject from "../components/ConfigurationSetup/views/viewSubjectView";

export default function Routers() {
  return (
    <Router>
      <div>

        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/dashboard" component={Sidebars} />

{/* General Entry Start */}

          <Route exact path="/dashboard/generalEntry/routineEntry" component={Routine} />
          <Route exact path="/dashboard/generalEntry/reportableDelays" component={ReportableDelays} />
          <Route exact path="/dashboard/generalEntry/pilotReports" component={Pilotsreports} />

{/* General Entry End */}

{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Fault Reporting Start */}


<Route exact path="/dashboard/faultReporting/cnsFaultReporting" component={CNSFaultReporting} />
<Route exact path="/dashboard/faultReporting/fmFaultReporting" component={FMFaultReporting} />
<Route exact path="/dashboard/faultReporting/elogFaultReporting" component={ElogFault} />



{/* Fault Reporting End */}

{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Runway Change Start */}


<Route exact path="/dashboard/runwayChange/runwayForm" component={RunwayChange} />
<Route exact path="/dashboard/runwayChange/runwayOperations" component={NonDutyRunwayOperations} />




{/* Runway Change End */}


{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Inspection Entry Start */}


<Route exact path="/dashboard/inspectionEntry/rwyInspection" component={RWYInspection} />
<Route exact path="/dashboard/inspectionEntry/otherInspection" component={OtherInspection} />




{/* Inspection Entry End */}


{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* staffEntry Entry Start */}

<Route exact path="/dashboard/staff/staffEntry" component={StaffEntry} />
<Route exact path="/dashboard/staff/rostering" component={Rostering} />


{/* staffEntry Entry End */}



{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Unusual Entry Start */}

<Route exact path="/dashboard/specialentry" component={Special} />

{/* Unusual Entry End */}


{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Total Movement Start */}

<Route exact path="/dashboard/totalMovement" component={Movement} />

{/* Total Movement End */}


{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Aircraft Start */}

<Route exact path="/dashboard/aircraft" component={Aircraft} />

{/* Aircraft End */}


{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* LVO Start */}

<Route exact path="/dashboard/lvo" component={LVO} />

{/* LVO End */}


{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Emergency Checklist Start */}

<Route exact path="/dashboard/emergencyChecklist/aircraftAccident" component={AircraftAcci} />
<Route exact path="/dashboard/emergencyChecklist/bombWarning" component={BombWarning} />
<Route exact path="/dashboard/emergencyChecklist/hijackUnLawfulInterference" component={HijackUnlawfulInterference} />
<Route exact path="/dashboard/emergencyChecklist/fullEmergency" component={FullEmergency} />
<Route exact path="/dashboard/emergencyChecklist/groundIncident" component={GroundIncident} />
<Route exact path="/dashboard/emergencyChecklist/laserIncident" component={LaserIncident} />
<Route exact path="/dashboard/emergencyChecklist/localStandby" component={LocalStandby} />
<Route exact path="/dashboard/emergencyChecklist/medicalEmergency" component={GeneralMedicalEmergency} />
<Route exact path="/dashboard/emergencyChecklist/sarPhaseDeclaration" component={SarPhaseDeclaration} />


{/* Emergency Checklist End */}

{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Conditions Start */}

<Route exact path="/dashboard/conditions/metCondition" component={METCondition} />
<Route exact path="/dashboard/conditions/operationalCondition" component={OperationalCondition} />


{/* Conditions End */}

{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Feedback Start */}

<Route exact path="/dashboard/feedback/createFeedback" component={CreateFeedback} />
<Route exact path="/dashboard/feedback/manageFeedback" component={ManageFeedback} />


{/* Feedback End */}

{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Training Session Start */}

<Route exact path="/dashboard/trainingSession/trainingHours" component={TrainingHours} />
          <Route exact path="/dashboard/trainingSession/trainingReport" component={TrainingReport} />
          <Route exact path="/dashboard/trainingSession/trainingSessionForm" component={TrainingSession} />

{/* Training Session End */}

{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Document / Instruction Start */}

<Route exact path="/dashboard/instruction/si" component={Si} />
<Route exact path="/dashboard/instruction/ti" component={Ti} />
<Route exact path="/dashboard/instruction/memo" component={Memo} />
<Route exact path="/dashboard/instruction/opsManual" component={OpsManual} />
<Route exact path="/dashboard/instruction/demoInstruction" component={DemoInstruction} />


{/* Document / Instruction End */}

{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* Reports Start */}

<Route exact path="/dashboard/reports/positionLogReport" component={PositionLogReport} />
<Route exact path="/dashboard/reports/positionLogSummaryReport" component={PositionLogSummaryRep} />
<Route exact path="/dashboard/reports/dailyReport" component={DailyReport} />
<Route exact path="/dashboard/reports/supervisorReport" component={SupervisorReport} />
<Route exact path="/dashboard/reports/faultReport" component={FaultReport} />
<Route exact path="/dashboard/reports/totalMovementReport" component={TotalMovementReport} />
<Route exact path="/dashboard/reports/instructionReport" component={InstructionReport} />
<Route exact path="/dashboard/reports/incidentReport" component={IncidentReport} />
<Route exact path="/dashboard/reports/attendanceReport" component={AttendanceReport} />
<Route exact path="/dashboard/reports/airCraftReport" component={AirCraftReport} />
<Route exact path="/dashboard/reports/generalReport" component={GeneralReport} />
<Route exact path="/dashboard/reports/managementReport" component={ManagementReport} />


{/* Reports End */}

{/* ---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_---_--- */}

{/* User Management Start */}

<Route exact path="/dashboard/userManagement/webAppPermissions" component={BasicTabs} />
<Route exact path="/dashboard/userManagement/addInstruction" component={addInstruction} />
<Route exact path="/dashboard/UserManagment/addUser" component={AddUser} />
<Route exact path="/dashboard/UserManagment/users" component={Users} />
<Route exact path="/dashboard/UserManagment/userRoles" component={UserRoles} />
<Route exact path="/dashboard/UserManagment/editUser" component={EditUser} />

{/* User Management End */}


{/* Configuration Setup Start */}

<Route exact path="/dashboard/ConfigurationSetup/status" component={Status} />
<Route exact path="/dashboard/ConfigurationSetup/subjectForm" component={SubjectForm} />
<Route exact path="/dashboard/ConfigurationSetup/purposeForm" component={PurposeForm} />
<Route exact path="/dashboard/ConfigurationSetup/subjectPriorityView" component={SubjectPriorityView} />
<Route exact path="/dashboard/ConfigurationSetup/positionName" component={PositionName} />
<Route exact path="/dashboard/ConfigurationSetup/equipmentName" component={EquipmentName} />
<Route exact path="/dashboard/ConfigurationSetup/consoleNumber" component={ConsoleNumber} />
<Route exact path="/dashboard/ConfigurationSetup/vehicles" component={Vehicles} />
<Route exact path="/dashboard/ConfigurationSetup/releasePurpose" component={ReleasePurpose} />
<Route exact path="/dashboard/ConfigurationSetup/aircraftType" component={AircraftType} />
<Route exact path="/dashboard/ConfigurationSetup/airport" component={Airport} />
<Route exact path="/dashboard/ConfigurationSetup/shift" component={Shift} />
<Route exact path="/dashboard/ConfigurationSetup/staffReason" component={StaffReason} />
<Route exact path="/dashboard/ConfigurationSetup/manoeuvringArea" component={ManoeuvringArea} />
<Route exact path="/dashboard/ConfigurationSetup/nationality" component={Nationality} />
<Route exact path="/dashboard/ConfigurationSetup/destination" component={Destination} />
<Route exact path="/dashboard/ConfigurationSetup/designation" component={Designation} />
<Route exact path="/dashboard/ConfigurationSetup/department" component={Department} />
<Route exact path="/dashboard/ConfigurationSetup/company" component={Company} />
<Route exact path="/dashboard/ConfigurationSetup/instructionType" component={InstructionType} />
<Route exact path="/dashboard/ConfigurationSetup/inspectionType" component={InspectionType} />
<Route exact path="/dashboard/ConfigurationSetup/typeOfIncident" component={TypeOfIncident} />
<Route exact path="/dashboard/ConfigurationSetup/dangerousGoods" component={DangerousGoods} />
<Route exact path="/dashboard/ConfigurationSetup/notificationList" component={NotificationList} />
<Route exact path="/dashboard/ConfigurationSetup/runway" component={Runway} />
<Route exact path="/dashboard/ConfigurationSetup/authority" component={Authority} />

<Route exact path="/dashboard/ConfigurationSetup/viewAuthority" component={ViewAuthority} />

{/* Configuration Setup End */}

{/* Access Logs Start */}

<Route exact path="/dashboard/accessLogs" component={AccessLogs} />


{/* Access Logs End */}




          <Route exact path="/dashboard/dailyChecklist" component={DailyChecklist} />









          <Route exact path="/dashboard/positionRelief" component={PositionRelief} />

          <Route exact path="/dashboard/userProfile" component={UserProfile} />

          <Route exact path="/dashboard/elog" component={Elog} />

          <Route exact path="/dashboard/tabst" component={Tabst} />
          <Route exact path="/dashboard/view" component={ViewSubject} />

         
          













<Route exact path="**" component={ErrorPg} /> 

        </Switch>
      </div>
    </Router>
  );
}