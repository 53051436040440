import React from 'react'
import { FaEdit } from 'react-icons/fa';
import InputsHi from '../LOGs/input';

const SubjectPriorityView = () => {
    return (
        <div className="font back7">
            
			            <div className="App">
                <h1 style={{ color: "#505050", fontFamily:"Poppins",fontSize:"45px" }} className="fw-bold my-5">Subject Priority View</h1>
            </div><br/><br/>

            <div class="col-md-12 container">
		<div class="portlet light">
			<div class="portlet-title">
			</div>
			<div class="portlet-body text-center">
			<form action="#" accept-charset="utf-8" method="post">					
            <input type="hidden" name="faultreports" value="" autocomplete="off"/>
					<span class="description-col my-2" style={{width: "10%!important", fontWeight: "bold", height: "auto", margin: "0!important", padding: "0!important", marginTop: "1%!important", marginLeft: "1%!important", float:"left"}}>Order By: </span>
					
				</form>				
				<div class="table-scrollable" >
					<table class="table table-striped table-bordered table-hover">
						<thead>
							<tr>
								<th scope="col">
									Priority
								</th>
								<th scope="col">
									Color
								</th>
								<th scope="col">
									Active
								</th>
								<th scope="col">
									Action
								</th>
								
							</tr>
						</thead>
						<tbody>
														<tr>
								<td style={{fontSize:"15px"}}>
									Demo low								</td>
							   <td>
                               <div style={{backgroundColor:"#000000", height:"20px"}} className="mx-5"> </div>
                               </td>
								<td>
									Yes								</td>
								<td className="text-center">
								<button className="px-4 py-1 mx-5" data-bs-target="#exampleModal" data-bs-toggle="modal" style={{border:"none" , fontSize:"18px"}} ><FaEdit /></button>
								
								
								
								
								
								
								
								


<div class="modal fade my-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit:</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body row">
	  <div className="col-md-9">
                   <label className="fw-bold" style={{marginRight:"60%"}}  htmlFor="inp2" >Subject</label> <br />
                   <div className="App">
				   <input className="px-2 py-2 mx-2 inpFoc radius"  v-model="color" style={{marginBottom:"3%" , height:"33px" , borderRadius:"5px"}} type="color" />

            </div>
            </div>

			<div>




			<ul style={{
                    listStyleType: "none", width: "350px",
                    display: "inline-block" , marginRight:"60%"
                }}>
                   
                   
                    <li className="my-1">
                    <input type="checkbox" id="tb5"name="tb5" />
        <label style={{ width: "50px", display: "inline-block" , marginTop:"50px" }} className="text-dark mx-2 fw-bold my-1" for="tb5">Active</label><br/></li>
                   
                   
                   
                </ul>
			</div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger rounded-pill mx-3" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary rounded-pill">Save changes</button>
      </div>
    </div>
  </div>
</div>
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								
								</td>
								
							</tr>
													</tbody>
					</table>
				</div>
				
			</div>
		</div>
	</div>










          </div>


    )
}

export default SubjectPriorityView;
