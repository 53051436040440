import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';

const AddInstruction = () => {


    const [title, setTitle] = useState('');
    const [instructionType, setInstructionType] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [effectiveDate, setEffectiveDate] = useState('');
    const [expiryData, setExpiryData] = useState('');
    const [files, setFiles] = useState('');
    const [issueToDesignation, setIssueToDesignation] = useState('');
    const [issueToIndividual, setIssueToIndividual] = useState('');
    const [details, setDetails] = useState('');


    const mysubmit = () => {
        let titleClean = DOMPurify.sanitize(title, { USE_PROFILES: { html: false } });     
        let instructionTypeClean = DOMPurify.sanitize(instructionType, { USE_PROFILES: { html: false } });
        let documentNumberClean = DOMPurify.sanitize(documentNumber, { USE_PROFILES: { html: false } });
        let effectiveDateClean = DOMPurify.sanitize(effectiveDate, { USE_PROFILES: { html: false } });
        let expiryDataClean = DOMPurify.sanitize(expiryData, { USE_PROFILES: { html: false } });
        let issueToDesignationClean = DOMPurify.sanitize(issueToDesignation, { USE_PROFILES: { html: false } });
        let issueToIndividualClean = DOMPurify.sanitize(issueToIndividual, { USE_PROFILES: { html: false } });
        let detailsClean = DOMPurify.sanitize(details, { USE_PROFILES: { html: false } });

        console.log(titleClean);
        console.log(instructionTypeClean);
        console.log(documentNumberClean);
        console.log(effectiveDateClean);
        console.log(expiryDataClean);
        console.log(files);
        console.log(issueToDesignationClean);
        console.log(issueToIndividualClean);
        console.log(detailsClean);
    }

    return (
        <>
         <div className="font back7">
    {/*  */}
 
        <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Add Instruction</h1>
        </div>
<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">

         
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}}  >Title:</label> <br />
          <div className="App">
            <InputsHi type="text" onChange={(e)=>setTitle(e.target.value)} id="inp2" placeholder="Enter Title" />
            </div>
            </div>
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Instruction Type:</label> <br />
            <div className="App">
            <InputsHi type="text" id="inp2" onChange={(e)=>setInstructionType(e.target.value)} placeholder="Enter Instruction Type" />
            </div>
            </div>
        </div>
        <div className="row">
        <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Document Number:</label> <br />
            <div className="App">
            <InputsHi type="number" id="inp2" onChange={(e)=>setDocumentNumber(e.target.value)} placeholder="Enter Document Number" />
            </div>
            </div>
       
            <div className="col-md-6">
            <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}} >Effective Date:</label> <br />
            <div className="App">
            <InputsHi type="date" onChange={(e)=>setEffectiveDate(e.target.value)} id="inp4"  />
            </div>
            </div>
             <br />
            </div>
            <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}} >Expiry Date:</label> <br />
            <div className="App">
            <InputsHi type="date" onChange={(e)=>setExpiryData(e.target.value)} id="inp4" />
            </div>
            </div>
            
            <div class="file col-md-6">
            <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}} >Files Upload:</label> <br />

                <div className="App">
<input type="file" id="fle" name="files[]" onChange={(e)=>setFiles(e.target.files[0])} class="multiple-inst-file" accept=".pdf,.PDF" multiple="multiple" autocomplete="off" />  <a href="#" class="clean btn glyphicon glyphicon-ban-circle" ></a>
</div>
</div>

          



            
        </div>

            <div className="row">
           

            <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Issue To Designation</label> <br />
           <div className="App">
            <select id="inp3" onChange={(e)=>setIssueToDesignation(e.target.value)} className="px-3 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >-- Select Designation  --</option>
            <option value="ATCO">ATCO</option>
            <option value="ATCA">ATCA</option>
            
            </select> 
                </div>
                </div>



                   
            <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Issue To Individual</label> <br />
           <div className="App">
            <select id="inp3" onChange={(e)=>setIssueToIndividual(e.target.value)} className="px-3 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >-- Select Individual  --</option>
            <option value="ATCO">ATCO</option>
            <option value="ATCA">ATCA</option>
            
            </select> 
                </div>
        </div>

            <div className="row">
           
        
             <div className="col-md-6"  >

            <label htmlFor="inp3" className="fw-bold"  style={{marginLeft:"15%"}}   >Details</label> <br />
			<div className="App">
             <textarea id="inp4" className="px-2 py- mx-2 inpFoc radius" onChange={(e)=>setDetails(e.target.value)} style={{marginBottom:"3%" }} type="text" placeholder="Enter Details ..." />
        </div>
            </div>
                </div>
             
      
            
        </div>
    


        <div style={{paddingBottom:"1%", marginTop:"3 %"}}>
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
        </div>
</form>
    </div>
        </>
    )
}

export default AddInstruction;
