import React , {useState} from 'react'
import {FaEdit} from "react-icons/fa"
import InfoIcon from '@mui/icons-material/Info';

const Status = () => {

	const [status , setStatus] = useState([
		{
			status : "ON",
			color: "#000000"
		},
		{
			status : "demo status",
			color: "#000000"
		},
		{
			status : "Close",
			color: "#000000"
		},
		{
			status : "Open",
			color: "#000000"
		},
	])

	const [edit , setEdit] = useState(true)
	const [details , setDetails] = useState(true)
    return (
        <div className="font back7">
            

            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Status View</h1>
            </div><br/><br/>
            <div class="portlet-body container" style={{width:"70%"}}>

			<form>	
					<button class="pull-right btn btn-danger">Create New</button>
					<br/><br/>
					<br/>
					
				</form>				
				<div class="table-scrollable text-center">
					<table class="table table-striped table-bordered table-hover">
						<thead>
							<tr>
								<th scope="col">
									Status
								</th>
								<th scope="col" colSpan={3}>
									Color
								</th>
								
								<th scope="col">
									Action
								</th>
								
							</tr>
						</thead>
						<tbody>
							{status.map((e,i)=>{
								return(
									<tr key={i}>
									<td style={{fontSize:"15px"}}>
										{e.status}								</td>
									<td colSpan={3}>
									   <div style={{backgroundColor:"#B10000", border:"1px solid #B10000", height:"15px"}} className="my-3 mx-4"> </div> 
									</td>
									<td>
										
									<div className="row text-center">
	<div className="col-md-3"></div>
	<div className="col-md-3">
											{/* <Link to="/dashboard/tabst"> */}
											<button class="my-2" style={{fontSize:"20px" , border:"none"}}><FaEdit style={ edit ? { color:'black'} : {color : 'gray'} } onClick={()=> {(edit === true)?setEdit(false): setEdit(true)}} className="my-2"   /></button>
											{/* </Link> */}
											</div>
											<div className="col-md-3">
	
											<button class="my-2" style={{fontSize:"20px" , border:"none"}}><InfoIcon style={ details ? { color:'black'} : {color : 'gray'} } onClick={()=> {(details === true)?setDetails(false): setDetails(true)}} className="my-2" /></button>
										</div>
										
										</div>
	
	
									</td>
									
								</tr>
								)
							})}
													
													
													</tbody>
					</table>
				</div>
				
			</div>

        </div>
    )
}

export default Status;
