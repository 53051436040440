import "../../App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import InputsHi from "../LOGs/input";
import Navbar from "../navbar/topnav";
import SnackBar from "../LOGs/SnackBar";
import DOMPurify from "dompurify";
import { useState } from "react";

const Movement = () => {
    const [timeStamp, setTimeStamp] = useState('');
    const [VFRCount, setVFRCount] = useState('');
    const [IFRCount, setIFRCount] = useState('');
    const [TotalRunwayCount, setTotalRunwayCount] = useState('');
    const [overflightsCount, setOverflightsCount] = useState('');
    const [totalMovement, setTotalMovement] = useState('');
    const [remarks, setRemarks] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });
        let VFRCountClean = DOMPurify.sanitize(VFRCount, { USE_PROFILES: { html: false } });
        let IFRCountClean = DOMPurify.sanitize(IFRCount, { USE_PROFILES: { html: false } });
        let TotalRunwayCountClean = DOMPurify.sanitize(TotalRunwayCount, { USE_PROFILES: { html: false } });
        let overflightsCountClean = DOMPurify.sanitize(overflightsCount, { USE_PROFILES: { html: false } });
        let totalMovementClean = DOMPurify.sanitize(totalMovement, { USE_PROFILES: { html: false } });
        let remarksClean = DOMPurify.sanitize(remarks, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(VFRCountClean);
        console.log(IFRCountClean);
        console.log(TotalRunwayCountClean);
        console.log(overflightsCountClean);
        console.log(totalMovementClean);
        console.log(remarksClean);
    }

    return (
        <div className="font back7">
            
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Total Movement</h1>
            </div>

<form onSubmit={(e)=>e.preventDefault()}>
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp2" className="fw-bold"  style={{marginLeft:"15%"}}>Time Stamp</label> <br />
                <div className="App">
                <InputsHi type="datetime-local" onChange={(e)=>setTimeStamp(e.target.value)} id="inp2" />
                </div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}} >VFR Count*</label> <br />
                <div className="App">
                <InputsHi type="number" onChange={(e)=>setVFRCount(e.target.value)} placeholder="Enter Count" id="inp3" />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp4" className="fw-bold" style={{marginLeft:"15%"}} >IFR Count*</label> <br />
           <div className="App">
                <InputsHi type="number" onChange={(e)=>setIFRCount(e.target.value)} placeholder="Enter Count" id="inp4" />
                </div>
                </div>
               
                <div className="col-md-6">
                <label htmlFor="inp5" className="fw-bold" style={{marginLeft:"15%"}}>Total Runway Count*</label> <br />
                    <div className="App">
                    <input className="px-3 py-2 mx-2 inpFoc radius" onChange={(e)=>setTotalRunwayCount(e.target.value)} id="inp5" value="Enter Count" style={{ backgroundColor: "#e9edc9",marginBottom:"4%" }} disabled id="outlined-disabled" type="number" placeholder="User Name" />
</div>
                </div>
                            
            </div>
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp6" className=" fw-bold" style={{marginLeft:"15%"}}>Overflights Count*</label> <br />
              <div className="App">
                <InputsHi type="number" onChange={(e)=>setOverflightsCount(e.target.value)} placeholder="Enter Count" id="inp6" />
                </div>
                </div>
               
                <div className="col-md-6">
               
                <label htmlFor="inp7" className=" fw-bold" style={{marginLeft:"15%"}}>Total Movement*</label> <br />
              <div className="App">
                <input className="px-3 py-2 mx-2 inpFoc radius" onChange={(e)=>setTotalMovement(e.target.value)} id="inp7" value="Enter Count" style={{ backgroundColor: "#e9edc9",marginBottom:"4%" }} disabled id="outlined-disabled" type="text" placeholder="User Name" />

                </div>
                </div>
                            
            </div>
           


            <div className="row">
                <div className="col-md-6">
                <label htmlFor="inp8" className="fw-bold" style={{marginLeft:"15%"}} >Remarks</label> <br />
                  <div className="App">
                    <textarea id="inp8" placeholder="Enter Remarks..." onClick={(e)=>setRemarks(e.target.value)} className="px-2 mx-2 inpFoc radius"style={{ height: "80%", marginBottom:"3%" }} type="text" />
            
                </div>
                </div>
                <div className="col-md-6">
             
                </div>

                
            </div>
            <div style={{paddingBottom:"3%", marginTop:"5%"}}>
<SnackBar  />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
            </div>
</form>

        </div>
    )
}
export default Movement;