import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const DailyChecklist = () => {

    const [timeStamp, setTimeStamp] = useState('');
    const [remarks, setRemarks] = useState('');

    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });     
        let remarksClean = DOMPurify.sanitize(remarks, { USE_PROFILES: { html: false } });

        console.log(timeStampClean);
        console.log(remarksClean);
    }


    return (
        <div style={{fontFamily:"Poppins , sans-serif"}}>
            {/* <Navbar/> */}
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Daily Checklist</h1>
            </div>
<form onSubmit={(e)=>e.preventDefault()}>
            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Date</label> <br />
                  <div className="App">
                    <InputsHi type="datetime-local" onChange={(e)=>setTimeStamp(e.target.value)} id="inp2" />
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp3" >Remarks</label> <br />
<div className="App">
                    <InputsHi type="text" id="inp3" onChange={(e)=>setRemarks(e.target.value)} placeholder="Enter Remarks" />
                </div>
                </div>
            </div>
           

     <div>       
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
</div>
</form>

        </div>
    )
}

export default DailyChecklist
