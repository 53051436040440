import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';


const GroundIncident = () => {

    const [landingRWY, setLandingRWY] = useState('');
    const [aircraftType, setAircraftType] = useState('');
    const [ETA, setETA] = useState('');
    const [pob, setPob] = useState('');
    const [reasonForAction, setReasonForAction] = useState('');
    const [pilotIntentions, setPilotIntentions] = useState('');


    const mysubmit = () => {
        let landingRWYClean = DOMPurify.sanitize(landingRWY, { USE_PROFILES: { html: false } });
        let aircraftTypeClean = DOMPurify.sanitize(aircraftType, { USE_PROFILES: { html: false } });
        let ETAClean = DOMPurify.sanitize(ETA, { USE_PROFILES: { html: false } });
        let pobClean = DOMPurify.sanitize(pob, { USE_PROFILES: { html: false } });
        let reasonForActionClean = DOMPurify.sanitize(reasonForAction, { USE_PROFILES: { html: false } });
        let pilotIntentionsClean = DOMPurify.sanitize(pilotIntentions, { USE_PROFILES: { html: false } });

        console.log(landingRWYClean);
        console.log(aircraftTypeClean);
        console.log(ETAClean);
        console.log(pobClean);
        console.log(reasonForActionClean);
        console.log(pilotIntentionsClean);
    }


    return (
        <div style={{fontFamily:"Poppins , sans-serif"}}>
<br />     
        <div className="App">
            <div style={{borderBottom:"1px solid rgba(0,0,0,0.2)"}}>
                        <h1 style={{ color: "#505050"}} className="fw-bold my-4">Ground Incident</h1>
                        <label  className="text-center" >Call RFFS on the VCCS, state ‘Ground Incident’ and pass the following.</label><br/>
                        <label   className="text-center" style={{marginBottom:"20px"}} >Do not delay call if some information is not available.</label><br/>
                      
                        </div>
                     
                    </div>
        
        <div className="my-5">
            <form onSubmit={(e)=>e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-6">
                               <label className="fw-bold" style={{marginLeft:"15%",width:"50%"}} htmlFor="inp2" >Landing RWY or Location on Airport</label> <br />
                            <div className="App">
                            <InputsHi type="text" id="inp2" onChange={(e) => setLandingRWY(e.target.value)} placeholder="Landing RWY or Location on Airport"/>
                            </div>              
                            </div>
                        <div className="col-md-6">
                        <label className="fw-bold" style={{width:"50%",marginLeft:"15%"}} htmlFor="inp3" >Aircraft Type</label> <br />
        <div className="App">
                            <InputsHi type="text" id="inp3" onChange={(e) => setAircraftType(e.target.value)} autocomplete="on" placeholder="Aircraft Type (Auto Complete)" />
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <label className="fw-bold" style={{marginLeft:"15%",width:"50%"}} htmlFor="inp4" >ETA</label> <br />
                        <div className="App">
                        <InputsHi type="time" id="inp3" onChange={(e) => setETA(e.target.value)} />
        </div>
                        </div>

                        <div className="col-md-6">
                        <label className="fw-bold " style={{width:"50%",marginLeft:"15%"}} htmlFor="inp4" >POB (if known)</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp3" onChange={(e) => setPob(e.target.value)} placeholder="POB (if known)" />
           </div>
           </div>
           </div>
          
          <div className="row">
           <div className="col-md-6">
                        <label className="fw-bold clas5" style={{marginLeft:"15%",width:"50%"}} htmlFor="inp4" >Reason for Action</label> <br />
                        <div className="App">
                        <InputsHi type="text" id="inp3" onChange={(e) => setReasonForAction(e.target.value)} placeholder="Reason for Action" />
           </div>           
        </div>
           <div className="col-md-6">
                        <label className="fw-bold"  htmlFor="inp4" style={{marginLeft:"15%"}} >Pilot’s Intentions</label> <br />
                       <div className="App">
                        <InputsHi type="text" id="inp3" onChange={(e) => setPilotIntentions(e.target.value)} placeholder="Pilot’s Intentions" />
                    </div>
                    </div>
                     
                        </div>
           

           {/* CheckBoxes */}
        
              <div style={{marginLeft:"40px",marginTop:"80px", fontFamily:'Poppins',fontWeight:'400'}}>
        
                <h5 style={{fontFamily:'Poppins',fontWeight:'600',fontSize:"16px"}}>Pass the information above to:</h5>



                <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb1"name="tb1" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb1"> GMC/ADC</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb2"name="tb2"/>
        <label style={{ width: "450px", display: "inline-block" }} className="text-dark mx-2" for="tb2">ATCA</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb3" name="tb3" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb3"> DPN DL (if necessary, ask for increased spacing/ stop arrival traffic)</label><br/>
                    </li>
     
                    <li>
                    <input type="checkbox" id="tb4" name="tb4" />
        <label style={{ width: "410px", display: "inline-block" }} className="text-dark mx-2" for="tb4">Support Manager  (use Support Manager On-Call list)</label><br/>

                    </li>
                    <li>
                    <input type="checkbox" id="tb5" name="tb5" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb5"> Split ADC/GMC (if necessary)</label><br/>
                    </li>

                
                 
         
                
                </ul>


                    
                        <h5 style={{fontFamily:'Poppins',fontWeight:'600',fontSize:"14px"}}>Notify the following when Ground Incident is cancelled</h5>


                        <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb11"name="tb11" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb11"> GMC/ADC</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb12"name="tb12"/>
        <label style={{ width: "450px", display: "inline-block" }} className="text-dark mx-2" for="tb12">ATCA</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb13" name="tb13" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb13">DPN DL (if necessary, revise arrival spacing/ resume arrival traffic)</label><br/>
                    </li>
     
                    <li>
                    <input type="checkbox" id="tb14" name="tb14" />
        <label style={{ width: "410px", display: "inline-block" }} className="text-dark mx-2" for="tb14"> Support Manager</label><br/>

                    </li>
                    <br />
                    <li style={{fontSize:"14px"}}>
                    Request RWY Inspection if required

                    </li>
                    <li  style={{fontSize:"14px"}}>
                    File ROSI

                    </li>

                
                 
         
                
                </ul>
                   
              
         </div>
         {/* Check Boxxes End */}
         <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
        
        </form>
         </div>
         </div>

    )
}

export default GroundIncident;
