import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const GeneralMedicalEmergency = () => {
    const [genderOfPatients, setGenderOfPatients] = useState('');
    const [age, setAge] = useState('');
    const [conscious, setConscious] = useState('');
    const [reason, setReason] = useState('');
    const [specialRequests, setSpecialRequests] = useState('');
    
    const [aircraftIdentification, setAircraftIdentification] = useState('');
    const [departureAerodrome, setDepartureAerodrome] = useState('');
    const [destinationAerodrome, setDestinationAerodrome] = useState('');
    const [timeOfArrival, setTimeOfArrival] = useState('');
    const [personOnBoard, setPersonOnBoard] = useState('');
    const [suspectedCase, setSuspectedCase] = useState('');
    const [publicHealthRisk, setPublicHealthRisk] = useState('');


    const mysubmit = () => {
        let genderOfPatientsClean = DOMPurify.sanitize(genderOfPatients, { USE_PROFILES: { html: false } });
        let ageClean = DOMPurify.sanitize(age, { USE_PROFILES: { html: false } });
        let consciousClean = DOMPurify.sanitize(conscious, { USE_PROFILES: { html: false } });
        let reasonClean = DOMPurify.sanitize(reason, { USE_PROFILES: { html: false } });
        let specialRequestsClean = DOMPurify.sanitize(specialRequests, { USE_PROFILES: { html: false } });
      
        let aircraftIdentificationClean = DOMPurify.sanitize(aircraftIdentification, { USE_PROFILES: { html: false } });
        let departureAerodromeClean = DOMPurify.sanitize(departureAerodrome, { USE_PROFILES: { html: false } });
        let destinationAerodromeClean = DOMPurify.sanitize(destinationAerodrome, { USE_PROFILES: { html: false } });
        let timeOfArrivalClean = DOMPurify.sanitize(timeOfArrival, { USE_PROFILES: { html: false } });
        let personOnBoardClean = DOMPurify.sanitize(personOnBoard, { USE_PROFILES: { html: false } });
        let suspectedCaseClean = DOMPurify.sanitize(suspectedCase, { USE_PROFILES: { html: false } });
        let publicHealthRiskClean = DOMPurify.sanitize(publicHealthRisk, { USE_PROFILES: { html: false } });

        console.log(genderOfPatientsClean);
        console.log(ageClean);
        console.log(consciousClean);
        console.log(reasonClean);
        console.log(specialRequestsClean);

        console.log(aircraftIdentificationClean);
        console.log(departureAerodromeClean);
        console.log(destinationAerodromeClean);
        console.log(timeOfArrivalClean);
        console.log(personOnBoardClean);
        console.log(suspectedCaseClean);
        console.log(publicHealthRiskClean);
    }

    return (
        <div style={{fontFamily:"Poppins , sans-serif"}}>
             
            <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Medical Emergency</h1>
        </div>
       
       
            <br/>
<form onSubmit={(e)=>e.preventDefault()}>
  
    <h3 style={{color:'#E72C2C',marginLeft:"60px" , fontWeight:"600"}}>General Medical Emergency</h3><br/>
            <div className="row ">
                <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Gender of Patient</label> <br />
           <div className="App">
            <select id="inp3"   onChange={(e) => setGenderOfPatients(e.target.value)} className="px-3 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >Others </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            </select> 
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold"  style={{marginLeft:"15%"}}   htmlFor="inp3" >Age</label> <br />
<div className="App">
                <InputsHi   onChange={(e) => setAge(e.target.value)} type="number" id="inp3" />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold"  style={{marginLeft:"15%"}}   htmlFor="inp4" >Conscious, Y/N?</label> <br />
                       <div className="App">
                       <select id="inp3"   onChange={(e) => setConscious(e.target.value)} className="px-3 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >Select </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
        
            </select>
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp5" >Reason/Symptoms</label> <br />
<div className="App">
                <InputsHi type="text" id="inp5"   onChange={(e) => setReason(e.target.value)} placeholder="Reason/Symptoms"/>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold"  style={{marginLeft:"15%"}}   htmlFor="inp6">Special Requests</label> <br />
                    <div className="App">
                    <InputsHi type="text" id="inp6"   onChange={(e) => setSpecialRequests(e.target.value)} placeholder="Special Requests"/>
                </div>
                </div>
            </div>
        
<br/>
    <h3 style={{color:'#E72C2C',marginLeft:"60px", fontWeight:"600"}}>Public Health Emergencies ONLY</h3>
    <br/>
            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold"  style={{marginLeft:"15%"}}   htmlFor="inp2" >Aircraft Identification</label> <br />
                    <div className="App">
                    <InputsHi type="text"   onChange={(e) => setAircraftIdentification(e.target.value)} id="inp2" placeholder="Aircraft Identification"/>
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold"  style={{marginLeft:"15%"}}   htmlFor="inp3" >Departure Aerodrome</label> <br />
<div className="App">
                <InputsHi type="text"   onChange={(e) => setDepartureAerodrome(e.target.value)} id="inp3" placeholder="Departure Aerodrome"/>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold"  style={{marginLeft:"15%"}}   htmlFor="inp4">Destination Aerodrome</label> <br />
                 <div className="App">
                    <InputsHi type="text" id="inp4"   onChange={(e) => setDestinationAerodrome(e.target.value)} placeholder="Destination Aerodrome"/>
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold"  style={{marginLeft:"15%"}}   htmlFor="inp5" >Estimated Time of Arrival</label> <br />
<div className="App">
                <InputsHi type="time"   onChange={(e) => setTimeOfArrival(e.target.value)} id="inp5" />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold"  htmlFor="inp6"  style={{marginLeft:"15%"}}  >Number of Persons on Board</label> <br />
                 <div className="App">
                    <InputsHi type="text" id="inp6"   onChange={(e) => setPersonOnBoard(e.target.value)} placeholder="Number of Persons on Board"/>

                </div>
                </div>
          <div className="col-md-6">
     

        
             
                <label className="fw-bold"  htmlFor="inp6" style={{width:"50%" , marginLeft:"15%"}} >Number of Suspected Case(s) On Board</label> <br />
                   <div className="App">
                    <InputsHi type="text" id="inp6"   onChange={(e) => setSuspectedCase(e.target.value)} placeholder="Number of Suspected Case(s) On Board"/>

                    
       
                </div>
                </div>
                
            </div>
            <div className="row">
  
            <div className="col-md-6">
            
                <label className="fw-bold"  htmlFor="inp7" style={{marginLeft:"15%",width:"50%"}} >Nature of the Public Health Risk, If Known</label> <br />
                <div className="App">
                <InputsHi type="text"   onChange={(e) => setPublicHealthRisk(e.target.value)} id="inp7" placeholder="Nature of the Public Health Risk, If Known"/>
        
            </div>
            </div>

            </div>








            <div  style={{marginLeft:"50px",marginTop:"80px", fontFamily:'Poppins',fontWeight:'400'}}>



        
        <h5 style={{fontFamily:'Poppins',fontWeight:'600',fontSize:"16px"}}>Pass the information appropriate table above to:</h5>


        <ul style={{
                    listStyleType: "none", width: "850px",
                    display: "inline-block"
                }}>
                    <li>
                    <input type="checkbox" id="tb1"name="tb1" />
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb1"> RFFS</label><br/></li>
                    <li>
                    <input type="checkbox" id="tb2"name="tb2"/>
        <label style={{ width: "350px", display: "inline-block" }} className="text-dark mx-2" for="tb2">OCC</label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb3" name="tb3" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb3"> GMC/ADC/CIC </label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb4" name="tb4" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb4"> COD (if necessary) For Public Health Emergencies, also notify: </label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb5" name="tb5" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb5">Support Manager </label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb6" name="tb6" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb6">Medical Centre </label><br/>
                    </li>
                    <li>
                    <input type="checkbox" id="tb7" name="tb7" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb7">Aircraft Operator </label><br/>
                    </li>
      
                    <li>
                    <input type="checkbox" id="tb8" name="tb8" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb8">ATS unit serving the destination/departure</label><br/>
                    </li>
      
                    <li>
                    <input type="checkbox" id="tb9" name="tb9" />
        <label style={{ width: "520px", display: "inline-block" }} className="text-dark mx-2" for="tb9">Refer to IFER Manual </label><br/>
                    </li>
      
                </ul>

              
                
                <p className="my-1">File ROSI (Pilot Requesting Diversion/Priority Handling OR Public Health Emergency ONLY)</p>
                
                </div>
            <div className="row App">
                
                <div className="col-md-6">
               
                </div>

                
            </div>

            
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
</form>
        </div>
    )
}

export default GeneralMedicalEmergency;
