import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import { Link } from 'react-router-dom';

const ManageFeedback = () => {

	const [manage, setManage] = useState([
		{
			referenceNo: "ATC_W_61923b1e7cc6c_15-11-2021",
			initial: "OMA",
			subject: "demo subject",
			purpose: " ",
			dateTime: "15 Nov 2021 - 10:50",
			status: "Open",


		},
		{
			referenceNo: "ATC_W_61923b1e7cc6c_15-11-2021",
			initial: "OMA",
			subject: "demo subject",
			purpose: " ",
			dateTime: "15 Nov 2021 - 10:50",
			status: "Close",


		},
		{
			referenceNo: "ATC_W_61923b1e7cc6c_15-11-2021",
			initial: "OMA",
			subject: "demo subject",
			purpose: " ",
			dateTime: "15 Nov 2021 - 10:50",
			status: "Open",


		},
		{
			referenceNo: "ATC_W_61923b1e7cc6c_15-11-2021",
			initial: "OMA",
			subject: "demo subject",
			purpose: " ",
			dateTime: "15 Nov 2021 - 10:50",
			status: "Close",


		},
		{
			referenceNo: "ATC_W_61923b1e7cc6c_15-11-2021",
			initial: "OMA",
			subject: "demo subject",
			purpose: " ",
			dateTime: "15 Nov 2021 - 10:50",
			status: "Open",


		},
		{
			referenceNo: "ATC_W_61923b1e7cc6c_15-11-2021",
			initial: "OMA",
			subject: "demo subject",
			purpose: " ",
			dateTime: "15 Nov 2021 - 10:50",
			status: "Close",


		},

	])

	return (
		<div className="font back7">
			
			<br />
			<br />
			<div className="App">
				<h1 style={{ color: "#505050" }} className="fw-bold my-5">Manage Feedback</h1>
			</div>

			<form>
				<div className="row">


					<div className="col-md-6">
						<label htmlFor="inp3" className="fw-bold" style={{ marginLeft: "15%" }} >Reference Number</label> <br />
						<div className="App">
							<InputsHi type="text" id="inp3" placeholder="Enter Reference Number" />
						</div>
					</div>
					<div className="col-md-6">
						<label htmlFor="inp4" className="fw-bold" style={{ marginLeft: "15%" }}  >To</label> <br />
						<div className="App">
							<InputsHi type="text" id="inp4" placeholder="Enter value..." />
						</div>
					</div>

				</div>
				<div className="row">

					<div className="col-md-6">
						<label htmlFor="inp5" className="fw-bold" style={{ marginLeft: "15%" }}>From</label> <br />
						<div className="App">
							<InputsHi type="text" id="inp5" placeholder="Enter value..." />
						</div>
					</div>
					<div className="col-md-6">
						<label htmlFor="inp6" className=" fw-bold" style={{ marginLeft: "15%" }}>Purpose</label> <br />
						<div className="App">
							<InputsHi type="text" placeholder="Enter Purpose..." id="inp6" />
						</div>
					</div>

				</div>
				<div className="row ">

					<div className="col-md-6">

						<label htmlFor="inp7" className="fw-bold" style={{ marginLeft: "15%" }}>Subject</label> <br />
						<div className="App">
							<InputsHi type="text" id="inp7" placeholder="Enter Subject..." />
						</div>
					</div>

				</div>



				<div style={{ paddingBottom: "1%", marginTop: "2%" }}>


					<Link to="/dashboard/Feedback/createFeedback">
						<button className="btn px-3 py-2 mx-2" style={{ backgroundColor: "#FB6A6A", borderRadius: "50px", color: "white", marginLeft: "1%", marginRight: "200px", marginBottom: "8%", float: "right" }}>Create New</button>
					</Link>

					<button className="btn px-3 py-2 mx-1" style={{ backgroundColor: "#BB3417", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "8%", float: "right" }}>Search</button>

				</div>
			</form>



			{/* TAbles */}


			<div class="portlet light" >

				<div class="portlet-body " style={{ paddingBottom: "20px" }}>

					<div class="table-scrollable text-center" >
						<table class="table table-striped table-bordered table-hover" style={{ width: "85%", marginTop: "5%", marginLeft: "160px" }}>
							<thead>
								<tr>
									<th className="text-center" scope="col">
										Reference No.
									</th>
									<th className="text-center" scope="col" style={{ width: "150px" }}>
										Initial
									</th>
									<th className="text-center" scope="col" style={{ width: "230px" }}>
										Subject
									</th>
									<th className="text-center" scope="col" style={{ width: "190px" }}>
										Purpose
									</th>

									<th className="text-center" scope="col" style={{ width: "150px" }}>
										Date &amp; Time
									</th>
									<th className="text-center" scope="col" style={{ width: "90px" }}>
										Status
									</th>
									<th scope="col" className="text-center" style={{ width: "90px" }}>
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{manage.map((e, i) => {
								return(
								<tr>
										<td className="text-center">
											{e.referenceNo}
										</td>
										<td className="text-center">
{e.initial}
											
										</td>
										<td className="text-center">
{e.subject}
										</td>
										<td className="text-center">
											{e.purpose}
										</td>

										<td className="text-center">
											{e.dateTime}
											</td>
										<td className="text-center">
										{e.status}
										</td>
										<td className="text-center">
											<button class="btn btn-danger btn-circle text-center" >View</button>
										</td>
									</tr>
								)
								})}


							</tbody>
						</table>


					</div>
				</div>
			</div>

			{/* Table End */}

		</div>
	)
}

export default ManageFeedback;
