import React from 'react';
import InputsHi from '../LOGs/input';
import { useState } from 'react';
import {CSVLink} from 'react-csv';
import DOMPurify from 'dompurify';


const TrainingReport = () => {


  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');



  const mysubmit = () => {
      let fromDateClean = DOMPurify.sanitize(fromDate, { USE_PROFILES: { html: false } });
      let toDateClean = DOMPurify.sanitize(toDate, { USE_PROFILES: { html: false } });
 

      console.log(fromDateClean);
      console.log(toDateClean);

  }




const [tableData , setTableData] = useState([
  {
  select:"1",
  date:"15-12-2021",
  time:"12:15 till 15:54",
  duration:"2 hours",
  trainee:"OMA",
  instructor:"OMA",
  action:"Open"
},
  {
  select:"2",
  date:"15-12-2021",
  time:"12:15 till 15:54",
  duration:"2 hours",
  trainee:"OMA",
  instructor:"OMA",
  action:"Open"
},
  {
  select:"3",
  date:"15-12-2021",
  time:"12:15 till 15:54",
  duration:"2 hours",
  trainee:"OMA",
  instructor:"OMA",
  action:"Open"
},
  {
  select:"4",
  date:"15-12-2021",
  time:"12:15 till 15:54",
  duration:"2 hours",
  trainee:"OMA",
  instructor:"OMA",
  action:"Open"
},

])

  function printData()
  {
     var divToPrint = document.getElementById("printTable");
     
     var htmlToPrint = '' +
     '<style type="text/css">' +
     '.flatTable {' +
      "display: block;" +
    "font-family: Poppins , sans-serif;" +
      "font-size: 13px;"+
     
      "border: 5px solid rgba(0,0,0, 0.2)"+
  "}" +
     "th {" +
        "background-color: #00b4d8;" +
        "color: white;"+
        "font-weight: normal;"+
        "padding: 10px 10px;"+
        "text-align: center;"+
        "border-right: 1px solid rgba(0,0,0, 0.2);"+
        "border-bottom: 1px solid rgba(0,0,0, 0.2);"+

      "}"+
     "td {"+
        "background-color: rgb(238, 238, 238);"+
        "padding: 15px 15px;"+

        "border-bottom: 1px solid rgba(0,0,0, 0.2);"+
        "border-right: 1px solid rgba(0,0,0, 0.2);"+
  

        "color: rgb(111, 111, 111);"+
      "}"+
      
     '</style>';
 htmlToPrint += divToPrint.outerHTML;
 let newWin = window.open("");
 newWin.document.write(htmlToPrint);
 newWin.print();
 newWin.close();
  }
 
return (


    <div className="font back7">
    
 
        <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Training Report</h1>
        </div>

<form onSubmit={(e)=>e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >From</label> <br />
            <div className="App">
            <InputsHi onChange={(e)=>setFromDate(e.target.value)} type="date" id="inp2" />
            </div>
            </div>
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >To</label> <br />
            <div className="App">
            <InputsHi type="date" onChange={(e)=>setToDate(e.target.value)} id="inp2"  />
            </div>
            </div>
        </div>
       


      

       
        <div style={{paddingBottom:"1%", marginTop:"1%"}}>

        <CSVLink data={tableData} filename={"trainingReport.csv"}  className="btn px-4 py-2 mx-3" style={{backgroundColor:"#f4a261", borderRadius:"5px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>Export CSV  </CSVLink>

<button className="btn px-4 py-2 mx-3"  style={{backgroundColor:"#48cae4", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}} onClick={()=>printData()}>Print Report</button>
<button onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>Filter</button>


        </div>
</form>



<div className="App container">
<table className="table table-bordered table-info flatTable " id="printTable">
  <thead className="table-primary">
    <tr>
      <th scope="col" style={{width:"60px"}}>Select</th>
      <th scope="col" style={{width:"150px"}}>Date</th>
      <th scope="col"  style={{width:"150px"}}>Start Time & End Time</th>
      <th scope="col"  style={{width:"150px"}}>Duration</th>
      <th scope="col" style={{width:"150px"}}>Trainee</th>
      <th scope="col" style={{width:"150px"}}>Instructor</th>
      <th scope="col" style={{width:"150px"}}>Action</th>
    </tr>
  </thead>
  <tbody>

  {
  
  tableData.map((e,i)=>{
return(
    <tr key={i}>


      <th scope="row">{e.select}</th>
      <td className="App">{e.date}</td>
      <td className="App">{e.time}</td> 
      <td className="App">{e.duration}</td>
      <td className="App">{e.trainee}</td>
      <td className="App">{e.instructor}</td>
      <td className="App">{e.action}</td>



    </tr>
)
  })}
  


  </tbody>
</table>
</div>



    </div>

)
}
export default TrainingReport;



