import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';

const GeneralReport = () => {

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [designation, setDesignation] = useState('');


    const mysubmit = () => {
        let fromDateClean = DOMPurify.sanitize(fromDate, { USE_PROFILES: { html: false } });
        let toDateClean = DOMPurify.sanitize(toDate, { USE_PROFILES: { html: false } });
        let designationClean = DOMPurify.sanitize(designation, { USE_PROFILES: { html: false } });

        console.log(fromDateClean);
        console.log(toDateClean);
        console.log(designationClean);
    }


    return (
        <div style={{fontFamily:"Poppins , sasns-serif"}}>

<div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">General Report</h1>
            </div>
<form onSubmit={(e)=>e.preventDefault()}>
            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold"  htmlFor="inp2"  style={{marginLeft:"15%"}}>From Date</label> <br />
                  <div className="App">
                    <InputsHi type="date" onChange={(e)=>setFromDate(e.target.value)} id="inp2" />
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold " style={{marginLeft:"15%"}}  htmlFor="inp3" >To Date</label> <br />
<div className="App">
                    <InputsHi type="date" onChange={(e)=>setToDate(e.target.value)} id="inp3" />
            </div>
                </div>
                </div>
                <div className="row">
            <div className="col-md-6">
                
              
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp4" >Designation</label> <br />
<div className="App">
                    

<select id="inp3" onChange={(e)=>setDesignation(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >Select Designation</option>
    <option value="ATCO">ATCO</option>
    <option value="ATCA">ATCA</option>
  </select> 



                </div>
                </div>
                </div>

                <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
</form>
        </div>
    )
}

export default GeneralReport;
