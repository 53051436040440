import React from 'react'
import Navbar from '../../navbar/topnav'
import "./userProfile.css"
import {FaPhoneAlt} from "react-icons/fa"
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { Link } from '@mui/material';


const UserProfile = () => {
    return (
        <div style={{fontFamily:"Poppins , sans-serif"}}>
            
<div id="heroo-image">
  <div class="covers">
  </div>
  <div class="overlayy"></div>
  <div class="row profiles">
    <div class="profiles-image">
      <img src="https://thumbs.dreamstime.com/b/male-avatar-icon-flat-style-male-user-icon-cartoon-man-avatar-hipster-vector-stock-91462914.jpg" alt="" />
    </div>    
    <div class="profiles-details">
      <h1>Mohammed Ali Omair</h1>
      <span> <FaPhoneAlt className="mx-2" style={{fontSize:"13px"}}/> 
4567</span> <br/>
<span> <MailOutlineIcon className="mx-2" style={{fontSize:"15px"}} />
Mohammed.Ali
</span>
    </div>
  </div>
</div>
<div className = "container" id="contents-section">
  <div className = "row">
    <div>
    <div className = "row">
        <div className = "col-md-6">
      
            
        <h1 className = "contents-heading" style={{textAlign:"left", color:"red",fontSize:"24px"}}>Work History</h1>
   
        </div>

        <div className = "col-lg-4">
          <Link to="/dashboard/myProfile">
             <button className="btn btn-danger" style={{width:"20%"}}>Edit</button>
             </Link>
      {/* <Profile/> */}
      
          </div>

   
    
      </div>
      <hr/>
      <ul>
      <div className = "row">
        <div className = "col-md-4">
      
        <li className = "list"><strong>Company :</strong><br/> Airport ME</li>
       

        </div>

        <div className = "col-md-4">
          
        <li className = "list"><strong>Nationality :</strong><br/>  Demo Nationality  </li>

    
          </div>
          <div className = "col-md-4"> 
          <li className = "list"><strong>Designation :</strong><br/> ATCO</li>
          
            </div>
          
          </div>
          <hr />
          <div className = "row">
        <div className = "col-md-4">
      
        <li className = "list"><strong>Mobile No :</strong><br/>234567</li>
        

        </div>

        <div className = "col-md-4">
          
        <li className = "list"><strong>Phone 1 :</strong><br/> </li>
       
    
          </div>
          <div className = "col-md-4"> 
          <li className = "list"><strong>Phone 2 :</strong><br/> 878787 </li>
        
            </div>
          
          </div>
          <hr />
    <div className = "row">
        <div className = "col-md-4">
      
        <li className = "list"><strong>Address Line 1:</strong><br/></li>
        

        </div>

        <div className = "col-md-4">
          
        <li className = "list"><strong>Address Line 2:</strong><br/>  </li>
       
    
          </div>
          <div className = "col-md-4"> 
          <li className = "list"><strong>User Role:</strong><br/> Admin </li>
        
            </div>
          
          </div>
          <hr />
          <div className = "row">
        <div className = "col-md-4">
      
        <li className = "list"><strong>Medical Expiry Date :</strong><br/>2029-12-31 00:00:00</li>
        

        </div>

        <div className = "col-md-4">
          
        <li className = "list"><strong>ADP Cicem Expiry Date :</strong><br/> 2029-12-31 00:00:00 </li>
       
    
          </div>
          <div className = "col-md-4"> 
          <li className = "list"><strong>Visa Expiry Date :</strong><br/> 2029-12-31 00:00:00 </li>
        
            </div>
          <hr/>
          </div>
          <div className = "row">
        <div className = "col-md-4">
      
        <li className = "list"><strong>Staff Date :</strong><br/>23456</li>
        

        </div>

        <div className = "col-md-4">
          
        <li className = "list"><strong>Authority :</strong><br/>  </li>
       
    
          </div>
          <div className = "col-md-4"> 
          <li className = "list"><strong> Insurance :</strong><br/>  </li>
        
            </div>
          
          </div>
          <hr/>
    <div className = "row">
        <div className = "col-md-4">
      
        <li className = "list"><strong>ATC Expiry Date :</strong><br/>2029-12-31 00:00:00</li>
        

        </div>

        <div className = "col-md-4">
          
        <li className = "list"><strong>Date of Birth :</strong><br/>  </li>
       
    
          </div>
          <div className = "col-md-4"> 
          <li className = "list"><strong> Position :</strong><br/> Demo Position </li>
        
            </div>
          
          </div>
          <br/><br/>
          <div className = "row" style={{backgroundColor:"#F1F1F1"}}>
        <div className = "col-md-6">
      
        <li className = "list" style={{marginLeft:"20px"}}><strong>Last Access : </strong>29th November Monday 2021 </li>
        

        </div>

        <div className = "col-md-6">
          
        <li className = "list"><strong>Total Time :</strong> 7 hours 21 minutes </li>

          </div>

          </div>
          <br/><br/>
      </ul>
        
    </div>
      </div>
     
    
    
     </div>

        </div>
    )
}

export default UserProfile
