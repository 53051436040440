import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';
// 
const DangerousGoods = () => {
    const [dangerousGoods, setDangerousGoods] = useState('');
    

    const mysubmit = () => {
        let dangerousGoodsClean = DOMPurify.sanitize(dangerousGoods, { USE_PROFILES: { html: false } });
        

        console.log(dangerousGoodsClean);
        

    }
    return (
        <div className="container" style={{fontFamily:"Poppins , sans-serif"}}>
        {/*  */}
                <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Dangerous Goods</h1>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Dangerous Goods </label> <br />
                   <div className="App">
                <InputsHi type="text"  onChange={(e) => setDangerousGoods(e.target.value)} id="inp2" placeholder="Enter Dangerous Goods..." />
            </div>
            </div>
         
			
			
			
        </div>
        
       




            <br/>
            <br/>
        
<SnackBar />
<button onClick={() => mysubmit()} className="btn px-4 py-2 mx-3" style={{ backgroundColor: "#FB6A6A", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "8%", float: "right" }}>Cancel</button>
<button className="btn px-4 py-2 mx-1" style={{backgroundColor:"blue", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>View Dangerous Goods</button>
</form>
   
    </div>
    )
}

export default DangerousGoods;
