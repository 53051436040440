import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import InputsHi from '../LOGs/input';

const AddUser = () => {
  const [userName, setUserName] = useState('');
  const [userData, setUserData] = useState([
    {
    date: "19-11-2020",
    startAndEndTime: "19:00 till 23:24",
    duration: "03:24",
    trainee: "OMA",
    instructor: "OMA",
    action: "Open"
  },
    {
    date: "19-11-2020",
    startAndEndTime: "19:00 till 23:24",
    duration: "03:24",
    trainee: "OMA",
    instructor: "OMA",
    action: "Open"
  },
    {
    date: "19-11-2020",
    startAndEndTime: "19:00 till 23:24",
    duration: "03:24",
    trainee: "OMA",
    instructor: "OMA",
    action: "Open"
  },
    {
    date: "19-11-2020",
    startAndEndTime: "19:00 till 23:24",
    duration: "03:24",
    trainee: "OMA",
    instructor: "OMA",
    action: "Open"
  },
    {
    date: "19-11-2020",
    startAndEndTime: "19:00 till 23:24",
    duration: "03:24",
    trainee: "OMA",
    instructor: "OMA",
    action: "Open"
  },
    {
    date: "19-11-2020",
    startAndEndTime: "19:00 till 23:24",
    duration: "03:24",
    trainee: "OMA",
    instructor: "OMA",
    action: "Open"
  },
])

  const mysubmit = () => {
    let userNameClean = DOMPurify.sanitize(userName, { USE_PROFILES: { html: false } });

    console.log(userNameClean);


  }

  return (
    <div className="font back7">
      <div className="App">
        <h1 style={{ color: "#505050", fontFamily: "Poppins", fontSize: "45px" }} className="fw-bold my-5">Add Users</h1>
      </div>
      <br />




      <form onSubmit={(e) => e.preventDefault()}>
        <div className="row">
          <div className="col-md-6">
            <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp4" >User Name:</label> <br />
            <div className="App">
              <InputsHi type="text" onChange={(e) => setUserName(e.target.value)} placeholder="Enter User Name..." id="inp4" />

            </div>
          </div>
        </div>

        <div style={{ paddingTop: "2%", paddingBottom: "2%" }}>
          <div class="al-search-controls">
            <Link to="/dashboard/UserManagment/users">
              <button className="btn px-4 py-2 mx-3" style={{ backgroundColor: "red", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "6%", float: "right" }}>Filter</button>
            </Link>
            <button className="btn px-4 py-2 mx-3" style={{ backgroundColor: "#FB6A6A", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "6%", float: "right" }}>Cancel</button>

          </div>

        </div>
      </form>



      <div className="App container">
        <table className="table table-bordered table-info ">
          <thead className="table-primary">
            <tr>
              <th scope="col" style={{width:"80px"}}>Select</th>
              <th scope="col"  style={{width:"150px"}}>Date</th>
              <th scope="col"  style={{width:"150px"}}>Start Time & End Time</th>
              <th scope="col"  style={{width:"100px"}}>Duration</th>
              <th scope="col"  style={{width:"100px"}}>Trainee</th>
              <th scope="col"  style={{width:"100px"}}>Instructor</th>
              <th scope="col"  style={{width:"80px"}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((e, i) => {
              return (
                <tr>
                  <th scope="row">{i+1}</th>
                  <td className="App">{e.date}</td>
                  <td className="App">{e.startAndEndTime}</td>
                  <td className="App">{e.duration}</td>
                  <td className="App">{e.trainee}</td>
                  <td className="App">{e.instructor}</td>
                  <td className="App">{e.action}</td>

                </tr>
              )
            })}



          </tbody>
        </table>
      </div>







    </div>
  )
}

export default AddUser;
