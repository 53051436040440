import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const Pilotsreports = () => {

    const [timeStamp, setTimeStamp] = useState('');
    const [subject, setSubject] = useState('');
    const [callSign, setCallSign] = useState('');
    const [METInformed, setMETInformed] = useState('');
    const [runway, setRunway] = useState('');
    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });
        let subjectClean = DOMPurify.sanitize(subject, { USE_PROFILES: { html: false } });
        let callSignClean = DOMPurify.sanitize(callSign, { USE_PROFILES: { html: false } });
        let METInformedClean = DOMPurify.sanitize(METInformed, { USE_PROFILES: { html: false } });
        let runwayClean = DOMPurify.sanitize(runway, { USE_PROFILES: { html: false } });

        console.log(timeStampClean)
        console.log(subjectClean)
        console.log(callSignClean)
        console.log(METInformedClean)
        console.log(runwayClean)
    }


    return (
        <div style={{fontFamily:"Poppins, sans-serif"}}>
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Pilots Report</h1>
            </div>

            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Time Stamp</label> <br />

                       <div className="App">
                    <InputsHi type="datetime-local" onChange={(e) =>setTimeStamp(e.target.value)} id="inp2" />
                    </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp3" >Subject</label> <br />
                    <div className="App">
                    <InputsHi type="text" id="inp3" onChange={(e) =>setSubject(e.target.value)} placeholder="Enter Subject.." />
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                <label className="fw-bold" style={{marginLeft:"15%"}}   htmlFor="inp4" >Callsign</label> <br />
                <div className="App">
                <InputsHi type="number" onChange={(e) =>setCallSign(e.target.value)} id="inp3" placeholder="Enter Values.." />
                </div>
                </div>
                <div className="col-md-6">
                <label className="fw-bold clas5" style={{marginLeft:"15%"}}   htmlFor="inp3" >MET informed?</label> <br />
                <div className="App">
                <select id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius" style={{ marginBottom:"4%"}}  onChange={(e) =>setMETInformed(e.target.value)} >
    <option selected >Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select>            
                </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp3" >Runway Used</label> <br />
<div className="App">
                    <InputsHi type="text" id="inp3"  onChange={(e) =>setRunway(e.target.value)} placeholder="Enter Location.." />
                </div>
                </div>

                </div>
                

            <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>

        </div>
    )
}

export default Pilotsreports
