import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'

const OperationalCondition = () => {

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [subject, setSubject] = useState('');
    const [vehicle, setVehicle] = useState('');
    const [description, setDescription] = useState('');


    const mysubmit = () => {
        let startTimeClean = DOMPurify.sanitize(startTime, { USE_PROFILES: { html: false } });     
        let endTimeClean = DOMPurify.sanitize(endTime, { USE_PROFILES: { html: false } });
        let subjectClean = DOMPurify.sanitize(subject, { USE_PROFILES: { html: false } });
        let vehicleClean = DOMPurify.sanitize(vehicle, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });

        console.log(startTimeClean);
        console.log(endTimeClean);
        console.log(subjectClean);
        console.log(vehicleClean);
        console.log(descriptionClean);
    }


    return (
        <div style={{ fontFamily: "Poppins , sasns-serif" }}>

            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Operational Condition</h1>
            </div>

          <form onSubmit={(e)=>e.preventDefault()}>
            <div className="row ">
                <div className="col-md-6">
                    <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp2" >Start Time</label> <br />
                    <div className="App">
                        <InputsHi type="time" onChange={(e)=>setStartTime(e.target.value)} id="inp2" />
                    </div>

                </div>
                <div className="col-md-6">
                    <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp3" >End Time</label> <br />
                    <div className="App">
                        <InputsHi type="time" onChange={(e)=>setEndTime(e.target.value)} id="in3" />
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp4" >Subject</label> <br />
                    <div className="App">
                        <InputsHi type="text" id="inp4" onChange={(e)=>setSubject(e.target.value)} placeholder="Enter Subject.." />
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp4" >Vehicle</label> <br />
                    <div className="App">
                        <InputsHi type="text" id="inp4" onChange={(e)=>setVehicle(e.target.value)} placeholder="Enter Vehicle" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp5" >Description</label> <br />
                    <div className="App">
                        <textarea id="inp5" className="px-2 mx-2 inpFoc radius" onChange={(e)=>setDescription(e.target.value)} style={{ height: "80%", marginBottom: "3%" }} type="text" placeholder="Enter Description.." />

                    </div>
                </div>
                <div className="col-md-6">

                </div>

            </div>

         <div>
              <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>

        </div>
        </form>
        </div>
    )
}

export default OperationalCondition
