import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';
// 
const Designation = () => {
    const [designation, setDesignation] = useState('');
    const [designationOf, setDesignationOf] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState('');

    const mysubmit = () => {
        let designationClean = DOMPurify.sanitize(designation, { USE_PROFILES: { html: false } });
        let designationOfClean = DOMPurify.sanitize(designationOf, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });
        let activeClean = DOMPurify.sanitize(active, { USE_PROFILES: { html: false } });


        console.log(designationClean);
        console.log(designationOfClean);
        console.log(descriptionClean);
        console.log(activeClean);

    }
    return (
        <div className="container" style={{fontFamily:"Poppins , sans-serif"}}>
        {/*  */}
               
 <div className="App">

            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Designation</h1>
        </div>
           		       			
        <form onSubmit={(e) => e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Designation</label> <br />
                   <div className="App">
                <InputsHi type="text" onChange={(e) => setDesignation(e.target.value)} id="inp2" placeholder="Enter Designation..." />
            </div>
            </div>
			
            <div className="col-md-6">
            <label htmlFor="inp2" className="fw-bold" style={{marginLeft:"15%"}} >Designation of
</label>
            <div className="App">
            <select onChange={(e) => setDesignationOf(e.target.value)} id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
            <option selected >-- Select Designation --
</option>
            <option value="ATCO">ATCO</option>
            <option value="ATCA">ATCA</option>
            </select>
            </div>
            </div>
			
        </div>
        
            <div className="row" >
            
			
           
            <div className="col-md-6"  >

            <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Description</label> <br />
			<div className="App">
                           <textarea onChange={(e) => setDescription(e.target.value)} id="inp4" className="px-2 mx-2 inpFoc radius" style={{ height: "80%", marginBottom:"3%" }} type="text" placeholder="Enter Description ..." />
        </div>
            </div>
			<div className="col-md-6">
<label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Active</label> <br />

			<ul style={{
                    listStyleType: "none", width: "350px",
                    display: "inline-block" , marginLeft:"20%"
                }}>
                   
                   
                    <li className="my-1">
                    <input  onChange={active ? (e) => { (e.target.checked) ? setActive("Active") : setActive("Not Active") } : setActive("Not Active")}  type="checkbox" id="tb5"name="tb5" />
        <label style={{ width: "250px", display: "inline-block" }} className="text-dark mx-2" for="tb5">Active</label><br/></li>
                   
                   
                   
                </ul>
			</div>
            </div>




            <br/>
            <br/>
        
<SnackBar />
<button onClick={() => mysubmit()} className="btn px-4 py-2 mx-3" style={{ backgroundColor: "#FB6A6A", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "8%", float: "right" }}>Cancel</button>
<button className="btn px-4 py-2 mx-1" style={{backgroundColor:"blue", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>View Designations</button>
</form>
		
    </div>
    )
}

export default Designation;
