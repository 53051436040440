import "../App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Boxes from "./LOGs/boxes";
import Chat from "./chat/chat";
import Elog from "./elog/elog";

const Dashboard = () => {

    return (
        <>

            <div className="row">

                <Boxes />

               </div>            
            <div className="container-fluid">
            
<div style={{marginBottom:"90px", marginTop:"100px"}}>
           <Elog />
</div>

<br />
<br />

</div>
            <Chat />


        </>
    );
}


export default Dashboard;