import React, { useState } from 'react';
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = (props) => {
  const [msg, setMsg] = useState(props.msg);
  const [severity, setSeverity] = useState(props.severity);
  const [open, setOpen] = useState(props.open);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  }
    
  return (
    <React.Fragment>
      
  <Stack spacing={2} sx={{ width: "100%" }}>

<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
  <Alert severity={severity} onClose={handleClose} style={{ fontFamily: "Poppins , sans-serif" }} >
    {msg}! &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp;
  </Alert>
</Snackbar>
</Stack>

<button 
 className="btn px-4 py-2" 
 style={{ backgroundColor: "#DD3333", borderRadius: "50px", color: "white", marginBottom: "8%", float: "right", marginRight: "105px", fontFamily: "Poppins , sans-serif" }} 
onClick={props.onClick}
>Submit</button> 



    </React.Fragment>
  )
}

export default SnackBar

