import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input'
import SnackBar from '../LOGs/SnackBar'


const ReportableDelays = () => {

    // var csurf = require('csurf')

    const [timeStamp, setTimeStamp] = useState('');
    const [duration, setDuration] = useState('');
    const [callSign, setCallSign] = useState('');
    const [arrival, setArrival] = useState('');
    const [reason, setReason] = useState('');
    var timeStampFilter = timeStamp.replace(/T/g, ' ');

    const mysubmit = () => {
        let timeStampClean = DOMPurify.sanitize(timeStampFilter, { USE_PROFILES: { html: false } });
        let durationClean = DOMPurify.sanitize(duration, { USE_PROFILES: { html: false } });
        let callSignClean = DOMPurify.sanitize(callSign, { USE_PROFILES: { html: false } });
        let arrivalClean = DOMPurify.sanitize(arrival, { USE_PROFILES: { html: false } });
        let reasonClean = DOMPurify.sanitize(reason, { USE_PROFILES: { html: false } });

        console.log(timeStampClean)
        console.log(durationClean)
        console.log(callSignClean)
        console.log(arrivalClean)
        console.log(reasonClean)
    }



    return (
        <div style={{ fontFamily: "Poppins, sans-serif" }}>
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Reportable Delays</h1>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp2" >Time Stamp</label> <br />

                    <div className="App"> <InputsHi onChange={(e) =>setTimeStamp(e.target.value)} type="datetime-local" id="inp2" /> </div>
                </div>
                <div className="col-md-6">
                    <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp3" >Durations Delays(minutes)</label> <br />
                    <div className="App">
                        <InputsHi type="number" id="inp3" onChange={(e) =>setDuration(e.target.value)} placeholder="Enter Values.." />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold " style={{ marginLeft: "15%" }} htmlFor="inp4" >Callsign</label> <br />
                    <div className="App">
                        <InputsHi type="number" onChange={(e) =>setCallSign(e.target.value)} id="inp3" placeholder="Enter Values.." />
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp3" >Arrival/Departure Aerodrome</label> <br />
                    <div className="App">
                        <InputsHi type="number" id="inp3" onChange={(e) =>setArrival(e.target.value)} placeholder="Enter Values.." />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp3" >Reason</label> <br />
                    <div className="App">
                        <InputsHi type="text" id="inp3"  onChange={(e) =>setReason(e.target.value)} placeholder="Enter Reason" />
                    </div>
                </div>

            </div>

            <SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>


        </div>
    )
}

export default ReportableDelays
