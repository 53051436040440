import React from 'react'
// import "../LOGs/profile.css"
// import $ from "jquery"
const Profile = () => {
    // function readURL(input) {
     
    //     if (input.files && input.files[0]) {
    //         console.log(input.files)
    //         var reader = new FileReader();
    //         reader.onload = function(e) {
    //             $('#imagePreview').css('background-image', 'url('+e.target.result +')');
    //             $('#imagePreview').hide();
    //             $('#imagePreview').fadeIn(650);
    //         }
    //         reader.readAsDataURL(input.files[0]);
    //     }
    // }
    // function immmage() {
    //     alert($("#imageUpload")[0].files);
    //     readURL($("#imageUpload")[0].files);
    // };
    return (
    
<div className="container">
   
    <div className="avatar-upload">
        <div className="avatar-edit">
            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
            <label htmlFor="imageUpload"></label>
        </div>
        <div className="avatar-preview">
            <div id="imagePreview" >
            </div>
        </div>
    </div>
</div>
    )
}

export default Profile;
