import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';

const DailyReport = () => {
    const [date, setDate] = useState('');
    const [designation, setDesignation] = useState('');


    const mysubmit = () => {
        let dateClean = DOMPurify.sanitize(date, { USE_PROFILES: { html: false } });
        let designationClean = DOMPurify.sanitize(designation, { USE_PROFILES: { html: false } });

        console.log(dateClean);
        console.log(designationClean);
    }

    return (
        <div style={{fontFamily:"Poppins , sans-serif"}}>
            {/*  */}
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Daily Report</h1>
            </div>
<form onSubmit={(e)=>e.preventDefault()}>
            <div className="row">
                <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Date</label> <br />
                       <div className="App">
                    <InputsHi type="date" onChange={(e)=>setDate(e.target.value)} id="inp2" />
                </div>
                </div>
               
                <div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Designation</label> <br />
                       <div className="App">
                <select id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius minimal"  onChange={(e)=>setDesignation(e.target.value)} style={{ marginBottom:"4%"}}>
    <option selected >-- Select Designation --</option>
    <option value="ATCO">ATCO</option>
    <option value="ATCA">ATCA</option>
  </select>  



            </div>
                </div>
               
          </div>

            
<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
</form>

        </div>
    )
}

export default DailyReport
