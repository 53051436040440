import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';

const TotalMovementReport = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [type, setType] = useState('');


    const mysubmit = () => {
        let fromDateClean = DOMPurify.sanitize(fromDate, { USE_PROFILES: { html: false } });
        let toDateClean = DOMPurify.sanitize(toDate, { USE_PROFILES: { html: false } });
        let typeClean = DOMPurify.sanitize(type, { USE_PROFILES: { html: false } });

        console.log(fromDateClean);
        console.log(toDateClean);
        console.log(typeClean);
    }


    return (
        <div style={{fontFamily:"Poppins , sans-serif"}}>
            {/*  */}
         
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Total Movement Report</h1>
            </div>
<form onSubmit={(e)=>e.preventDefault()}>
            <div className="row App">
                <div className="col-md-6">
                       <label className="fw-bold clas5"  htmlFor="inp2" >From Date</label> <br />
                    <InputsHi type="date" id="inp2" onChange={(e)=>setFromDate(e.target.value)} />
                </div>
                <div className="col-md-6">
                       <label className="fw-bold clas5"  htmlFor="inp2" >From Date</label> <br />
                    <InputsHi type="date" onChange={(e)=>setToDate(e.target.value)} id="inp2" />
                </div>
                </div>
               
                <div className="row">
            <div className="col-md-6">
                
              
                <label className="fw-bold"  style={{marginLeft:"15%"}}  htmlFor="inp4" >Select Type</label> <br />
<div className="App">
                    

<select id="inp3" onChange={(e)=>setType(e.target.value)} className="px-2 py-2 mx-2 inpFoc bg-white radius minimal" style={{ marginBottom:"4%"}}>
    <option selected >-- Select Type --</option>
    <option value="IFR">IFR</option>
    <option value="VFR">VFR</option>
    <option value="Total Runway Count">Total Runway Count</option>
    <option value="Overflights">Overflights</option>
    <option value="Total Movement">Total Movement</option>

  </select> 



                </div>
                </div>
                </div>  


<SnackBar />
   <button data-bs-dismiss="modal" onClick={()=>mysubmit()} className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"6%",float:"right"}}>Cancel</button>
</form>

        </div>
    )
}

export default TotalMovementReport;
