import React from 'react'
import Navbar from "../navbar/topnav"
import InputsHi from './input';
import SnackBar from './SnackBar';

const PositionRelief = () => {
    return (
        <div className="font back7">
            
<br/>
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Position Relief</h1>
            </div>

<form>
            <div className="row">
         
                <div className="col-md-6">
                <label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}  >Person on position and function</label> <br />
                <div className="App">
                <input className="px-2 py-2 mx-2 inpFoc radius" value="Muhammed Ali" style={{marginBottom:"3%"}} type="text" />
                </div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp4" className="fw-bold"  style={{marginLeft:"15%"}}   >Takeover Person</label> <br />
                <div className="App">
            <select id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius" style={{ marginBottom:"4%"}}>
            <option selected >--Select Takeover--</option>
            <option value="1">Night</option>
            <option value="2">Demo Shift</option>
           
            </select>
            </div>
                </div>
            </div>
            <div className="row">
               
               
                <div className="col-md-6">
                <label htmlFor="inp5" className="fw-bold"  style={{marginLeft:"15%"}} >Takeover Function</label> <br />
                <div className="App">
            <select id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius" style={{ marginBottom:"4%"}}>
            <option selected >--Select Function--</option>
            <option value="1">CMB</option>
            <option value="2">ADC</option>
            <option value="3">GMC</option>
           
            </select>
            </div>
                </div>
                <div className="col-md-6">
                <label htmlFor="inp6" className="fw-bold" style={{marginLeft:"15%"}}>Training</label> <br />
                <div className="App">
            <select id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius" style={{ marginBottom:"4%"}}>
            <option selected >--Select Training--</option>
            <option value="1">OJTI</option>
            <option value="2">COC</option>
            <option value="3">Long Leave Check</option>
            <option value="4">Currency Check</option>
           
            </select>
            </div>  
                </div>       
            </div>
            <div className="row">
                
               
                <div className="col-md-6">
               
                <label htmlFor="inp7" className="fw-bold" style={{marginLeft:"15%"}}>Person in Training</label> <br />
                <div className="App">
            <select id="inp3" className="px-2 py-2 mx-2 inpFoc bg-white radius" style={{ marginBottom:"4%"}}>
            <option selected >--Select Training--</option>
            
           
            </select>
            </div>  
                </div>
                            
            </div>
           


            <div style={{paddingBottom:"3%", marginTop:"5%"}}>
<SnackBar/>
<button className="btn px-4 py-2 mx-3" style={{backgroundColor:"#FB6A6A", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>Cancel</button>
            </div>
</form>

        </div>
    )
}

export default PositionRelief;
