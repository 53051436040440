import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';

function MyApp() {
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });
    
      const { vertical, horizontal, open } = state;
    
      const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
      };
    
      const handleClose = () => {
        setState({ ...state, open: false });
      };


  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (variant) => () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar('Successfully Updated..!', { variant });
  };

  return (
    <React.Fragment>

            <button className="btn px-4 py-2" style={{backgroundColor:"red", borderRadius:"50px", color:"white",marginBottom:"8%", float:"right", marginRight:"105px" }} onClick={handleClickVariant('success')}>Update</button>

    </React.Fragment>
  );
}

export default function SnackBarUpdate() {
  return (
    <SnackbarProvider maxSnack={3}>
      <MyApp />
    </SnackbarProvider>
  );
}
