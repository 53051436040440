import React from 'react'
import "./errorPg.css"
import logo3 from "../../images/logo3.png"
import { Link } from 'react-router-dom'

const ErrorPg = () => {
    return (
        <div className="backgroundImg1">
          {/* <h1 className="h1s fw-bold" >404 Error Page </h1> */}
          <div className="App">
<img className="App" style={{marginTop:"6%"}} src={logo3} width="300px" alt="logo" />
</div>
<section class="error-container">
  <span class="four"><span class="screen-reader-text">4</span></span>
  <span class="zero"><span class="screen-reader-text">0</span></span>
  <span class="four"><span class="screen-reader-text">4</span></span>
</section>

<div style={{margin:"auto"}}>
<div class="leyenda App">
	Sorry! Page not found
</div>
<Link to="/" style={{textDecoration:"none"}}>
<a class="anchor">Go Back</a>
</Link>
</div> 
        </div>
    )
}

export default ErrorPg
