import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import { api, authorityApi } from '../api/api';
import InputsHi from '../LOGs/input';
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Authority = () => {
    const [authority, setAuthority] = useState('');
    const [description, setDescription] = useState('');

    const [msg, setMsg] = useState("");
    const [severity, setSeverity] = useState("error");
    const [open, setOpen] = useState(false);

    const cancelButton = ()=>{
        setAuthority("");
        setDescription("");
    }



    const mysubmit = () => {

        let authorityClean = DOMPurify.sanitize(authority, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });


        console.log(authorityClean);
        console.log(descriptionClean);

        const AuthorityData = 

            {authority: authorityClean,
            description: descriptionClean};
        

        let convertedAuthorityData = JSON.stringify(AuthorityData);
        console.log(convertedAuthorityData);

        fetch(
            // "http://8f27-205-164-159-43.ngrok.io/api/authority"
            api + authorityApi
            , {
            method: "POST",
            headers : {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body: convertedAuthorityData
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                setOpen(true)
                setMsg("Data Submitted Successfully");
                setSeverity("success")

                setAuthority("");
        setDescription("");

            })
            .catch((error) => {
                console.error(error);
            });

          

    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }

        setOpen(false);

      }
        


    return (
        <div className="container" style={{ fontFamily: "Poppins , sans-serif" }}>
            {/*  */}
            <div className="App">
                <h1 style={{ color: "#505050" }} className="fw-bold my-5">Authority</h1>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="fw-bold" style={{ marginLeft: "15%" }} htmlFor="inp2" >Authority</label> <br />
                        <div className="App">
                            <InputsHi value={authority} type="text" onChange={(e) => setAuthority(e.target.value)} id="inp2" placeholder="Enter Authority..." />
                        </div>
                    </div>
                    <div className="col-md-6"  >

                        <label htmlFor="inp3" className="fw-bold" style={{ marginLeft: "15%" }} >Description</label> <br />
                        <div className="App">
                            <textarea value={description} rows="3" id="inp4" onChange={(e) => setDescription(e.target.value)} className="px-2 mx-2 inpFoc radius" style={{ marginBottom: "3%" }} type="text" placeholder="Enter Description ..." />
                        </div>
                    </div>


                </div>


                <br />
                <br />

<div>

                {/* <SnackBar open={open} severity={severity} msg={msg} onClick={() => mysubmit()} /> */}
                <button 
 className="btn px-4 py-2" 
 style={{ backgroundColor: "#DD3333", borderRadius: "50px", color: "white", marginBottom: "8%", float: "right", marginRight: "105px", fontFamily: "Poppins , sans-serif" }} 
 onClick={() => mysubmit()} >Submit</button> 
                <button onClick={() => cancelButton()} className="btn px-4 py-2 mx-3" style={{ backgroundColor: "#FB6A6A", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "8%", float: "right" }}>Cancel</button>
                <button className="btn px-4 py-2 mx-1" style={{ backgroundColor: "blue", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "8%", float: "right" }}>View Authority</button>
</div>

            </form>












            <Stack spacing={2} sx={{ width: "100%" }}>

<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
  <Alert severity={severity} onClose={handleClose} style={{ fontFamily: "Poppins , sans-serif" }} >
    {msg}! &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    &nbsp; &nbsp;
  </Alert>
</Snackbar>
</Stack>




        </div>
    )
}

export default Authority;
