import DOMPurify from 'dompurify';
import React, { useState } from 'react'
import InputsHi from '../LOGs/input';
import SnackBar from '../LOGs/SnackBar';
// 
const NotificationList = () => {
    const [name, setName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [description, setDescription] = useState('');
    const [typeFor, setTypeFor] = useState('');

    const mysubmit = () => {
        let nameClean = DOMPurify.sanitize(name, { USE_PROFILES: { html: false } });
        let emailAddressClean = DOMPurify.sanitize(emailAddress, { USE_PROFILES: { html: false } });
        let phoneNumberClean = DOMPurify.sanitize(phoneNumber, { USE_PROFILES: { html: false } });
        let descriptionClean = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });
        let typeForClean = DOMPurify.sanitize(typeFor, { USE_PROFILES: { html: false } });


        console.log(nameClean);
        console.log(emailAddressClean);
        console.log(phoneNumberClean);
        console.log(descriptionClean);
        console.log(typeForClean);

    }
    return (

        <div className="container" style={{fontFamily:"Poppins , sans-serif"}}>
        {/*  */}
        
        <div className="App">
            <h1 style={{ color: "#505050" }} className="fw-bold my-5">Notification List</h1>
        </div>
                		       			
        <form onSubmit={(e) => e.preventDefault()}>
        <div className="row">
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Name</label> <br />
                   <div className="App">
                <InputsHi type="text" id="inp2" onChange={(e) => setName(e.target.value)}  placeholder="Enter Name..." />
            </div>
            </div>
            <div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Email Address</label> <br />
                   <div className="App">
                <InputsHi type="text" id="inp2" onChange={(e) => setEmailAddress(e.target.value)}  placeholder="Enter Email Address..." />
            </div>
            </div>
			
			
			
        </div>
        
            <div className="row" >
            
			
           
			<div className="col-md-6">
                   <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >Phone Number</label> <br />
                   <div className="App">
                <InputsHi type="number" id="inp2" onChange={(e) => setPhoneNumber(e.target.value)}  placeholder="Enter Phone Number..." />
            </div>
            </div>
		


			<div className="col-md-6"  >

<label htmlFor="inp3" className="fw-bold" style={{marginLeft:"15%"}}   >Description</label> <br />
<div className="App">
			   <textarea onChange={(e) => setDescription(e.target.value)}  id="inp4" className="px-2 mx-2 inpFoc radius" style={{ height: "80%", marginBottom:"3%" }} type="text" placeholder="Enter Description ..." />
</div>
</div>
            </div>

<div className="row">

<div className="col-md-6">
                       <label className="fw-bold" style={{marginLeft:"15%"}}  htmlFor="inp2" >For</label> <br />
           <div className="App">
            <select onChange={(e) => setTypeFor(e.target.value)} id="inp3" className="px-3 py-2 mx-2 inpFoc bg-white radius" style={{ marginBottom:"4%"}}>
            <option selected >Others </option>
            <option value="Full Emergency / Aircraft Incident">Full Emergency / Aircraft Incident</option>
            <option value="Ground Incident / Local Standby">Ground Incident / Local Standby</option>
            <option value="Ground Incident / Local Standby">Ground Incident / Local Standby</option>
            <option value="Bomb Warning">Bomb Warning</option>
            <option value="Laser Incident">Laser Incident</option>
            <option value="Drone / UAS">Drone / UAS</option>
            <option value="Medical Emergency">Medical Emergency</option>
            <option value="Hijack/Unlawful Interference">Hijack/Unlawful Interference</option>
            <option value="Weather Standby">Weather Standby</option>
            <option value="Fault Reporting">Fault Reporting</option>
            <option value="RWY Inspection">RWY Inspection</option>
            <option value="Daily Report">Daily Report</option>
            <option value="IT Support">IT Support</option>
            <option value="CNS">CNS</option>
            <option value="FM">FM</option>
            <option value="FeedBack">Feedback</option>
            <option value="Feedback Management Comments">Feedback Management Comments</option>
            </select> 
                </div>
                </div>
    </div>


            <br/>
            <br/>
        
<SnackBar />
<button onClick={() => mysubmit()} className="btn px-4 py-2 mx-3" style={{ backgroundColor: "#FB6A6A", borderRadius: "50px", color: "white", marginLeft: "1%", marginBottom: "8%", float: "right" }}>Cancel</button>
<button className="btn px-4 py-2 mx-1" style={{backgroundColor:"blue", borderRadius:"50px", color:"white", marginLeft:"1%", marginBottom:"8%",float:"right"}}>View Emails / Phone</button>
</form>
		
                </div>
                
    
    )
}

export default NotificationList;
